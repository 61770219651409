import { Flex, Text, useColorModeValue, Button, RadioGroup, Stack, Radio, Wrap, WrapItem } from '@chakra-ui/react';
import { UserPrivacySettings } from 'lib/coplay/types/BackendTypes';
import { EnrichedPrivacySetting, enrichSettings, SettingsHumanReadableToValue } from 'lib/coplay/types/SettingsUtils';
import Card from 'lib/horizon/components/card/Card';
import { useEffect, useState } from 'react';

function SettingsZeroState() {
	return (
		<Card h='100%' w='100%'>
			<Flex align='center' h='100%' w='100%' justifyContent='center' alignContent={'center'} mb='30px'>
				<Text fontWeight='bold' fontSize='2xl' mb='4px'>
					Hmmm... It looks like you don't have any settings yet. Try refreshing your account.
				</Text>
			</Flex>
		</Card>
	)

}

export default function SpotlightPrivacy(props: { onSettings: (settings: UserPrivacySettings) => Promise<void>, settings?: UserPrivacySettings, [x: string]: any }) {
	console.log(props)
	const { onSettings, settings, ...rest } = props;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.700', 'gray.300');

	const [enrichedSettings, setEnrichedSettings] = useState<EnrichedPrivacySetting[]>([]);
	const [originalSettings, setOriginalSettings] = useState<UserPrivacySettings | undefined>(undefined);
	const [currentSettings, setCurrentSettings] = useState<UserPrivacySettings | undefined>(undefined);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (props.settings !== undefined && props.settings !== null) {
			setEnrichedSettings(enrichSettings(settings));
			setOriginalSettings(settings);
			setCurrentSettings(settings);
			setIsDirty(false);
		}
	}, [settings]);

	const handleSettingChange = (setting: string, value: string) => {
		if (currentSettings !== undefined) {
			const newSettings = {
				...currentSettings,
				settings: currentSettings.settings.map(s => s.setting === setting ? { ...s, value } : s)
			};
			setCurrentSettings(newSettings as UserPrivacySettings);
			setIsDirty(true);
		}
	};

	const handleSaveClick = () => {
		// Save the current settings
		setIsLoading(true);
		onSettings(currentSettings as UserPrivacySettings).then(() => {
			setIsLoading(false);
			setOriginalSettings(currentSettings);
			setIsDirty(false);
		});
	};

	const enrichedSettingsElem = enrichedSettings.map((setting, index) => {
		const originalValue = originalSettings?.settings.find(s => s.setting === setting.setting)?.value;
		const currentValue = currentSettings?.settings.find(s => s.setting === setting.setting)?.value;
		const isChanged = originalValue !== undefined && originalValue !== currentValue;
		return (
			<WrapItem key={index} w='420px' h='160px' p='10px' borderWidth="1px" borderColor="gray.200" borderRadius="md">

				<div>
					<Text w='300px' color={textColorPrimary} fontWeight={"bold"}>
						{setting.description}
					</Text>
					<Text w='300px' color={textColorSecondary}>
						{setting.details}
					</Text>
				</div>

				<RadioGroup value={currentValue} onChange={e => handleSettingChange(setting.setting, e as string)}>
					<Stack direction="column">
						{setting.options.map((option, i) => (
							<Radio key={i} value={SettingsHumanReadableToValue[option]}>
								{option}
							</Radio>
						))}
					</Stack>
				</RadioGroup>

			</WrapItem>
		);
	});

	return (

		<Card mb='20px' overflowY='scroll' {...rest}>
			<Wrap p='10px'>
				<Flex align='center' w='100%' justify='space-between' mb='30px'>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
						Privacy Settings
					</Text>
					<Button isLoading={isLoading} colorScheme="blue" disabled={!isDirty || isLoading} onClick={handleSaveClick}>Save</Button>
				</Flex>
				{enrichedSettingsElem.length > 0 ? enrichedSettingsElem : <SettingsZeroState />}
			</Wrap>

		</Card>

	);
}