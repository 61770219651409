import { useState } from "react"
import { useHistory } from "react-router-dom"
import { confirmThePasswordReset } from "lib/coplay/backend/FirebaseFunctions"
import PasswordInput from "lib/coplay/components/views/auth/PasswordInput"
import AuthStatus from "lib/coplay/components/views/auth/AuthStatus"
import AuthButton from "lib/coplay/components/views/auth/AuthButton"

export default function ChangePasswordForm(
    props: { textColor: string, brandStars: string }
) {
    const [getFirstPassword, setFirstPassword] = useState<string>()
    const [getSecondPassword, setSecondPassword] = useState<string>()
    const [getStatus, setStatus] = useState<string>()

    const history = useHistory()

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (getFirstPassword === getSecondPassword) {
            if (getFirstPassword.length > 6) {
                confirmThePasswordReset(
                    (new URLSearchParams(window.location.search)).get("oobCode"),
                    getFirstPassword
                ).then(() => history.replace("/sign-in"))
                    .catch(() => setStatus("Authentication error"))
            } else {
                setStatus("Password too short")
            }
        } else {
            setStatus("Mismatched passwords")
        }
    }

    return (
        <form onSubmit = {onSubmit}>
            <PasswordInput
                setPassword = {setFirstPassword}
                textColor = {props.textColor}
                brandStars = {props.brandStars}
                labelText = {"New Password"}
            />
            <PasswordInput
                setPassword = {setSecondPassword}
                textColor = {props.textColor}
                brandStars = {props.brandStars}
                labelText = {"Repeat New Password"}
            />
            <AuthStatus getStatus = {getStatus} />
            <AuthButton>
                Change Password
            </AuthButton>
        </form>
    )
}