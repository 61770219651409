import { Switch } from "@chakra-ui/react"
import { XboxAccountSettingsController }
    from "lib/coplay/components/views/profiles/ProfileDetailsInfo"

export default function PrivacySwitch(
    props: { editing: boolean, controller: XboxAccountSettingsController }
) {
    const onChange
        = () => props.controller.setEnablePrivacy(!props.controller.getEnablePrivacy)

    return (
        <Switch
            isDisabled = {!props.editing}
            onChange={onChange}
            isChecked={props.controller.getEnablePrivacy}
        />
    )
}