import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Avatar, Flex, Button, Box, Link, Spinner, Text, ListItem, List } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { BackendConfig } from "lib/coplay/constants/generated";
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";
import { XMSuggestions } from "lib/coplay/types/FrontendTypes";


async function getSuggestionDetails(gameId: string) {
    try {
        const res = await fetch(BackendConfig.xboxGameSearchUrl, {
            method: 'POST',
            body: JSON.stringify({
                action: "getSuggestionDetails",
                bigCatId: gameId
            })
        })
        const data = await res.json()

        if (Array.isArray(data.details) && data.details.length > 0) {
            return data.details[0]
        }

        throw new Error("Could not get error details")
    } catch (err) {
        console.log("Error getting suggestion details: ", err)
        return null
    }
}


/*
{
   "details" : [
      {
         "imageUri" : "https://store-images.s-microsoft.com/image/apps.56115.66154479819523742.0dd42547-0394-489e-b8a8-0bfec963dfd8.b15fb871-6679-495e-bfba-0fb7c855d4f0?w=150&h=150",
         "name" : "Diablo III: Reaper of Souls â Ultimate Evil Edition",
         "productId" : "C2R5R5P2DF3C",
         "uniqueId" : "BlizzardEntertainmentInc.DiabloIIIReaperofSoulsUlt_kdg5n7x41qwkg"
      }
   ]
}
*/

const CompatibilityMap = {
    "ConsoleGen9": "Xbox Series S|X",
    "ConsoleGen8": "Xbox One"
}

export default function GameDetailsModal(props: {
    game: XMSuggestions | null,
    onSelected: (app: SearchedSoftware) => void,
    isOpen: boolean,
    onClose: () => void
}) {

    const [gameDetails, setGameDetails] = React.useState<SearchedSoftware | null>(null)
    const [isLoading, setIsLoading] = React.useState(true)

    const closeModal = () => {
        props.onClose()
        setGameDetails(null)
        setIsLoading(false)
    }

    const details = () => {
        if (props.game) {
            return (
                <Flex alignItems={'center'}>
                    <Avatar
                        name={props.game.Title}
                        marginRight="2"
                        src={props.game.ImageUrl}
                        w='50px'
                        h='50px'
                        borderRadius='20px'
                        me='16px'
                    />
                    <Box>
                        {props.game.Title}
                        <br></br>
                        <Link color='teal.500' href={props.game.Url} isExternal>View in Microsoft Store</Link>
                    </Box>

                </Flex>
            )
        }
        return null
    }

    useEffect(() => {
        if (props.game) {
            setIsLoading(true)
            getSuggestionDetails(props.game.BigCatalogId).then((res) => {
                setGameDetails(res)
            }).catch((e) => {
                console.log("Error getting suggestion details")
            })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [props.game?.BigCatalogId]);

    const compatElem = (compatConsoles: string[] | null) => {
        if (compatConsoles && compatConsoles.length > 0) {
            return (
                <>
                    <List>
                        <strong>Compatible with:</strong>
                        {gameDetails.compatibileWith.map((detail) => {
                            return (<ListItem>- {CompatibilityMap[detail]}</ListItem>)
                        })}
                    </List>

                </>
            )
        }
    }

    const installableElem = () => {
        if (isLoading) {
            return (<Spinner />)
        } else {
            if (gameDetails != null) {

                const installSelected = () => {
                    props.onSelected(gameDetails)
                    closeModal()
                }

                return (
                    <>
                        {compatElem(gameDetails.compatibileWith)}
                        <Button variant='brand' onClick={() => installSelected()}>Add</Button>
                    </>
                )
            } else {
                return (
                    <>
                        <Text>Hmmm... Cannot install this title</Text>
                        <Button variant='brand' disabled>Add</Button>
                    </>

                )
            }
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            size={"xl"}
            onClose={closeModal}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody style={
                    { display: "flex", flexDirection: "column", rowGap: "24px" }
                }>
                    {details()}
                    {installableElem()}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}