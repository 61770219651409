import { useState, useEffect } from "react"
import { subscribeToProfiles } from "lib/coplay/backend/FirebaseFunctions"
import { XMProfile } from "lib/coplay/types/BackendTypes"

export default function ProfileOptions() {
    const [getProfileOptions, setProfileOptions] = useState<JSX.Element[]>()

    useEffect(() => {
        return subscribeToProfiles((profiles: XMProfile[]) => {
            setProfileOptions(profiles.map((profile: XMProfile) => {
                return (
                    <option key={profile.id}>
                        {profile.name}
                    </option>
                )
            }))
        })
    }, [])

    return (
        <>
            {getProfileOptions}
        </>
    )
}