
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Logout } from 'lib/coplay/backend/FirebaseFunctions';
import { useContactDetails } from 'lib/coplay/components/DataProvider';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { AppSidebarResponsive, SidebarLink } from 'lib/coplay/components/app_layout/AppSidebar';
import { ClearConversationsActionLink } from 'lib/coplay/components/actions/ClearConversationsActionLink';
import { RefreshUsersActionLink } from 'lib/coplay/components/actions/user/RefreshUsersActionLink';
import { RemoveFriendsActionLink } from 'lib/coplay/components/actions/friending/RemoveFriendActionLink';
import { SyncFriendsActionLink } from 'lib/coplay/components/actions/friending/SyncFriendsActionLink';
import { MdHome, MdBarChart, MdPerson, MdGroups, MdCalendarMonth, MdCode, MdSettings, MdNotifications } from 'react-icons/md';
import Brand from '../sidebar/components/Brand';
import { DeleteUsersActionLink } from 'lib/coplay/components/actions/user/DeleteUsersActionLink';
import { ScheduleActionLink } from 'lib/coplay/components/actions/scheduling/ScheduleActionLink';
import { RemoveProfileActionLink } from 'lib/coplay/components/actions/user/RemoveProfileActionLink';
import { SetProfileActionLink } from 'lib/coplay/components/actions/user/SetProfileActionLink';
import { SyncProfileActionLink } from 'lib/coplay/components/actions/user/SyncProfileActionLink';
import { BatchInstallActionLink } from 'lib/coplay/components/actions/BatchInstallActionLink';
import { XMNotificationProvider } from 'lib/coplay/context/NotificationContext';

export default function HeaderLinks(props: { secondary: boolean }) {
	const contactDetails = useContactDetails();
	const history = useHistory();

	const { secondary } = props;
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	const logout = () => {
		Logout().then(() => {
			history.replace('/sign-in');
		});
	};

	const location = useLocation();
	const isDashboard = location.pathname === '/app/dashboard';


	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='30px'
			boxShadow={shadow}>
			<AppSidebarResponsive>

				<Brand key="Brand" />
				<XMNotificationProvider>
					<Accordion allowMultiple index={isDashboard ? [0] : []}>

						<AccordionItem border='none' >
							<Box position="relative">
								<SidebarLink label="Dashboard" icon={MdHome} routeName={'/app/dashboard'}>
									<></>
									<Flex border='' position="absolute" right={0} top={0} bottom={0} w='25%' justifyContent="center" alignItems="center">
										<AccordionButton m={5}>
											<AccordionIcon />
										</AccordionButton>
									</Flex>
								</SidebarLink>
							</Box>

							<AccordionPanel _hover={{ cursor: 'pointer' }} pb={4} pl={'60px'}>
								<Text fontSize='sm' fontWeight={'bold'}> User Actions</Text>

								<SyncFriendsActionLink key="SyncFriendsActionLink" />
								<RemoveFriendsActionLink key="RemoveFriendsActionLink" />
								<ClearConversationsActionLink key="ClearConversationsActionLink" />
								<RefreshUsersActionLink key="RefreshUsersActionLink" />
								<DeleteUsersActionLink key="DeleteUsersActionLink" />

								<Text fontSize='sm' fontWeight={'bold'}> Console Actions</Text>
								<BatchInstallActionLink key="BatchInstallActionLink" />


								<Text fontSize='sm' fontWeight={'bold'}> Profile Actions </Text>
								<SetProfileActionLink key="SetProfileActionLink" />
								<RemoveProfileActionLink key="RemoveProfileActionLink" />
								<SyncProfileActionLink key="SyncProfileActionLink" />

								<Text fontSize='sm' fontWeight={'bold'}> Scheduling </Text>
								<ScheduleActionLink key={"Schedule"} />

							</AccordionPanel>

						</AccordionItem>

						<SidebarLink label="Usage" icon={MdBarChart} routeName={'/app/usage'} />
						<SidebarLink label="Create/View Profiles" icon={MdGroups} routeName={'/app/profiles'} />
						<SidebarLink label="View Schedules" icon={MdCalendarMonth} routeName={'/app/schedules'} />
						<SidebarLink label="Notifications" icon={MdNotifications} routeName={'/app/notifications'} />
					</Accordion>

					<Accordion allowMultiple >
						<AccordionItem border='none'>
							<AccordionButton>
								<Box flex="1" textAlign="left">
									<Flex w='100%' ml={'12px'} align="center">
										<MdSettings />
										<Text ml={'20px'} fontSize='md'> Settings </Text>
									</Flex>
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel m={0} p={0} pb={4} w='100%'>
								<Box w='100%' px={'15px'} mx={'15px'}>
									<SidebarLink label="Account" icon={MdPerson} routeName={'/app/account'} />
									<SidebarLink label="Developer" icon={MdCode} routeName={'/app/api'} />
								</Box>
							</AccordionPanel>
						</AccordionItem>

					</Accordion>
				</XMNotificationProvider>
			</AppSidebarResponsive>

			<Menu>
				<MenuButton p='0px'>
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						name={contactDetails.companyName}
						bg='#11047A'
						size='sm'
						w='40px'
						h='40px'
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
					<Flex w='100%' mb='0px'>
						<Text
							ps='20px'
							pt='16px'
							pb='10px'
							w='100%'
							borderBottom='1px solid'
							borderColor={borderColor}
							fontSize='sm'
							fontWeight='700'
							color={textColor}>
							👋&nbsp; Hey, {contactDetails.companyName}
						</Text>
					</Flex>
					<Flex flexDirection='column' p='10px'>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
							<NavLink to='/app/account' style={{ textDecoration: 'none', color: 'inherit' }}>
								<Text fontSize='sm'>Account</Text>
							</NavLink>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='red.400'
							borderRadius='8px'
							px='14px'
							onClick={logout}>
							<Text fontSize='sm'>Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
