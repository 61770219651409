import { NavLink } from "react-router-dom"
import { IconButton } from "@chakra-ui/react"
import { MdArrowBack } from "react-icons/md"

export default function AuthBack() {
    let authBack: JSX.Element
    if (window.location.pathname === "/sign-in/forgot-password") {
        authBack = (
            <NavLink style = {{ height: 0 }} to = {"/sign-in"}>
                <IconButton aria-label = {"Go back"}>
                    <MdArrowBack />
                </IconButton>
            </NavLink>
        )
    } else {
        authBack = null
    }

    return authBack
}