import { useState, useRef } from "react"
import { Button } from "@chakra-ui/react"
import { ContactDetails } from "lib/coplay/types/FrontendTypes"
import { useContactDetails, useUpdateContactDetails }
	from "lib/coplay/components/DataProvider"
import ContactDetail from "lib/coplay/components/views/account/ContactDetail"

export default function ContactDetailsView() {
	const contactDetails: ContactDetails = useContactDetails()
	const updateContactDetails = useUpdateContactDetails()

	const [getEditing, setEditing] = useState<boolean>(false)

	const firstNameRef = useRef<HTMLInputElement>()
	const lastNameRef = useRef<HTMLInputElement>()
	const emailAddressRef = useRef<HTMLInputElement>()
	const phoneRef = useRef<HTMLInputElement>()
	const titleRef = useRef<HTMLInputElement>()
	const urlRef = useRef<HTMLInputElement>()

	const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
		if (getEditing) {
			const enteredDetails: { [key: string]: string } = {
				firstName: firstNameRef.current.value,
				lastName: lastNameRef.current.value,
				emailAddress: emailAddressRef.current.value,
				phone: phoneRef.current.value,
				title: titleRef.current.value,
				url: urlRef.current.value
			}
			for (const enteredDetail in enteredDetails) {
				if (contactDetails[enteredDetail] !== enteredDetails[enteredDetail]) {
					updateContactDetails(enteredDetails)
					break
				}
			}
		}
		setEditing(!getEditing)
    }

	return (
		<form onSubmit = {onSubmit}>
			<div style = {
				{ padding: '10px', display: "flex", justifyContent: "space-between" }
			}>
				<h3 style = {{ fontSize: "24px", fontWeight: "bold" }}>
					Contact Details
				</h3>
				<Button type = {"submit"}>
					{getEditing ? "Save" : "Edit"}
				</Button>
			</div>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {firstNameRef}
				detailName = {"First Name"}
				detailValue = {contactDetails.firstName}
			/>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {lastNameRef}
				detailName = {"Last Name"}
				detailValue = {contactDetails.lastName}
			/>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {emailAddressRef}
				detailName = {"Email Address"}
				detailValue = {contactDetails.emailAddress}
			/>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {phoneRef}
				detailName = {"Phone Number"}
				detailValue = {contactDetails.phone}
			/>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {titleRef}
				detailName = {"Job Title"}
				detailValue = {contactDetails.title}
			/>
			<ContactDetail
				getEditing = {getEditing}
				inputRef = {urlRef}
				detailName = {"Company Url"}
				detailValue = {contactDetails.url}
			/>
		</form>
	)
}