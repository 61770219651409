import { IconButton } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import ProfileDetailsInfo from "lib/coplay/components/views/profiles/ProfileDetailsInfo"

export function CreateProfile(props: { onCreated: () => void, onBack: () => void }) {
    return (
        <div style = {{ display: "flex", flexDirection: "column" }}>
            <IconButton
                style = {{ width: "40px" }}
                aria-label="Go back"
                icon={<MdArrowBack />}
                onClick={() => props.onBack()}
                mb={4} />
            <ProfileDetailsInfo editing = {true} onCreated = {props.onCreated} />
        </div>
    );
};

export default CreateProfile;