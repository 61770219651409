import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tabs, Text, TabList, Tab, TabPanels, TabPanel, Box, VStack } from "@chakra-ui/react";
import { getSchedulesWithId, getUserLog } from "lib/coplay/backend/FirebaseFunctions";
import { useState, useEffect } from "react";
import { createSchedules } from "lib/coplay/components/views/schedules/ScheduleListItem";
import { ScheduleEvent, LogEntry } from "lib/coplay/types/BackendTypes";

const useScheduleDetails = (scheduleIds?: string[]) => {
    const [schedules, setSchedules] = useState<ScheduleEvent[] | null>(null);

    useEffect(() => {
        if (!scheduleIds || scheduleIds.length === 0) {
            setSchedules(null);
        }
        getSchedulesWithId(scheduleIds).then((schedules) => {
            setSchedules(schedules);
        })
    }, [scheduleIds])


    return {
        schedules,
        setSchedules
    }
}

const useUserLogs = (hasUserLogs: boolean, emailAddress: string) => {
    const [logs, setLogs] = useState<LogEntry[]>([]);

    useEffect(() => {
        getUserLog(emailAddress).then((logs) => {
            setLogs(logs);
        }).catch((error) => console.log(`Error getting user Logs: ${error}`))
    }, [hasUserLogs, emailAddress])

    return logs;
}


export function ScheduleDetailsModal(props: {
    scheduleIds?: string[];
    hasScheduleWarnings?: boolean;
    emailAddress: string;
    isOpen: boolean;
    onClose: () => void;
}) {
    const { scheduleIds, emailAddress, isOpen, onClose } = props;
    const { schedules, setSchedules } = useScheduleDetails(scheduleIds);
    const logs = useUserLogs(props.hasScheduleWarnings, emailAddress);

    const renderDetails = () => {
        if (!schedules) {
            return <Text>No schedules found.</Text>
        } else {
            return createSchedules(schedules, null, emailAddress);
        }
    }

    const renderLogs = () => {
        if (logs && logs.length > 0) {
            return logs.map((log, index) => {
                return (
                    <LogEntryComponent logEntry={log} />
                )
            })
        } else {
            return <Text>No logs found.</Text>
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Schedule Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Tabs>
                        <TabList>
                            <Tab>Schedules</Tab>
                            <Tab>Logs</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                {renderDetails()}
                            </TabPanel>
                            <TabPanel>
                                {renderLogs()}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </ModalBody>
            </ModalContent>

        </Modal>

    );
}

const LogEntryComponent: React.FC<{ logEntry: LogEntry }> = ({ logEntry }) => {
    return (
        <VStack align="stretch" p={4} borderWidth="1px" borderRadius="lg">
            <Text>{logEntry.msg}</Text>
            {logEntry.response && (
                <details>
                    <summary>Show Details</summary>
                    <Box pt={4}>
                        <Text color="red.500">{JSON.stringify(logEntry.response, null, 2)}</Text>
                    </Box>
                </details>
            )}
        </VStack>
    );
};