import { Text } from "@chakra-ui/react"
import Card from "lib/horizon/components/card/Card"
import ContactDetailValue from "lib/coplay/components/views/account/ContactDetailValue"

export default function ContactDetail(props: {
    getEditing: boolean,
    inputRef: React.MutableRefObject<HTMLInputElement>,
    detailName: string,
    detailValue: string
}) {
	return (
        <Card style = {{padding: '10px', fontWeight: 500 }}>
            <label>
                <Text style = {{ fontSize: "14px" }} color = {"gray.400"}>
                    {props.detailName}
                </Text>
                <ContactDetailValue
                    getEditing = {props.getEditing}
                    inputRef = {props.inputRef}
                    detailValue = {props.detailValue}
                />
            </label>
        </Card>
    )
}