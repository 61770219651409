import { Flex, Text, Box, HStack, Avatar, useDisclosure, useTheme } from "@chakra-ui/react";
import { SupportedVendors } from "lib/coplay/types/PaymentTypes";
import React from "react";
import { MdAdd } from "react-icons/md";
import DisneyPlus from '../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../assets/xbox_icon.png';
import { AddVendorMemberButton } from "./AddVendorMemberButton";

export function PaymentsZeroState() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [vendor, setVendor] = React.useState(SupportedVendors.Disney);

    const theme = useTheme();
    const disneyColor = theme.colors.blue[500]; // Access the blue color scheme value
    const microsoftColor = theme.colors.green[500];

    const [outlineColor, setOutlineColor] = React.useState(disneyColor);

    const onSetVendor = (vendor: SupportedVendors) => {
        setVendor(vendor);
        setOutlineColor(vendor === SupportedVendors.Disney ? disneyColor : microsoftColor);
        // onOpen();
    }

    return (
        <Flex id="paymentsZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"} h='100%' w='100%'>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    No Managed Payments Or Subscription Accounts Found
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    Click below to add a payment method or subscription.
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    Aggregate third-party services like Disney+ or Xbox Game Pass, and manage your payments and billing in one place.
                </Text>
                <HStack justifyContent="center" spacing={4} mt={4} mb={4}>
                    <Avatar src={DisneyPlus} size="lg" />
                    <Avatar src={XboxIcon} size="lg" />
                    <Avatar icon={<MdAdd />} size="lg" border="1px solid" borderColor="gray.300" bg="transparent" />
                </HStack>
                <Flex w='100%' justifyContent="center" alignItems="center" mt={4}>
                    <AddVendorMemberButton hideIcon={true} style={{ width: '90%' }} onClick={onOpen} />
                </Flex>
            </Box>

        </Flex>
    )
}