import React from 'react';
import { Box, Text, Badge, VStack, Flex } from '@chakra-ui/react';
import { LogEntry, ResponseStatus, XMError, XMResponse } from 'lib/coplay/types/BackendTypes';

interface LogEntryRendererProps {
    logEntry: LogEntry;
}

const SpotlightLogItem: React.FC<LogEntryRendererProps> = ({ logEntry }) => {
    const { msg, type, response, timestamp } = logEntry;

    const errorRenderer = (error: XMError) => {
        return (
            <Box mt={2} p={2} borderWidth="1px" borderRadius="lg">
                <Text fontWeight="bold">Error:</Text>
                <Text>Message: {error.userMessage}</Text>
                <Text>Suggestion: {error.suggestion}</Text>
                <Text>Raw: {error.errorMessage}</Text>
            </Box>
        );
    };

    const renderResponse = (response: XMResponse) => {
        if (!response) return null;

        const renderStatus = (status: ResponseStatus) => {

            const statusToTextMap = {
                [ResponseStatus.Success]: 'Success',
                [ResponseStatus.Fail]: 'Fail',
                [ResponseStatus.Mixed]: 'Mixed',
                [ResponseStatus.Warning]: 'Warning',
                [ResponseStatus.Pending]: 'Pending',
                [ResponseStatus.LockFail]: 'Lock Fail',
            }

            const statusToColorMap = {
                [ResponseStatus.Success]: 'green',
                [ResponseStatus.Fail]: 'red',
                [ResponseStatus.Mixed]: 'yellow',
                [ResponseStatus.Warning]: 'orange',
                [ResponseStatus.Pending]: 'blue',
                [ResponseStatus.LockFail]: 'purple',
            }
            const text = statusToTextMap[status] || 'Unknown';

            return (
                <Flex>
                    <Text>Status: <Text as="span" fontWeight="bold" color={statusToColorMap[status]}>{text}</Text></Text>
                </Flex>
            );
        };

        return (
            <Box mt={2} p={2} borderWidth="1px" borderRadius="lg">
                <Text fontWeight="bold">Response:</Text>
                <Text>Action: {response.oauthAction}</Text>
                {renderStatus(response.status)}
                {response.error && errorRenderer(response.error)}
            </Box>
        );
    };

    return (
        <Box borderWidth="1px" borderRadius="lg" m={2}>
            <Flex alignItems="center">
                {type && (
                    <>
                        <Box mx={2}>
                            <Badge colorScheme={type === 'user' ? 'green' : 'blue'}>
                                {type}
                            </Badge>
                        </Box>
                        <VStack align="start">
                            <Text fontWeight="bold">Message: {msg}</Text>
                            {timestamp && (
                                <Text fontSize="sm" color="gray.500">
                                    {new Date(timestamp).toLocaleString()}
                                </Text>
                            )}
                        </VStack>
                    </>
                )}

            </Flex>
            {response && renderResponse(response)}
        </Box>

    );
};

export default SpotlightLogItem;