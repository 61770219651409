import { Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react"
import React from "react"
import { addNewUser, reAggregateUser, refreshUsers } from "lib/coplay/backend/ApiRequests"
import { ResetUserModal } from "lib/coplay/components/modals/UserAggregationModal"
import { getErrorDetails } from "lib/coplay/backend/FirebaseFunctions"
import { Loading } from "lib/coplay/components/Loading"
import { ResponseStatus, UserStatusMessage, XboxUser, XMError } from "lib/coplay/types/BackendTypes"


export function StatusButton(props: { rowData: XboxUser }) {
    const showLoading = () => {
        return (
            <ModalBody>
                <Loading />
            </ModalBody>
        )
    }

    const [currentView, setCurrentView] = React.useState<JSX.Element>(showLoading())
    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)

    const lastAction = props.rowData.userStatus.lastAction.oauthAction;
    const lastRequestId = props.rowData.userStatus.lastAction.requestID;
    const nextAttempt = props.rowData.userStatus.nextRequest ? props.rowData.userStatus.nextRequest : -1;
    const emailAddress = props.rowData.emailAddress;

    const onOpen = () => {
        setIsOpen(true)
        getErrorDetails(lastAction, lastRequestId, emailAddress).then((errorDetails) => {
            if (errorDetails == null) {
                // TODO: This is a hack. We should never have to handle empty error messages. Remove this once the bug is fixed on the backen.d
                setCurrentView(showStatus(emailAddress, {
                    userMessage: 'No error details found',
                    suggestion: 'Please Refresh and try again',
                    errorMessage: 'Last Action: ' + lastAction + '\nRequest ID: ' + lastRequestId + '\n AttemptNum: ' + nextAttempt
                }))
            } else {
                setCurrentView(showStatus(errorDetails.emailAddress, errorDetails.error))
            }
        })
    }

    let buttonColor = 'red';
    let buttonMessage = `Click for Details`;
    if (props.rowData.userStatus.statusMessage === UserStatusMessage.Warning) {
        buttonColor = 'orange';
    }
    else if (props.rowData.userStatus.statusMessage === UserStatusMessage.Pending) {
        buttonColor = 'brand';
        buttonMessage = "Click to continue"
    }

    const showStatus = (emailAddress: string, error: XMError) => {
        return (
            <>
                <ModalBody>
                    <Text py='10px' fontWeight='bold'>{emailAddress}</Text>
                    <Text py='10px'>{error.userMessage}</Text>
                    <Text py='10px'>{error.suggestion}</Text>
                    <details>
                        <summary>Click for more details</summary>
                        <Text>{error.errorMessage}</Text>
                    </details>
                </ModalBody>
                <ModalFooter>
                    {showButtons()}
                </ModalFooter>
            </>
        )
    }

    const showRefreshButton = () => {
        const refreshHandler = () => {
            setCurrentView(showLoading())
            refreshUsers([props.rowData.emailAddress]).then((response) => {
                onClose();
            })
        }

        return (<Button variant='brand' mr={3} onClick={refreshHandler}>Refresh</Button>)
    }

    const showResetButton = () => {
        const resetHandler = () => {
            function handleReset(loginCode: string) {
                setCurrentView(showLoading())
                reAggregateUser(loginCode).then((response) => {
                    if (response.status !== ResponseStatus.Success) {
                        console.log('Failed to reset user: ' + loginCode)
                        return;
                    }
                    onClose();
                }).catch((error) => {
                    console.log('Failed to reset user: ' + loginCode)
                    console.log(error)
                })
            }
            setCurrentView(<ResetUserModal handler={handleReset} close={onClose} emailAddress={props.rowData.emailAddress} />)
        }

        return (<Button colorScheme="red" mr={3} onClick={resetHandler}>Reset/Re-Authorize</Button>)
    }

    const showButtons = () => {
        if (props.rowData.userStatus.statusMessage === UserStatusMessage.Error) {
            return (
                <>
                    {showRefreshButton()}
                    {showResetButton()}
                </>
            )
        } else {
            return (<>
                {showRefreshButton()}
            </>)
        }
    }



    return (
        <>
            <Button fontSize='11px' minW="150px" py='20px' onClick={onOpen} colorScheme={buttonColor} variant="outline" size="sm">{props.rowData.userStatus.statusMessage} <br></br>{buttonMessage}</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>{props.rowData.userStatus.statusMessage} Details</ModalHeader>
                    {currentView}
                </ModalContent>
            </Modal>
        </>
    )
}