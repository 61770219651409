import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { XMNotification } from '../types/BackendTypes';
import { deleteXMNotification, markXMNotificationAsRead, subscribeToXMNotifications } from '../backend/FirebaseFunctions';

interface NotificationContextType {
    notifications: XMNotification[];
    unreadCount: number;
    markRead: (notificationId: string) => void;
    deleteNotification: (notificationId: string) => void;
}

interface XMNotificationProviderProps {
    children: ReactNode;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;
};

export const XMNotificationProvider: React.FC<XMNotificationProviderProps> = ({ children }) => {
    const [notifications, setNotifications] = useState<XMNotification[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        const unsubscribe = subscribeToXMNotifications(setNotifications);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setUnreadCount(notifications.filter(n => !n.read).length);
    }, [notifications]);

    const markRead = (notificationId: string) => {
        const notification = notifications.find(n => n.id === notificationId);
        if (notification) {
            if (!notification.read) {
                markXMNotificationAsRead(notification);
            }
        }
    };

    const deleteNotification = (notificationId: string) => {
        const notification = notifications.find(n => n.id === notificationId);
        if (notification) {
            deleteXMNotification(notification.id);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, unreadCount, markRead, deleteNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

