// PUPPETEER
export enum ProtocolCommands {
    'Input.dispatchKeyEvent' = 'Input.dispatchKeyEvent',
    'Input.emulateTouchFromMouseEvent' = 'Input.emulateTouchFromMouseEvent',
    'Input.dispatchMouseEvent' = 'Input.dispatchMouseEvent',
}

export enum HostCommands {
    'start' = 'start',
    'close' = 'close',
    'setStrategy' = 'setStrategy',
}

export enum WorkerCommands {
    'startComplete' = 'startComplete',
    'runComplete' = 'runComplete',
    'screencastFrame' = 'screencastFrame',
    'browserClose' = 'browserClose',
    'error' = 'error',
    'inProgress' = 'inProgress',
    'loading' = 'loading',
}

export interface Message {
    command: string,
    data: any,
}

export enum BrowserStates {
    Ready = 'ready',
    InProgress = 'inProgress',
    Loading = 'loading',
    Error = 'error',
    Closed = 'closed',
    Finished = 'finished'
}

export enum SupportedVendors {
    Microsoft = 'microsoft',
    Disney = 'disney',
}

export enum Vendor {
    'Disney' = "Disney",
    'Microsoft' = "Microsoft"
}

export interface VendorMember {
    id: string
    email: string

    vendor: SupportedVendors
    subscriptions: VendorSubscription[]

    hasPurchasing: boolean // use to filter on the purchasing dashboard
    createdAt: string
    updatedAt: string
    notes?: string
}


export interface VendorSubscription {
    id: string
    name: string

    endDate: string
    nextCharge?: string
    daysRemaining?: number
    price?: number
    payment: {
        id: string
        name: string
        lastFourDigits?: string;
        expiryMonth?: string;
        expiryYear?: string;
    }
    isCoplayManaged: boolean // TODO: Remove this
    coplayFunding?: {
        fundingId: string
        subscriptionId: string
        memberId: string
        name: string
        amountUsed: number
        amountRemaining: number
        status: "Created" | "Active" | "In-Use" | "Paused" | "Cancelled" | "Expired" | "Error"
    }

    description: string
}


export interface SimpleOrder {
    id: string;
    date: string;
    amount: number;
    items: {
        name: string;
        description: string;
        amount: number;
        status: string;
    }[]
}



export interface MSOrderHistoryItem {
    localSubmittedDate: string | null;
    localTotal: number;
    orderId: string;
    vanityOrderId: string;
    paymentInstruments: MSPaymentInstrument[];
    items: MSOrderItem[];
}

interface MSPaymentInstrument {
    id: string;
    localName: string;
    displayLogoLink: string;
}

interface MSOrderItem {
    itemTypeName: string;
    productId: string;
    itemState: string;
    localTitle: string;
    description: string;
    totalAmountWithoutTax: number;
}

export function adaptMSOrderHistoryItem(msOrder: MSOrderHistoryItem): SimpleOrder {
    return {
        id: msOrder.vanityOrderId,
        date: msOrder.localSubmittedDate,
        amount: msOrder.localTotal,
        items: msOrder.items.map(item => ({
            name: item.localTitle,
            description: item.description,
            amount: item.totalAmountWithoutTax,
            status: item.itemState
        }))
    }
}

