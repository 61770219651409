import { useColorModeValue, Link } from "@chakra-ui/react"

export default function PrivilegesLink() {
    const colorModeValue = useColorModeValue("brand.500", "white")

    return (
        <Link
            color = {colorModeValue}
            href =
                {"https://account.xbox.com/en-us/settings?activetab=main:privilegetab"}
            target = {"_blank"}
        >
            the Xbox site
        </Link>
    )    
}