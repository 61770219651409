import { DocumentData } from "firebase/firestore";
import { XboxUser, XMError } from "./types/BackendTypes";


export function isXMError(error: any): error is XMError {
    return error && error.userMessage && error.errorMessage && error.suggestion;
}

export function isStandardError(error: any): error is Error {
    return error && error.message && error.stack;
}

// TODO: Yikes. What a hack. Fix this later.
export function createXMError(
    error: any,
    userMessage: string = "Uh Oh, something went wrong!",
    suggestion: string = "Try Again Later"
): XMError {
    // Check if error
    if (isStandardError(error)) {
        return {
            userMessage: userMessage,
            errorMessage: error.toString(),
            suggestion: suggestion
        }
    }
    // check if object
    if (typeof (error) === "object") {
        return {
            userMessage: userMessage,
            errorMessage: JSON.stringify(error),
            suggestion: suggestion
        }
    }

    if (typeof (error) === "string") {
        return {
            userMessage: userMessage,
            errorMessage: error,
            suggestion: suggestion
        }
    }

    if (isXMError(error)) {
        return error;
    }

    return {
        userMessage: "Unknown Error",
        errorMessage: "Unknown Error",
        suggestion: "Unknown Error"
    }
}

export function emptyXMError(): XMError {
    return {
        userMessage: "",
        errorMessage: "",
        suggestion: ""
    }
}


// updatedAt is actually a firebase timestamp
// convert it to a Date object
// SUPER HACK
export function convertDocumentToXboxUser(doc: DocumentData) {
    const user = doc.data()
    try {
        user.updatedAt = user.updatedAt.toDate();
        return user as XboxUser;
    } catch (e) {
        console.log("Error converting document to user: ", doc.id, e, user)
    }
    return null;
}