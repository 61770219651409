import { useColorModeValue, Text } from "@chakra-ui/react"

export default function InformationValue(props: { value: string }) {
    const colorModeValue = useColorModeValue("secondaryGray.900", "white")

    return (
        <Text color = {colorModeValue}>
            {props.value}
        </Text>
    )
}