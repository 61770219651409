import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { XboxIcon } from "../XboxIcon";


const AddConsoleButton = (props: { enabled: boolean, onClick: () => void }) => {

	const disabledColor = "#A0AEC0";
	const enabledColor = useColorModeValue('#000000', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');

	let color = props.enabled ? enabledColor : disabledColor;
	let consoleText = props.enabled ? "ID: " : "Link Xbox";

	return (
		<Box position={'relative'} textAlign={'center'} height={'70px'} onClick={props.onClick}>
			<XboxIcon color={color} />
			<Text w={'100%'} position={'absolute'} bottom={'0'} left={'50%'} transform={'translate(-50%, -50%)'} zIndex={100} color={brandColor} fontWeight='bold' fontSize='xs' _hover={{ fontSize: 'sm' }} >
				{consoleText}
			</Text>
		</Box >
	);
}

export default AddConsoleButton;
