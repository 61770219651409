import { Avatar, Button, Flex, Text, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import Card from 'lib/horizon/components/card/Card';
import { useState } from 'react';

export default function ProfileListItem(props: {
    name: string;
    date: string;
    onClick: () => void;
    onDelete: (event: React.MouseEvent) => void;
    onDuplicate: (event: React.MouseEvent) => void;
}) {
    const { name, date } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Chakra Color Mode
    const textColor = useColorModeValue('brands.900', 'white');
    const bgItem = useColorModeValue(
        {
            bg: 'secondaryGray.300',
            boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)',
            cursor: 'pointer'
        },
        { bg: 'navy.700', boxShadow: 'unset', cursor: 'pointer' }
    );

    const handleDelete = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen(true);
    };

    const handleConfirm = async (event: React.MouseEvent) => {
        setIsLoading(true);
        try {
            await props.onDelete(event);
        } finally {
            setIsOpen(false);
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsLoading(false);
    };

    return (
        <>
            <Card _hover={bgItem} bg='transparent' boxShadow='unset' px='24px' py='10px' transition='0.2s linear' onClick={props.onClick}>
                <Flex direction={{ base: 'column' }} justify='center'>
                    <Flex position='relative' align='center'>
                        <Avatar name={props.name} w='66px' h='66px' borderRadius='20px' me='16px' />
                        <Flex
                            direction='column'
                            w={{ base: '70%', md: '100%' }}
                            me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: 'md'
                                }}
                                mb='5px'
                                fontWeight='bold'
                                me='14px'>
                                {name}
                            </Text>
                            <Text
                                color='secondaryGray.600'
                                fontSize={{
                                    base: 'sm'
                                }}
                                fontWeight='400'
                                me='14px'>
                                Created {date}
                            </Text>
                        </Flex>
                        <Button
                            zIndex={10}
                            colorScheme="red"
                            variant='ghost'
                            size="sm"
                            _hover={{ background: "red.100" }}
                            onClick={handleDelete}
                        > Remove </Button>
                        <Button
                            zIndex={10}
                            colorScheme="green"
                            variant='ghost'
                            size="sm"
                            _hover={{ background: "green.100" }}
                            onClick={props.onDuplicate}
                        > Duplicate </Button>
                    </Flex>
                </Flex>

            </Card>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this profile?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            colorScheme="red"
                            mr={3}
                            onClick={handleConfirm}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="ghost"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
