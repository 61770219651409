import { useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import { FormControl, FormLabel, Text, Input } from "@chakra-ui/react"
import { Login, passwordReset } from "lib/coplay/backend/FirebaseFunctions"
import SignInFormEntry from "lib/coplay/components/views/auth/SignInFormEntry"
import SignInFormReset from "lib/coplay/components/views/auth/SignInFormReset"

export default function SignInForm(
    props: { textColor: string, textColorBrand: string, brandStars: string }
) {
    const [email, setEmail] = useState<string>(undefined);
    const [password, setPassword] = useState<string>(undefined);
    const [error, setError] = useState<string>(undefined);
    const [getResetStatus, setResetStatus] = useState<string>()
    let history = useHistory();

    const onFormSubmit = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.location.pathname === "/sign-in") {
            Login(email, password).then((res: any) => {
                console.log("Login Succeeded! res: ", res);
                history.replace('/app/dashboard');
            }).catch(() => {
                setError("Incorrect username or password");
            })
        } else {
            passwordReset(email).then(() => setResetStatus("Email sent!"))
                .catch(() => setResetStatus("Unregistered email address"))
        }
    }

    return (
        <form onSubmit={onFormSubmit}>

            <FormControl>
                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={props.textColor}
                    mb='8px'>
                    Email<Text color={props.brandStars}>*</Text>
                </FormLabel>
                <Input
                    id='emailField'
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='email'
                    placeholder='example@coplay.io'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
                <Switch>
                    <Route exact path = {"/sign-in"}>
                        <SignInFormEntry
                            setPassword = {setPassword}
                            getStatus = {error}
                            textColor = {props.textColor}
                            textColorBrand = {props.textColorBrand}
                            brandStars = {props.brandStars}
                        />
                    </Route>
                    <Route exact path = {"/sign-in/forgot-password"}>
                        <SignInFormReset getStatus = {getResetStatus} />
                    </Route>
                    <Route>
                        <Redirect to = {"/sign-in"} />
                    </Route>
                </Switch>
            </FormControl>
        </form>
    )
}