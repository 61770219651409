import { Avatar, Box, Flex, Input, InputGroup, InputRightElement, List, ListItem, useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import GameDetailsModal from "./GameDetailsModal"
import { BackendConfig } from "lib/coplay/constants/generated"
import { SearchedSoftware } from "lib/coplay/types/BackendTypes"
import { XMSuggestions } from "lib/coplay/types/FrontendTypes"

function SuggestionsList(props: {
    suggestions: XMSuggestions[],
    isLoading: boolean,
    onClick: (game: XMSuggestions) => void
}) {

    const loadingText = props.isLoading ? "Loading..." : "No results found"

    const loadingElem = (
        <ListItem p={2}>
            {loadingText}
        </ListItem>
    )

    const items = props.suggestions.map((suggest, index) => {
        return (
            <ListItem
                key={`${index}-${suggest.Title}`}
                p={2}
                _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
                onMouseDown={() => props.onClick(suggest)}
            >
                <Flex alignItems={'center'}
                >
                    <Avatar
                        name={suggest.Title}
                        marginRight="2"
                        src={suggest.ImageUrl}
                        w='50px'
                        h='50px'
                        borderRadius='20px'
                        me='16px'
                    />
                    {suggest.Title}
                </Flex>
            </ListItem>
        )
    })

    return (
        <List
            mt={2}
            borderWidth={1}
            borderRadius="md"
            zIndex={1}
            position="absolute"
            width="100%"
            bg="white"
            maxHeight={'300px'}
            overflowY='scroll'>
            {items.length > 0 ? items : loadingElem}
        </List>
    )
}

// TODO: Move to api requests
async function getSuggestions(searchText: string, abortSignal: AbortSignal): Promise<XMSuggestions[]> {
    if (searchText == '')
        return []

    const res = await fetch(BackendConfig.xboxGameSearchUrl, {
        signal: abortSignal,
        method: 'POST',
        body: JSON.stringify({
            action: "getSuggestion",
            query: searchText
        })
    })

    if (!res.ok) {
        throw Error(`Error occured while getting games: ${JSON.stringify(res)}`)
    }

    const data = await res.json()
    if (data.suggestions && data.suggestions.length > 0) {
        return data.suggestions
    } else {
        console.log("No suggestions found")
        return []
    }
}

const useDebouncedSearch = (onSelected: (app: SearchedSoftware) => void) => {
    const [inputValue, setInputValue] = React.useState("");
    const [debouncedInput, setDebouncedInput] = React.useState("");
    const [gameList, setGameList] = React.useState<XMSuggestions[]>([]); // TODO: Stub
    const [isLoading, setIsLoading] = React.useState(false);
    const [cache, setCache] = React.useState<{ [key: string]: { data: XMSuggestions[]; timestamp: number } }>(() => {
        const storedCache = localStorage.getItem("searchCache");
        return storedCache ? JSON.parse(storedCache) : {};
    });

    const handleChange = (value) => {
        setInputValue(value);
    };

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInput(inputValue);
        }, 200);

        return () => clearTimeout(timeoutId);
    }, [inputValue]);



    React.useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setDebouncedInput(inputValue);
        setIsLoading(true);

        // Cache entry expires after 6 weeks (6 * 7 * 24 * 60 * 60 * 1000)
        // cache[inputValue] && cache[inputValue].timestamp + (3628800000) > Date.now()
        if (cache[inputValue] && cache[inputValue].timestamp + (3628800000) > Date.now()) {
            setGameList(cache[inputValue].data);
            setIsLoading(false);
            console.log("Got suggestion from Cache");
        } else {
            console.log("Did not get suggestion from Cache");
            getSuggestions(inputValue, signal)
                .then((result) => {
                    setGameList(result);
                    setCache((prevCache) => {
                        const newCache = {
                            ...prevCache,
                            [inputValue]: { data: result, timestamp: Date.now() },
                        };
                        // Limit cache size to 100 entries
                        const cacheKeys = Object.keys(newCache);
                        if (cacheKeys.length > 100) {
                            const oldestKey = cacheKeys.reduce((a, b) =>
                                newCache[a].timestamp < newCache[b].timestamp ? a : b
                            );
                            delete newCache[oldestKey];
                        }
                        localStorage.setItem("searchCache", JSON.stringify(newCache));
                        return newCache;
                    });
                })
                .catch((err) => {
                    console.log("Error getting installable games: ", err);
                    setGameList([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => controller.abort();
    }, [debouncedInput, cache]);


    return {
        inputValue,
        handleChange,
        debouncedInput,
        gameList,
        isLoading,
    };
};


export function AdvancedGameSearch(props: {
    onSelected: (app: SearchedSoftware) => void,
    editing: boolean,
}) {

    const { inputValue, handleChange, debouncedInput, gameList, isLoading } = useDebouncedSearch(props.onSelected);
    const [selectedGame, setSelectedGame] = React.useState<XMSuggestions | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isFocused, setIsFocused] = useState(false);

    if (props.editing) {

        const onCloseModal = () => {
            setSelectedGame(null)
            onClose()
            setIsFocused(true)
        }

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        const onSelect = (game: XMSuggestions) => {
            setSelectedGame(game)
            onOpen()
        }

        return (
            <>
                <GameDetailsModal game={selectedGame} onSelected={props.onSelected} isOpen={isOpen} onClose={onCloseModal} />
                <Box w="100%" maxW="md" pb="15px" position="relative">
                    <InputGroup>
                        <Input
                            placeholder="Search for a Game"
                            value={inputValue}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            isDisabled={!props.editing}
                        />
                        <InputRightElement children="🔍" />
                    </InputGroup>
                    {isFocused && <SuggestionsList suggestions={gameList} isLoading={isLoading} onClick={onSelect} />}
                </Box>
            </>
        )
    } else {
        return <></>
    }


}