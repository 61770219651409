import { useState, useEffect } from "react"
import { VStack, FormControl, Flex, FormLabel, Input, FormErrorMessage }
    from "@chakra-ui/react"
import { DefaultPrivacySettings } from "lib/coplay/types/SettingsUtils"
import { renameProfile, createProfile } from "lib/coplay/backend/FirebaseFunctions"
import { UserSettings } from "lib/coplay/components/views/profiles/UserSettings"
import { AppSettings } from "lib/coplay/components/views/profiles/AppSettings"
import { GameSettings } from "lib/coplay/components/views/profiles/GameSettings"
import { CreateProfileButton }
    from "lib/coplay/components/views/profiles/CreateProfileButton"
import { FriendingAction, UserPrivacySettings, SearchedSoftware, XMProfile, SoftwareSettings } from "lib/coplay/types/BackendTypes"

export type XboxAccountSettingsController = {
    friending: FriendingAction,
    setFriending: (value: FriendingAction) => void,
    clearConversations: boolean,
    setClearConversations: (value: boolean) => void,
    getEnablePrivacy: boolean,
    setEnablePrivacy: Function,
    privacySettings: UserPrivacySettings | null,
    setPrivacySettings: (value: UserPrivacySettings) => void
}

export type SoftwareSettingsController = {
    selected: SearchedSoftware[],
    add: (app: SearchedSoftware) => void,
    remove: (app: SearchedSoftware) => void,
    reinstall: boolean,
    setReinstall: (value: boolean) => void,
    removeOthers: boolean,
    setRemoveOthers: (value: boolean) => void
}

export default function ProfileDetailsInfo(
    props: { editing?: boolean, profile?: XMProfile, onCreated?: () => void }
) {
    const [getChanged, setChanged] = useState<boolean>()
    const [name, setName] = useState<string>(props.profile ? props.profile.name : "");

    const setSetting = (setState: Function) => {
        return (settingValue: any) => {
            setChanged(true)
            setState(settingValue)
        }
    }

    const appsControler
        = useSoftwareSettings(props.profile?.consoleSettings.apps, setSetting);
    const gamesController
        = useSoftwareSettings(props.profile?.consoleSettings.games, setSetting);
    const accountController
        = useXboxAccountSettings(props.profile?.accountSettings, setSetting);


    const handleSubmit = async (): Promise<void> => {
        const profile = collateProfileData(
            props.profile?.id, name, appsControler, gamesController, accountController
        )
        if (props.profile && props.profile.name !== name) {
            await renameProfile(profile)
        }
        await createProfile(profile)
        props.onCreated();
    };

    const isValid = (name.length > 0);

    return (
        <VStack spacing={4} >

            <FormControl isInvalid={!isValid}>
                <Flex pt='15px' alignItems={'center'}>
                    <FormLabel fontWeight={'bold'} w='100px'>Profile Name:</FormLabel>
                    <Input
                        required
                        maxW='500px'
                        isDisabled={!props.editing}
                        defaultValue={name}
                        placeholder='Enter the name for this profile'
                        name="name"
                        onChange={(e) => setSetting(setName)(e.target.value as string)}
                    ></Input>
                    {!isValid ? <FormErrorMessage px='10px'>*Profile name is required.</FormErrorMessage> : <></>}
                </Flex>

            </FormControl>

            <UserSettings editing={props.editing} controller={accountController} />
            <AppSettings editing={props.editing} controller={appsControler} />
            <GameSettings editing={props.editing} controller={gamesController} />

            <CreateProfileButton
                editing={props.editing}
                onClick={handleSubmit}
                enabled={getChanged && isValid}
            />

        </VStack >
    )
}

// Helpers


const useSoftwareSettings = (consoleSettings: SoftwareSettings = {
    software: [],
    reinstall: false,
    removeOthers: false
}, setSetting: Function): SoftwareSettingsController => {
    const [selected, setSelected]
        = useState<SearchedSoftware[]>(consoleSettings.software)
    const [reinstall, setReinstall] = useState<boolean>(consoleSettings.reinstall)
    const [removeOthers, setRemoveOthers]
        = useState<boolean>(consoleSettings.removeOthers)

    const add = (app: SearchedSoftware) => {
        console.log("Adding app", app)

        if (selected.find((item) => item.name === app.name)) {
            return // App already exists
        }

        setSetting(setSelected)([...selected, app])
    }

    const remove = (app: SearchedSoftware) => {
        setSetting(setSelected)(selected.filter((item) => item.name !== app.name))
    }

    return {
        selected,
        add,
        remove,
        reinstall, setReinstall: setSetting(setReinstall),
        removeOthers, setRemoveOthers: setSetting(setRemoveOthers)
    };
}

const useXboxAccountSettings = (accountSettings: { [key: string]: any } = {
    friending: null,
    clearConversations: null
}, setSetting: Function): XboxAccountSettingsController => {
    const [friending, setFriending]
        = useState<FriendingAction | null>(accountSettings.friending)
    const [clearConversations, setClearConversations]
        = useState<boolean | null>(accountSettings.clearConversations)
    const [getEnablePrivacy, setEnablePrivacy]
        = useState<boolean>(Boolean(accountSettings.privacySettings))
    const [privacySettings, setPrivacySettings] = useState<UserPrivacySettings>(
        getEnablePrivacy ? accountSettings.privacySettings : DefaultPrivacySettings
    )

    useEffect(() => {
    }, [clearConversations]);

    return {
        friending, setFriending: setSetting(setFriending),
        clearConversations, setClearConversations: setSetting(setClearConversations),
        getEnablePrivacy,
        setEnablePrivacy: setSetting(setEnablePrivacy),
        privacySettings, setPrivacySettings: setSetting(setPrivacySettings)
    }
}

const collateProfileData = (
    id: string,
    name: string,
    appController: SoftwareSettingsController,
    gameController: SoftwareSettingsController,
    accountController: XboxAccountSettingsController): XMProfile => {
    const date = new Date();
    if (!id) {
        id = `${name.replace(/\s/g, "_")}-${date.getTime().toString()}`
    }
    const privacySettings
        = accountController.getEnablePrivacy ? accountController.privacySettings : null
    return {
        name: name,
        dateCreated: date.toISOString(),
        id,
        consoleSettings: {
            games: {
                software: gameController.selected,
                removeOthers: gameController.removeOthers,
                reinstall: gameController.reinstall
            },
            apps: {
                software: appController.selected,
                reinstall: appController.reinstall,
                removeOthers: appController.removeOthers
            },
        },
        accountSettings: {
            friending: accountController.friending,
            clearConversations: accountController.clearConversations,
            privacySettings
        }
    }
}