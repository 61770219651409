import { Flex, Select, Text, useColorModeValue } from '@chakra-ui/react';
import { useXboxUsers } from 'lib/coplay/components/DataProvider';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { SearchBar } from 'lib/horizon/components/navbar/searchBar/SearchBar';
import ProfileFilter
    from "lib/coplay/components/views/dashboard/dashboard_list/ProfileFilter"
import FilterButton from 'lib/coplay/components/views/dashboard/dashboard_list/FilterButton';
import { SelectAllCheckbox } from 'lib/coplay/components/views/dashboard/dashboard_list/SelectAllCheckbox';
import { XboxUsersSet } from 'lib/coplay/types/FrontendTypes';
import CsvDownloadButton from './ExportButton';
import { XboxUser, UserStatusMessage } from 'lib/coplay/types/BackendTypes';

enum SortOption {
    Email = 'Email',
    Xbox = "Xbox",
    GamerTag = 'GamerTag',
    Friends = 'Friends',
    Conversations = 'Conversations',
    NumFriends = 'NumFriends',
    Status = "Status",
    UpdatedAt = 'UpdatedAt'
}

const sortUsers = (users: XboxUser[], option: SortOption, isDescending: boolean) => {
    let sortedUsers = users;
    switch (option) {
        case SortOption.Email:
            sortedUsers = users.sort((a, b) => {
                if (a.emailAddress && b.emailAddress) {
                    if (a.emailAddress.toLowerCase() < b.emailAddress.toLowerCase()) {
                        return -1;
                    }
                    if (a.emailAddress.toLowerCase() > b.emailAddress.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            });
            break;
        case SortOption.Xbox:
            sortedUsers = users.sort((first, second) => {
                if (first.consoleId) {
                    if (!second.consoleId) {
                        return -1
                    }
                } else if (second.consoleId) {
                    return 1
                }
                return 0
            })
            break
        case SortOption.GamerTag:
            sortedUsers = users.sort((a, b) => {
                if (a.gamerTag && b.gamerTag) {
                    if (a.gamerTag.toLowerCase() < b.gamerTag.toLowerCase()) {
                        return -1;
                    }
                    if (a.gamerTag.toLowerCase() > b.gamerTag.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            });
            break;
        case SortOption.Conversations:
            sortedUsers = users.sort((a, b) => {
                if (a.numConversations < b.numConversations) {
                    return -1;
                }
                if (a.numConversations > b.numConversations) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.NumFriends:
            sortedUsers = users.sort((a, b) => {
                if (a.numFriends < b.numFriends) {
                    return -1;
                }
                if (a.numFriends > b.numFriends) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.Status:
            sortedUsers = users.sort((a, b) => {
                // HACK - Handle the fact that we are actually displaying user presence text, not status
                const aComp =
                    a.userStatus.statusMessage !== UserStatusMessage.Ready ? a.userStatus.statusMessage : a.presenceText;
                const bComp =
                    b.userStatus.statusMessage !== UserStatusMessage.Ready ? b.userStatus.statusMessage : b.presenceText;
                if (aComp < bComp) {
                    return -1;
                }
                if (aComp > bComp) {
                    return 1;
                }
                return 0;
            });
            break;
        case SortOption.UpdatedAt:
            sortedUsers = users.sort((a, b) => {
                if (a.updatedAt < b.updatedAt) {
                    return -1;
                }
                if (a.updatedAt > b.updatedAt) {
                    return 1;
                }
                return 0;
            }
            );
            break;

    }
    if (isDescending) {
        sortedUsers = sortedUsers.reverse();
    }
    return sortedUsers;
}

function usePreviousXboxUsers(value: XboxUsersSet) {
    const ref = useRef<XboxUsersSet>(new XboxUsersSet());
    useEffect(() => {
        ref.current = value;
    }
        , [value]);
    return ref.current;
}

function searchForUsers(
    getProfileFilter: string, users: XboxUsersSet, searchText: string
) {
    let searchResults: XboxUser[]
    if (searchText === '') {
        searchResults = users.toArray();
    } else {
        searchResults = users.filter(
            user => (user.gamerTag && user.gamerTag.toLowerCase().includes(searchText.toLowerCase()))
                || (user.emailAddress && user.emailAddress.toLowerCase().includes(searchText.toLowerCase())
                    || (user.notes?.toLowerCase().includes(searchText.toLowerCase()))
                    || (user.xmProfile?.name.toLowerCase().includes(searchText.toLowerCase()))),
        );
    }
    if (getProfileFilter === "Filter By Profile") {
        return searchResults
    }
    if (getProfileFilter === "None") {
        return searchResults.filter((user) => !user.xmProfile)
    }
    return searchResults.filter((user) => user.xmProfile?.name === getProfileFilter)
}


export default function DashboardHeader(props: { displayedUsers: XboxUser[], setDisplayedUsers: (users: XboxUser[]) => void }) {
    const [filterOption, setFilterOption] = useState(SortOption.Email);
    const [getProfileFilter, setProfileFilter] = useState<string>("Filter By Profile")
    const [filterDescending, setFilterDescending] = useState(false);
    const [searchText, setSearchText] = useState('');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const users = useXboxUsers();
    const previoususers = usePreviousXboxUsers(users);


    useEffect(() => {
        console.log("Setting selected users to displayed users")
        const searchedUsers = searchForUsers(getProfileFilter, users, searchText);
        const usersToDisplay = sortUsers(searchedUsers, filterOption, filterDescending);
        props.setDisplayedUsers(new Array<XboxUser>(...usersToDisplay));
    }, [filterOption, getProfileFilter, filterDescending, searchText]);

    // Needed so that status updates come through. Sometimes many small updates come at once though,
    // so we need to make sure we don't update the displayed users too often.
    useEffect(() => {
        console.log("AllUsers has changed")

        // if number of users has changed, update displayed users
        if (users.size() !== previoususers.size()) {
            const searchedUsers = searchForUsers(getProfileFilter, users, searchText);
            const usersToDisplay = sortUsers(searchedUsers, filterOption, filterDescending);
            props.setDisplayedUsers(new Array<XboxUser>(...usersToDisplay));
            return;
        }


        let displayedUsersUpdate: XboxUser[] = new Array<XboxUser>();

        for (let i = 0; i < props.displayedUsers.length; i++) {
            let user = props.displayedUsers[i];
            if (users.has(user))
                displayedUsersUpdate.push(users.get(user));
            else
                displayedUsersUpdate.push(user);
        }

        props.setDisplayedUsers(displayedUsersUpdate);
    }, [users]);


    const renderSearchBar = useMemo(() => {
        return (
            <div>
                <SearchBar w='300px' mx='10px' onFilter={e => setSearchText(e.target.value)} filterText={searchText} />
            </div>
        );
    }, [searchText]);


    console.log('Rendering Dashboard Header');

    return (
        <Flex
            px='25px'
            justify='space-between'
            mb='10px'
            align='center'
            style={{ flexWrap: "wrap" }}
        >
            <Flex
                px={"15px"}
                justifyContent={'center'}
                alignItems='center'
                style={{ flexWrap: "wrap" }}
            >
                <SelectAllCheckbox displayedUsers={props.displayedUsers} />
                <Text style={{
                    whiteSpace: "nowrap",
                    lineHeight: 1,
                    fontSize: "22px",
                    fontWeight: "bold"
                }} color={textColor}>
                    Your Xbox® Users
                </Text>
                <CsvDownloadButton />
                <ProfileFilter setProfileFilter={setProfileFilter} />
            </Flex>

            <Flex
                justifyContent='center'
                alignItems='center'
                style={{ flexWrap: "wrap" }}
            >
                {renderSearchBar}

                <Select
                    width={"256px"}
                    variant='filled'
                    size="md"
                    onChange={(e) => setFilterOption(e.target.value as SortOption)}
                >
                    <option value={SortOption.Email}>Sort By Email Address</option>
                    <option value={SortOption.Xbox}>
                        Sort By Xbox
                    </option>
                    <option value={SortOption.GamerTag}>Sort By Gamertag</option>
                    <option value={SortOption.Conversations}>Sort By Conversations</option>
                    <option value={SortOption.NumFriends}>Sort By Number Of Friends</option>
                    <option value={SortOption.Status}>Sort By Status</option>
                    <option value={SortOption.UpdatedAt}>Sort By Last Updated</option>
                </Select>
                <FilterButton isDescending={filterDescending} setIsDescending={setFilterDescending} />

            </Flex>

        </Flex >
    );
}

export const DashboardHeaderMemo = memo(DashboardHeader);