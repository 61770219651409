import { useEffect, useState } from "react"
import {
    Box,
    Button,
    Heading, Text, Divider
} from "@chakra-ui/react"
import Card from "lib/horizon/components/card/Card"
import CreateKeyModal from "./CreateKeyModal"
import { XMAPIKeyCrumb } from "lib/coplay/types/BackendTypes"
import { subscribeToApiKeys } from "lib/coplay/backend/FirebaseFunctions"
import APIKeysTable from "./APIKeysTable"
import APIDocumentation from "./APIDocumentation"

export default function APIView() {
    const [keys, setKeys] = useState<XMAPIKeyCrumb[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [newKeyName, setNewKeyName] = useState("")

    useEffect(() => {
        const unsubscribe = subscribeToApiKeys((fetchedKeys) => {
            setKeys(fetchedKeys)
        })
        return () => unsubscribe()
    }, [])



    return (
        <Box p={5} mt={10}>
            <Card p={5} my={7} mb={5}>
                <Heading pb={5} size="lg" >Your API Keys</Heading>
                <Text mb={3}>
                    We have made an API available to allow you to apply the profile and all of its settings to a user.
                    This use useful if you have want to integrate Coplay with another system. <strong>For example,
                        an ERM system can use this API to apply a profile and reset your xbox console and account when a patient
                        is discharged.</strong>
                </Text>
                <Text mb={3}>
                    API Keys should be handled with care. Ensure you follow best practices for security and management.
                    Make sure to record your keys somewhere secure as they cannot be recovered once created.
                </Text>
                <Divider mb={5} />
                <APIKeysTable keys={keys} />
                <Button colorScheme="teal" mt={5} onClick={() => setIsModalOpen(true)}>Create new secret key</Button>
            </Card>
            <APIDocumentation />
            <CreateKeyModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </Box>
    )
}