import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { CardComponent } from 'lib/horizon/theme/additions/card/card';
import { buttonStyles } from 'lib/horizon/theme/components/button';
import { badgeStyles } from 'lib/horizon/theme/components/badge';
import { inputStyles } from 'lib/horizon/theme/components/input';
import { progressStyles } from 'lib/horizon/theme/components/progress';
import { sliderStyles } from 'lib/horizon/theme/components/slider';
import { textareaStyles } from 'lib/horizon/theme/components/textarea';
import { switchStyles } from 'lib/horizon/theme/components/switch';
import { linkStyles } from 'lib/horizon/theme/components/link';
import { breakpoints } from 'lib/horizon/theme/foundations/breakpoints';
import { globalStyles } from 'lib/horizon/theme/styles';

export default extendTheme(
	{ breakpoints }, // Breakpoints
	globalStyles,
	badgeStyles, // badge styles
	buttonStyles, // button styles
	linkStyles, // link styles
	progressStyles, // progress styles
	sliderStyles, // slider styles
	inputStyles, // input styles
	textareaStyles, // textarea styles
	switchStyles, // switch styles
	CardComponent // card component
);

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}
