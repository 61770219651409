import { Button } from "@chakra-ui/react"

export default function EditProfileButton(
    props: { getEditing: boolean, setEditing: Function }
) {
    const onClick = () => {
        if (props.getEditing) {
            props.setEditing(false)
        } else {
            props.setEditing(true)
        }
    }

    return (
        <Button
            marginLeft = {{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            style = {{ width: "87px" }}
            zIndex={10}
            colorScheme="blue"
            variant='ghost'
            _hover = {{ background: "blue.100" }}
            onClick={onClick}
        >
            {props.getEditing ? "Cancel" : "Edit"}
        </Button>
    )
}