import { Flex, Text, Button, HStack, Avatar, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useTheme } from "@chakra-ui/react";
import { SupportedVendors } from "lib/coplay/types/PaymentTypes";
import React from "react";
import { MdAdd } from "react-icons/md";
import DisneyPlus from '../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../assets/xbox_icon.png';
import RemoteBrowserCanvas from "../../remote_browser/RemoteBrowserCanvas";
import { AggDisneyStrategy, AggXboxStrategy, BrowserStrategy } from "../../remote_browser/strategies";

export function AddVendorMemberButton(props: {
    onClick: () => void
    hideIcon: boolean,
    [key: string]: any
}) {
    const { onClick, hideIcon, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [vendor, setVendor] = React.useState(SupportedVendors.Disney);
    const [strategy, setStrategy] = React.useState<BrowserStrategy>(new AggDisneyStrategy());


    const theme = useTheme();
    const disneyColor = theme.colors.blue[500]; // Access the blue color scheme value
    const microsoftColor = theme.colors.green[500];

    const [outlineColor, setOutlineColor] = React.useState(disneyColor);

    const onSetVendor = (vendor: SupportedVendors) => {
        setVendor(vendor);
        setStrategy(vendor === SupportedVendors.Disney ? new AggDisneyStrategy() : new AggXboxStrategy());
        setOutlineColor(vendor === SupportedVendors.Disney ? disneyColor : microsoftColor);
    }

    return (
        <>
            <Button variant='action' onClick={onOpen} {...rest}>
                <Flex alignItems="center" justifyContent="center">
                    {!hideIcon && <Avatar icon={<MdAdd />} size="sm" border="1px solid" borderColor="gray.300" bg="transparent" />}
                    <Text px={2}>Add Account</Text>
                </Flex>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Vendor</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" align="center" justify="center">
                            <Text mb={4} fontWeight="bold">Choose a vendor:</Text>
                            <HStack spacing={4} mb={4}>
                                <Button
                                    leftIcon={<Avatar src={DisneyPlus} size="sm" />}
                                    onClick={() => onSetVendor(SupportedVendors.Disney)}
                                    variant={vendor === SupportedVendors.Disney ? "solid" : "outline"}
                                    colorScheme="blue"
                                >
                                    Disney
                                </Button>
                                <Button
                                    leftIcon={<Avatar src={XboxIcon} size="sm" />}
                                    onClick={() => onSetVendor(SupportedVendors.Microsoft)}
                                    variant={vendor === SupportedVendors.Microsoft ? "solid" : "outline"}
                                    colorScheme="green"
                                >
                                    Microsoft
                                </Button>

                            </HStack>
                            <RemoteBrowserCanvas outlineColor={outlineColor} strategy={strategy} onFinish={onClose} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}