/* eslint-disable */

import { useDisclosure, Flex, Link, List, ListItem, Text, useColorModeValue }
	from '@chakra-ui/react';
import TermsModal from "lib/coplay/components/modals/terms/TermsModal"

export default function Footer() {
	const disclosure = useDisclosure()

	let textColor = useColorModeValue('gray.400', 'white');
	let linkColor = useColorModeValue('gray.500', 'white');
	return (
		<>
			<Flex
				zIndex='3'
				flexDirection={{
					base: 'column',
					lg: 'row'
				}}
				justifyContent='center'
				alignItems='center'
				px={{ base: '30px', md: '0px' }}
				pb='30px'>
				<Text
					color={textColor}
					textAlign={{
						base: 'center',
						xl: 'center'
					}}
					mb={{ base: '20px', lg: '0px' }}>
					{' '}
					&copy; {new Date().getFullYear()}
					<Text as='span' fontWeight='500' ms='4px'>
						CoPlay. Technology for good.
					</Text>
				</Text>
			</Flex>
			<List display='flex'>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={linkColor} href='mailto:hello@simmmple.com'>
						Support
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={linkColor} href='https://coplay.io'>
						License
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link
						fontWeight='500'
						color={linkColor}
						onClick = {disclosure.onOpen}
					>
						Terms of Use
					</Link>
					<TermsModal disclosure = {disclosure} />
				</ListItem>
				<ListItem>
					<Link fontWeight='500' color={linkColor} href='https://coplay.io'>
						Blog
					</Link>
				</ListItem>
			</List>
		</>
	);
}
