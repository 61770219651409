import { Box } from "@chakra-ui/react";
import Footer from "lib/horizon/components/footer/FooterAdmin";
import { ReactNode } from "react";

interface ContentAreaProps {
    children: ReactNode;
}

export function ContentArea(props: ContentAreaProps) {
    return (
        <>
            <Box
                float='right'
                minHeight='100vh'
                height='100%'
                overflow='auto'
                position='relative'
                maxHeight='100%'
                padding={"20px"}
                w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                transitionDuration='.2s, .2s, .35s'
                transitionProperty='top, bottom, width'
                transitionTimingFunction='linear, linear, ease'>
                {props.children}
                <Box>
                    <Footer />
                </Box>
            </Box>
        </>
    );
}