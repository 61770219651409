import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody }
    from "@chakra-ui/react"
import TermsModalBody from "lib/coplay/components/modals/terms/TermsModalBody"

export default function TermsModal(props: { disclosure }) {
    return (
        <Modal
            isOpen = {props.disclosure.isOpen}
            size = {"6xl"}
            onClose = {props.disclosure.onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody style = {
                    { display: "flex", flexDirection: "column", rowGap: "24px" }
                }>
                    <TermsModalBody />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}