import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Spacer, Text } from '@chakra-ui/react';
import { MdNotifications, MdPerson, MdCode } from "react-icons/md";
import { SidebarLink } from "../app_layout/AppSidebar";

export function SettingsSidebarSection(props: { isDashboard: boolean }) {
    return (
        <Accordion m={0} p={0} allowMultiple defaultIndex={props.isDashboard ? [] : [0]}>
            <AccordionItem m={0} p={0} border='none'>
                <AccordionButton w={'100%'} m={0} p={0} alignItems={'center'}>
                    <Text mt={6} px={4} fontSize={'md'} fontWeight={'bold'}>Settings And Updates</Text>
                    <Spacer />
                    <AccordionIcon mt={6} mr={4} />
                </AccordionButton>
                <AccordionPanel _hover={{ cursor: 'pointer' }}>
                    <SidebarLink label="Notifications" icon={MdNotifications} routeName={'/app/notifications'} />
                    <SidebarLink label="Account" icon={MdPerson} routeName={'/app/account'} />
                    <SidebarLink label="Developer" icon={MdCode} routeName={'/app/api'} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}