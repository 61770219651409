import { useState } from "react"
import { FormLabel, Text, InputGroup, Input, InputRightElement, Icon }
    from "@chakra-ui/react"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { RiEyeCloseLine } from "react-icons/ri"

export default function PasswordInput(props: {
    setPassword: React.Dispatch<React.SetStateAction<string>>,
    textColor: string,
    brandStars: string,
    labelText: string
}) {
    const [show, setShow] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setPassword(e.target.value)
    }

    const handleClick = () => {
        setShow(!show);
    }

    return (
        <>
            <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={props.textColor}
                display='flex'>
                {props.labelText}<Text color={props.brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    mb='24px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    onChange={onChange}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={"gray.400"}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                    />
                </InputRightElement>
            </InputGroup>
        </>
    )
}