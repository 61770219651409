import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { useState } from "react";

export function CreateProfileButton(
    props: { editing: boolean, onClick: () => Promise<void>, enabled: boolean }
) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let createProfileButton: JSX.Element
    if (props.editing) {
        const handleSubmit = () => {
            setIsOpen(true);  // Open the modal
        };

        const handleConfirm = () => {
            setIsLoading(true);
            props.onClick().catch((error) => {
                console.error(`Error creating profile: ${error}`)
            })
        };

        createProfileButton = (
            <>
                <Button
                    isLoading={isLoading}
                    isDisabled={!props.enabled}
                    colorScheme="blue"
                    onClick={handleSubmit}
                >
                    Save Profile
                </Button>

                <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirmation</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to save this profile?
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isLoading={isLoading}
                                colorScheme="blue"
                                mr={3}
                                onClick={handleConfirm}
                            >
                                Confirm
                            </Button>
                            <Button
                                isLoading={isLoading}
                                variant="ghost"
                                onClick={() => setIsOpen(false)}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    } else {
        createProfileButton = null
    }

    return createProfileButton
};
