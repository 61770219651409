import { Text, Button, Flex, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { changeXMProfile } from "lib/coplay/backend/ApiRequests";
import { useSelectedUsers } from "lib/coplay/components/DataProvider";
import { addXboxUsersObserver } from "lib/coplay/backend/FirebaseFunctions";
import { ActionLinkElem, ACTION_LINK_TIMEOUT } from "lib/coplay/components/actions/ActionLink";
import { BaseAction, ViewState, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "lib/coplay/components/actions/BaseAction";
import { BasicResponse, PartialXMProfile } from "lib/coplay/types/BackendTypes";


const ActionLink: BaseActionProps['ActionElem'] = (props: { onClickHandler: () => void }) => {

    let label = 'Remove Profile';
    let icon = <Icon as={MdOutlinePlaylistRemove} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}

const RemoveProfilesDialog: BaseActionProps['DialogElem'] = (props: {
    handleClose: () => void,
    setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
    setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
}) => {
    const selectedUsers = useSelectedUsers();

    const handler: () => void = () => {

        props.setViewState(ViewState.Loading);
        const timeout = setTimeout(() => {
            props.setViewState(ViewState.Warning);
        }, ACTION_LINK_TIMEOUT);

        addXboxUsersObserver(() => {
            props.handleClose();
            clearTimeout(timeout);
        })

        const selected: string[] = [];
        const emptyProfile: PartialXMProfile = {
            id: "",
            name: "",
        }
        selectedUsers.forEach(user => selected.push(user.emailAddress))
        changeXMProfile(selected, 'unset', emptyProfile).then((response) => {
            props.handleClose();
            clearTimeout(timeout);
            props.setViewState(ViewState.Success);
            props.setActionResponse(response);
        }).catch((error) => {
            console.log("[RemoveProfilesScreen] error", error)
            clearTimeout(timeout);
            props.setViewState(ViewState.Error);
        });
    }

    return (
        <>
            <ModalHeader>Remove Profiles</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Text py='25px'>Are you sure you want to remove the profiles for {selectedUsers.size()} user(s)?</Text>
                    <Button width='200px' variant="brand" onClick={handler}>Confirm</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

export function RemoveProfileActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={ActionLink}
                DialogElem={RemoveProfilesDialog}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}