import { useEffect, useState } from 'react';
import { Box, Button, Flex, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import Card from 'lib/horizon/components/card/Card';

export default function SpotlightNotes(props: { notes?: string, updateNotes: (notes: string) => void, [x: string]: any }) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const [originalNotes, setOriginalNotes] = useState<string>(props?.notes || '');

    useEffect(() => {
        setOriginalNotes(props?.notes || '');
    }, [props.notes]);

    let handleInputChange = (e: { target: { value: string }; }) => {
        let updatedNotes = e.target.value;
        setOriginalNotes(updatedNotes);
    }

    const bg = useColorModeValue('gray.100', 'navy.700');
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

    const isButtonDisabled = originalNotes === props?.notes;

    return (
        <Card mb={{ base: '0px', lg: '20px' }} alignItems='center'>
            <Flex w='100%'>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
                    Notes
                </Text>
            </Flex>
            <Box w='100%'>
                <Text
                    color={textColorSecondary}
                    fontSize='md'
                    mx='auto'
                    pb='2px'
                    textAlign='left'>
                    Add useful notes to your account
                </Text>
            </Box>

            <>
                <Textarea
                    bg={bg}
                    pt='10px'
                    h='250px'
                    placeholder='Add your notes here!'
                    borderColor={borderColor}
                    borderRadius='16px'
                    onChange={handleInputChange}
                    value={originalNotes}
                    defaultValue={props?.notes || ''}
                >
                </Textarea>
                <Button w='100%' mt='20px' variant='brand' onClick={() => { props.updateNotes(originalNotes) }} disabled={isButtonDisabled}>Save</Button>
            </>
        </Card>
    );
}
