import { FormControl, FormLabel, Checkbox, Text } from "@chakra-ui/react";
import { SoftwareSettingsController } from "lib/coplay/components/views/profiles/ProfileDetailsInfo";
import SoftwareDescription from "lib/coplay/components/views/profiles/SoftwareDescription";
import { SoftwareContainer } from "lib/coplay/components/views/profiles/SoftwareSearch";
import { AdvancedGameSearch } from "./AdvancedGameSearch";

export function GameSettings(props: {
    editing: boolean, controller: SoftwareSettingsController
}) {

    return (
        <>
            <FormControl>
                <FormLabel fontWeight='bold'>Games</FormLabel>
                <Text>
                    <SoftwareDescription
                        editing={props.editing} softwareCategory={"game"}
                    />
                </Text>
                <SoftwareContainer
                    apps={props.controller.selected}
                    editing={props.editing}
                    remove={props.controller.remove}
                    softwareCategory={"game"}
                />
            </FormControl>
            <FormControl>
                <div style={{ height: "55px", maxWidth: "448px" }}>
                    <AdvancedGameSearch
                        editing={props.editing}
                        onSelected={props.controller.add}
                    />
                </div>
                <Checkbox py='10px'
                    isDisabled={!props.editing}
                    defaultChecked={props.controller.removeOthers}
                    checked={props.controller.removeOthers}
                    onChange={() => props.controller.setRemoveOthers(!props.controller.removeOthers)}
                >
                    Remove all other installed games
                </Checkbox>
            </FormControl>
        </>
    )
}