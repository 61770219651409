import { Icon } from "@chakra-ui/react";
import { MdCalendarToday } from "react-icons/md";
import { useSelectedUsers } from "../../DataProvider";
import { ActionLinkElem } from "../ActionLink";
import { BaseAction, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "../BaseAction";
import 'react-calendar/dist/Calendar.css';
import 'lib/horizon/assets/css/MiniCalendar.css';
import { ScheduleActionDialog } from "./ScheduleActionModal";

const actionLink: BaseActionProps['ActionElem'] = (props: { onClickHandler: () => void }) => {
    return (
        <>
            <ScheduleLinkAction onClickHandler={props.onClickHandler} />
        </>
    )
}


function ScheduleLinkAction(props: { onClickHandler: () => void }) {
    let label = 'Schedule Action';
    let icon = <Icon as={MdCalendarToday} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}


export function ScheduleActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={actionLink}
                DialogElem={ScheduleActionDialog}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}

