import { useColorModeValue, Icon, Box } from "@chakra-ui/react"
import { MdBarChart } from "react-icons/md"
import { useXboxUsers } from "lib/coplay/components/DataProvider"
import MiniStatistics from "lib/horizon/components/card/MiniStatistics"
import IconBox from "lib/horizon/components/icons/IconBox"
import { useUserLimit } from "lib/coplay/context/ConfigurationContext"

export default function TotalUsers() {
    const userLimit = useUserLimit();
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const users = useXboxUsers();
    let numUsers = String(users.size())
    if (userLimit) {
        numUsers += `/${userLimit}`
    }

    return (
        <Box maxWidth="300px">
            <MiniStatistics
                startContent={
                    <IconBox
                        w='50px'
                        h='50px'
                        bg={boxBg}
                        icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
                    />
                }
                name='Users'
                value={numUsers}
            />
        </Box>
    )
}