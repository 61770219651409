import { useHistory } from "react-router-dom"
import { Box } from '@chakra-ui/react';
import UsersList from 'lib/coplay/components/views/dashboard/dashboard_list/UsersList';
import Card from 'lib/horizon/components/card/Card';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DashboardHeaderMemo } from 'lib/coplay/components/views/dashboard/DashboardHeader';
import DashboardInfo from 'lib/coplay/components/views/dashboard/DashboardInfo';
import SpotlightView from './dashboard_list/spotlight/SpotlightView';
import { XboxUser } from "lib/coplay/types/BackendTypes";

export default function Dashboard() {
    console.log('Rendering Dashboard');

    const history = useHistory<XboxUser>()

    const [displayedUsers, setDisplayedUsers] = useState<XboxUser[]>([]);
    const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);
    const [lastScrollPosition, setScrollPosition] = useState<number>(0);

    const openSpotlightUser = useCallback((user: XboxUser) => {
        history.push("/app/dashboard", user)
        setScrollPosition(window.scrollY);
    }, [history]);

    let setDisplayedRef = useRef<(users: XboxUser[]) => void>();
    setDisplayedRef.current = (users: XboxUser[]) => {
        setDisplayedUsers(users);
    };
    const stableSetDisplayed = useCallback((users: XboxUser[]) => {
        setDisplayedRef.current?.(users);
    }, []);

    useEffect(() => {
        if (!history.location.state) {
            window.scrollTo(0, lastScrollPosition);
        }
    }, [history.location.state]);

    return (
        <Box style={{ zoom: "87%" }} pt={{ base: '80px', xl: '80px' }}>
            <div style={{ display: !history.location.state ? 'none' : '' }} >
                <SpotlightView user={history.location.state} />
            </div>
            <div style={
                { display: history.location.state ? 'none' : '', overflowX: "hidden" }
            }>
                <DashboardInfo isRefreshing={isAutoRefreshing} />
                <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'scroll' }}>
                    <DashboardHeaderMemo displayedUsers={displayedUsers} setDisplayedUsers={stableSetDisplayed} />
                    <UsersList openSpotlightUser={openSpotlightUser} displayedUsers={displayedUsers} />
                </Card>
            </div>
        </Box>
    );
}
