import { useState } from "react";
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, useColorModeValue, Text, Box, Icon, Divider } from "@chakra-ui/react";
import { useSetSelectedUsers } from "lib/coplay/components/DataProvider";
import { Loading } from "lib/coplay/components/Loading";
import { MdCheckCircleOutline, MdWarningAmber } from "react-icons/md";
import { XboxUsersSet } from "lib/coplay/types/FrontendTypes";
import React from "react";
import { BasicResponse } from "lib/coplay/types/BackendTypes";

export enum ViewState {
    Dialog = 'dialog',
    Error = 'error',
    Warning = 'warning',
    Loading = 'loading',
    Success = 'success'
}

// Meant as an example
//tslint:ignore-next-line
function createBaseDialogScreen(handleClose: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>) {
    return (
        <BaseDialogScreen close={handleClose} setViewState={setViewState} setActionResponse={setActionResponse} />
    )
}

function BaseDialogScreen(props: { close: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>> }) {

    return (
        <>
            <ModalHeader>Dialog</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Box>
                    <Text> This is a dialog. </Text>
                </Box>
                <Flex justifyContent='center' w='100%'>
                    <Button width='200px' variant="brand" mr={3} onClick={props.close}> Submit Login Code </Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

export function SimpleLoadingElem(setViewState: React.Dispatch<React.SetStateAction<ViewState>>, timeoutMs: number) {
    return (
        <SimpleLoadingScreen setViewState={setViewState} timeoutMs={timeoutMs} />
    )
}

function SimpleLoadingScreen(props: { setViewState: React.Dispatch<React.SetStateAction<ViewState>>, timeoutMs: number }) {

    return (
        <Loading />
    )
}


export function SimpleErrorScreen(close: () => void, response: BasicResponse) {
    return (
        <BaseErrorScreen close={close} response={response} />
    )
}

export const BaseErrorScreen: BaseActionProps['ErrorElem'] = ({ close, response }) => {

    let errorMessage: JSX.Element = (
        <>
            <Box py='10px' textAlign='center'>
                <Text>Could not communicate with CoPlay Servers. Please contact Support</Text>
            </Box>
        </>
    )

    if (response?.emailAddress != undefined) {
        errorMessage = (
            <>
                <Box py='10px' textAlign='center'>
                    <Text>{response.error?.userMessage}</Text>
                    <Text>{response.error?.suggestion}</Text>
                    <Box py='10px'>
                        <details>
                            <summary>More Info</summary>
                            <Box py='5px'>
                                <Divider />
                                <Box textAlign='left'>
                                    <Text>{response.emailAddress}</Text>
                                    <Text>ID: {response.requestId}</Text>
                                    <Text>{response.error?.errorMessage}</Text>
                                </Box>
                                <Divider />
                            </Box>
                        </details>
                    </Box>
                </Box>
            </>
        )
    }




    return (
        <>
            <ModalHeader>Uh Oh! Something went wrong</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center'>
                    {errorMessage}
                </Flex>
                <Flex py='10px' justifyContent='center' w='100%'>
                    <Button width='200px' variant="brand" onClick={close}>Close</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

export function createBaseWarningScreen(close: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>) {
    return (
        <BaseWarningScreen close={close} setViewState={setViewState} />
    )
}

function BaseWarningScreen(props: { close: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>> }) {
    let color = useColorModeValue('brand.500', 'white');

    return (
        <>
            <ModalHeader>Hmmm...</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Icon as={MdWarningAmber} width='100px' height='100px' color={color} />,
                    <Text textAlign='center' paddingBottom='15px'>The request is taking longer then normal. Check your internet connection or try again later.</Text>
                    <Button width='200px' variant="brand" onClick={props.close}>Close</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

export function createBaseSuccessScreen(close: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, response: BasicResponse) {
    return (
        <BaseSuccessScreen close={close} setViewState={setViewState} response={response} />
    )
}

export const BaseSuccessScreen: BaseActionProps['SuccessElem'] = ({
    close,
    setViewState,
    response
}) => {
    let color = useColorModeValue('brand.500', 'white');

    return (
        <>
            <ModalHeader>Success!</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Icon as={MdCheckCircleOutline} width='100px' height='100px' color={color} />,
                    <Text py='25px'>We did it!</Text>
                    <Button width='200px' variant="brand" onClick={close}>Close</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

function createBaseActionElem(props: { onClickHandler: () => void, label?: string }) {
    return (
        <>
            <Button width="150px" height='40px' variant="brand" onClick={props.onClickHandler} mx='5px'>Base Action Button</Button>
        </>
    )
}

export interface BaseActionProps {
    ActionElem: React.FC<{
        onClickHandler: () => void
    }>
    DialogElem: React.FC<{
        handleClose: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
    }>
    LoadingElem?: React.FC<{
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        timeoutMs: number
    }>
    ErrorElem: React.FC<{
        close: () => void,
        response: BasicResponse
    }>
    SuccessElem: React.FC<{
        close: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        response: BasicResponse
    }>
    WarningElem?: React.FC<{
        close: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>
    }>
}

export const BaseAction: React.FC<BaseActionProps> = ({ ActionElem, DialogElem, LoadingElem, ErrorElem, SuccessElem, WarningElem }) => {

    const [viewState, setViewState] = useState<ViewState>(ViewState.Dialog);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const setSelectedUsers = useSetSelectedUsers();
    const [actionResponse, setActionResponse] = useState<BasicResponse>();

    const NewWarningScreen: React.FC<any> = WarningElem ?? createBaseWarningScreen;
    const NewLoadingElem: React.FC<any> = LoadingElem ?? SimpleLoadingElem;

    const resetOnClose = () => {
        setViewState(ViewState.Dialog);
        setActionResponse(undefined);
        setSelectedUsers(new XboxUsersSet());
        onClose();
    }

    const resetOnOpen = () => {
        setViewState(ViewState.Dialog);
        setActionResponse(undefined);
        onOpen();
    }

    return (
        <>
            <ActionElem onClickHandler={resetOnOpen} />
            <Modal isOpen={isOpen} onClose={resetOnClose} size='xl'>
                <ModalOverlay />
                <ModalContent>
                    {viewState === ViewState.Dialog &&
                        <DialogElem
                            handleClose={resetOnClose}
                            setViewState={setViewState}
                            setActionResponse={setActionResponse}
                        />}
                    {viewState === ViewState.Loading && <NewLoadingElem setViewState={setViewState} timeoutMs={20000} />}
                    {viewState === ViewState.Error && <ErrorElem close={resetOnClose} response={actionResponse} />}
                    {viewState === ViewState.Warning && <NewWarningScreen resetOnClose={resetOnClose} setViewState={setViewState} />}
                    {viewState === ViewState.Success && <SuccessElem close={resetOnClose} setViewState={setViewState} response={actionResponse} />}
                </ModalContent>
            </Modal>
        </>
    )
}