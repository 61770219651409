import { useEffect, useState, createContext, useContext } from 'react';
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import { getXboxUsers, subscribeToXboxUsers, getContactDetails, updateContactDetails }
    from 'lib/coplay/backend/FirebaseFunctions';
import { ContactDetails, EmptyContactDetails, XboxUsersSet } from 'lib/coplay/types/FrontendTypes';

type ContextState = {
    loggedIn: boolean,
    xboxUsers: XboxUsersSet,
    selectedUsers: XboxUsersSet,
    setSelectedUsers: (users: XboxUsersSet) => void,
    contactDetails: ContactDetails,
    setContactDetails: React.Dispatch<React.SetStateAction<ContactDetails>>
}

const DataContext = createContext<ContextState | undefined>(undefined);

const DataProvider = (props: { children: any }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [xboxUsers, setXboxUsers] = useState<XboxUsersSet>(new XboxUsersSet());
    const [loading, setLoading] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState<XboxUsersSet>(new XboxUsersSet());
    const [contactDetails, setContactDetails] = useState<ContactDetails>(EmptyContactDetails);

    const login = (user: FirebaseUser) => {
        setLoggedIn(true);
        setLoading(false);
        getXboxUsers(setXboxUsers);
    };

    const logout = () => {
        setLoggedIn(undefined);
        setLoading(false);
        setXboxUsers(new XboxUsersSet());
        setContactDetails(EmptyContactDetails);
        setSelectedUsers(new XboxUsersSet());
    };

    const handleAuthStateChanged = (user: FirebaseUser) => {
        if (user) {
            login(user);
        } else {
            logout();
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChanged);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (!loggedIn) return;
        const unsubscribe = subscribeToXboxUsers(setXboxUsers);
        return unsubscribe;
    }, [loggedIn]);

    useEffect(() => {
        if (!loggedIn) return;
        getContactDetails(setContactDetails);
        return () => { };
    }, [loggedIn]);


    const value = {
        loggedIn,
        xboxUsers,
        selectedUsers,
        setSelectedUsers,
        contactDetails,
        setContactDetails
    };

    return (
        <DataContext.Provider value={value}>
            {!loading && props.children}
        </DataContext.Provider>
    );
}

const useDataProvider = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a DataProvider');
    }
    return context;
}

const useIsLoggedIn = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useIsLoggedIn must be used within a DataProvider');
    }
    return context.loggedIn;
}

const useSetSelectedUsers = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useSetSelectedUsers must be used within a DataProvider');
    }
    return context.setSelectedUsers;
}

const useSelectedUsers = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useSelectedUsers must be used within a DataProvider');
    }
    return context.selectedUsers;
}

const useXboxUsers = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useXboxUsers must be used within a DataProvider');
    }
    return context.xboxUsers;
}

const useContactDetails = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useContactDetails must be used within a DataProvider');
    }

    return context.contactDetails;
}

const useUpdateContactDetails = () => {
    const context = useContext(DataContext)
    if (context === undefined) {
        throw new Error("useUpdateContactDetails must be used within a DataProvider")
    }
    return function(enteredDetails: { [key: string]: string }) {
        updateContactDetails(enteredDetails)
        context.setContactDetails({ ...context.contactDetails, ...enteredDetails })
    }
}

export {
    DataProvider,
    useDataProvider,
    useIsLoggedIn,
    useXboxUsers,
    useSetSelectedUsers,
    useSelectedUsers,
    useContactDetails,
    useUpdateContactDetails
};