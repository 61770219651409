// Chakra imports
import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Footer from 'lib/horizon/components/footer/FooterAuth';


function AuthIllustration(props: { children: JSX.Element | string; }) {
	const { children } = props;
	// Chakra color mode
	return (
		<Flex id='AuthIllustration' position='relative' h='max-content' align='center' justifyContent='center'>
			<Flex
				h={{
					sm: 'initial',
					md: 'unset',
					lg: '100vh',
					xl: '97vh'
				}}
				alignItems='center'
				w='100%'
				maxW={{ md: '66%', lg: '1313px' }}
				mx='auto'
				pt={{ sm: '50px', md: '0px' }}
				// px={{ lg: '30px', xl: '0px' }}
				// ps={{ xl: '70px' }}
				direction='column'>
				{children}
				<Footer />
			</Flex>
		</Flex>
	);
}
// PROPS

AuthIllustration.propTypes = {
	image: PropTypes.any
};

export default AuthIllustration;
