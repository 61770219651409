import React from 'react';
import Chart from 'react-apexcharts';

type ChartProps = {
	// using `interface` is also ok
	[x: string]: any;
};
type ChartState = {
	chartData: any[];
	chartOptions: any;
};

class ColumnChart extends React.Component<ChartProps, ChartState> {
	constructor(props: { chartData: any[]; chartOptions: any }) {
		super(props);
		this.state = {
			chartData: [],
			chartOptions: {}
		};
	}

	// componentDidMount() {
	// 	this.setState({
	// 		chartData: this.props.chartData,
	// 		chartOptions: this.props.chartOptions
	// 	});
	// }

	// componentDidUpdate(prevProps: { chartData: any[]; chartOptions: any }) {
	// 	if (prevProps.chartData !== this.props.chartData) {
	// 		this.setState({
	// 			chartData: this.props.chartData,
	// 			chartOptions: this.props.chartOptions
	// 		});
	// 	}
	// }

	render() {
		return (
			<Chart
				options={this.props.chartOptions}
				series={this.props.chartData}
				type='bar'
				width='100%'
				height='100%'
			/>
		);
	}
}

export default ColumnChart;
