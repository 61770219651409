import { Flex, Progress, Spinner, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";


export function Loading() {
    return (
        <>
            <Flex direction='column' alignItems='center' justifyContent='center' py='50px'>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
            <Text py='25px' align='center'>
                Wait for it...
            </Text>
        </>
    )
}


export const LoadingProgressBar = ({ color = 'blue.500', time = 60, label = 'Working...' }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100; // Ensure it doesn't exceed 100
                }
                return prev + (100 / time); // Increment progress over specified time
            });
        }, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on unmount
    }, [time]);

    return (
        <Flex direction='column' alignItems='center' justifyContent='center' py='50px'>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color={color}
                size='lg'
                mb={4}
            />
            <Progress value={progress} size='sm' width='100%' mt={4} colorScheme={color.split('.')[0]} />
            <Text mt={2}>{label} {progress.toFixed(0)}%</Text>
        </Flex>
    )
}