import { NavLink } from "react-router-dom"
import { FormLabel, Text, Flex, FormControl, Checkbox } from "@chakra-ui/react"
import PasswordInput from "lib/coplay/components/views/auth/PasswordInput"
import AuthStatus from "lib/coplay/components/views/auth/AuthStatus"
import AuthButton from "lib/coplay/components/views/auth/AuthButton"

export default function SignInFormEntry(props: {
    setPassword: React.Dispatch<React.SetStateAction<string>>,
    getStatus: string,
    textColor: string,
    textColorBrand: string,
    brandStars: string
}) {
    return (
        <>
            <PasswordInput
                setPassword = {props.setPassword}
                textColor = {props.textColor}
                brandStars = {props.brandStars}
                labelText = {"Password"}
            />
            <AuthStatus getStatus = {props.getStatus} />
            <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                    <Checkbox
                        id='remember-login'
                        colorScheme='brandScheme'
                        me='10px'
                    />
                    <FormLabel
                        htmlFor='remember-login'
                        mb='0'
                        fontWeight='normal'
                        color={props.textColor}
                        fontSize='sm'>
                        Keep me logged in
                    </FormLabel>
                </FormControl>
                <NavLink to='/sign-in/forgot-password'>
                    <Text
                        color={props.textColorBrand}
                        fontSize='sm'
                        w='124px'
                        fontWeight='500'>
                        Forgot password?
                    </Text>
                </NavLink>
            </Flex>
            <AuthButton>
                Sign In
            </AuthButton>
        </>
    )
}