import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Card from 'lib/horizon/components/card/Card';
import { PaymentsLoadingState } from './PaymentsLoadingState';
import { PaymentsZeroState } from './PaymentsZeroState';
import PaymentsHeader from './PaymentsHeader'; // Import the new component
import { VendorMember } from 'lib/coplay/types/PaymentTypes';
import { subscribeToVendorMembers } from 'lib/coplay/backend/FirebaseFunctions';
import { PaymentsList } from './PaymentsList';


enum PaymentsViewState {
    LOADING = "Loading",
    LIST = "List",
}

export default function PaymentsView() {
    const [selectedService, setSelectedService] = React.useState<string | null>('Xbox'); // State for selected service
    const [viewState, setViewState] = React.useState<PaymentsViewState>(PaymentsViewState.LIST);
    const [vendorMembers, setVendorMembers] = React.useState<VendorMember[]>([]);


    React.useEffect(() => {
        const unsubscribe = subscribeToVendorMembers(setVendorMembers);
        setViewState(PaymentsViewState.LIST);
        return () => unsubscribe();
    }, []);

    return (
        <Box pt={{ base: '80px', xl: '80px' }} pb="10px" h="93vh" >
            <Card h='100%' overflowY='scroll' >
                {viewState === PaymentsViewState.LOADING && <PaymentsLoadingState />}
                {viewState === PaymentsViewState.LIST && <PaymentsList selectedService={selectedService} setSelectedService={setSelectedService} vendorMembers={vendorMembers} />}
            </Card>
        </Box>
    );
}