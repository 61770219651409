// Chakra imports
import { Flex, useColorModeValue, Heading } from '@chakra-ui/react';
import { IoGameController } from 'react-icons/io5';
// Custom components
import { HSeparator } from 'lib/horizon/components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column' justifyContent='center'>
			<Flex alignItems='center' flexDirection='row' width='80%' justifyContent='center'>
				<IoGameController style={{ marginRight: '10px' }} size='35px' color={logoColor} />
				<Heading textAlign='center' fontSize='3xl' py={5}>
					CoPlay
				</Heading>
			</Flex>
			<HSeparator mb='10px' />
		</Flex>
	);
}

export default SidebarBrand;
