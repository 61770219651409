import { ModalHeader, ModalCloseButton, ModalBody, Box, FormLabel, Input, Text, Link, Flex, Button, ModalFooter } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { XboxLoginUrl } from "lib/coplay/constants/generated";
import { useState, ChangeEvent } from "react";


export function CreateUserModal(props: { close: () => void, handler: (loginCode: string) => void }) {
    return (
        <UserModalScreen
            close={props.close}
            handler={props.handler}
            heading="Add A New Xbox Live® User"
            body="Adding a new Xbox Live® user to CoPlay XM is easy! All you need to do is get a login code from Microsoft. To do this:"
        />
    )
}

export function ResetUserModal(props: { close: () => void, handler: (loginCode: string) => void, emailAddress: string }) {
    return (
        <UserModalScreen
            close={props.close}
            handler={props.handler}
            heading={"Resetting Xbox Live® account for " + props.emailAddress}
            body="Sometimes a user's Xbox Live® account can get into a bad state. If you're having trouble, try resetting their account. To do this:"
            reAggEmail={props.emailAddress}
        />
    )
}

function UserModalScreen(props: { close: () => void, handler: (loginCode: string) => void, heading: string, body: string, reAggEmail?: string }) {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [loginCode, setLoginCode] = useState<string>("");

    const loginUrl = props.reAggEmail ? 
        XboxLoginUrl + encodeURIComponent("&login_hint=" + props.reAggEmail) : 
        XboxLoginUrl

    const loginLink: JSX.Element = <Link
        fontWeight='900'
        color={textColorBrand}
        href={loginUrl}
        target="_blank"
        rel="noopener noreferrer">
        special login link
    </Link>

    return (
        <>
            <ModalHeader>{props.heading}</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Box>
                    <strong>{props.body}</strong>
                    <Text style={{ paddingTop: "20px" }}> 1. Click on our {loginLink} </Text>
                    <p>2. Log into your Xbox Live® account </p>
                    <p>3. Copy the login code you obtain after logging in</p>
                    <p>4. Paste the login code below</p>
                    <p>5. Click 'Submit Login Code'</p>
                    <br></br>
                </Box>

                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Paste your login code here
                </FormLabel>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Paste your login code here'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='outline'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLoginCode(e.target.value)}
                />
                <Flex justifyContent='center' w='100%'>
                    <Button width='200px' variant="brand" mr={3} onClick={() => { props.handler(loginCode) }}>Submit Login Code</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}