import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { subscribeToConfiguration } from '../backend/FirebaseFunctions';
import { FrontendConfiguration } from '../types/FrontendTypes';

interface ConfigurationContextType {
    numUsers?: number;
    hasPayments?: boolean;
}

const ConfigurationContext = createContext<ConfigurationContextType | undefined>(undefined);

export const useConfiguration = () => {
    const context = useContext(ConfigurationContext);
    if (!context) {
        throw new Error('useConfiguration must be used within a ConfigurationProvider');
    }
    return context;
};

export const useUserLimit = () => {
    const config = useConfiguration();
    return config.numUsers;
};

export const useHasPayments = () => {
    const { hasPayments } = useConfiguration();
    return hasPayments;
};

interface ConfigurationProviderProps {
    children: ReactNode;
}

export const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({ children }) => {
    const [config, setConfig] = useState<FrontendConfiguration>({});

    useEffect(() => {
        const unsubscribe = subscribeToConfiguration(setConfig);
        return () => unsubscribe();
    }, []);

    return (
        <ConfigurationContext.Provider value={config}>
            {children}
        </ConfigurationContext.Provider>
    );
};
