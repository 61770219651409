import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, Flex } from '@chakra-ui/react';
import { getVendorOrders } from 'lib/coplay/backend/FirebaseFunctions';
import { SimpleOrder, VendorMember } from 'lib/coplay/types/PaymentTypes';

interface OrdersTabProps {
    member: VendorMember;
}

const OrdersTab: React.FC<OrdersTabProps> = ({ member }) => {
    const [orders, setOrders] = useState<SimpleOrder[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getVendorOrders(member.email).then((orders) => {
            setOrders(orders);
            setLoading(false);
        }).catch(console.error);
    }, [member.email]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <Spinner />
            </Box>
        );
    }

    return (
        <Box overflowY="auto" maxH="500px">
            <Text fontSize="xl" fontWeight="bold" mb={4}>Orders</Text>
            {orders.length === 0 ? (
                <Text>No orders found.</Text>
            ) : (
                <Box>
                    {orders.map((order) => (
                        <Box key={order.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} mb={2}>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Text fontSize="sm" color="gray.500">{order.date} | Order number {order.id}</Text>
                                <Text fontSize="sm" color="gray.500">Total {order.amount}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <Box>
                                    <Text fontWeight="bold">{order.items[0]?.name || 'Product Name'}</Text>
                                    <Text>{order.items[0]?.amount || '0.00'}</Text>
                                    <Text color="green.500">{order.items[0]?.status || 'N/A'}</Text>
                                </Box>
                            </Flex>
                            {order.items.length > 1 && (
                                <Box mt={2}>
                                    {order.items.slice(1).map((item, index) => (
                                        <Box key={index} mt={2}>
                                            <Text>{item.name}</Text>
                                            <Text>{item.amount}</Text>
                                            <Text color="green.500">{item.status}</Text>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default OrdersTab;