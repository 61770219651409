import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { XMError } from "lib/coplay/types/BackendTypes";
import { emptyXMError } from "lib/coplay/utils";
import { useState } from "react";
import { AddConsoleErrorScreen } from "./AddConsoleErrorScreen";
import { FindConsolesScreen } from "./FindConsoleScreen";
import { FoundConsolesScreen } from "./FoundConsoleScreen";
import { LinkingConsoleScreen } from "./LinkConsoleScreen";

// Add enums for each state
enum AddConsoleState {
    NO_EMAIL = "NO_EMAIL",
    FINDING_CONSOLE = "FINDING_CONSOLE",
    FOUND_CONSOLES = "FOUND_CONSOLES",
    LINKING_CONSOLE = "LINKING_CONSOLE",
    ERROR = "ERROR"
}

export const AddConsoleModal = (props: { emailAddress: string, isOpen: boolean, onClose: () => void }) => {
    console.log("[AddConsoleModal] Email Address: " + props.emailAddress)
    const { onClose } = useDisclosure();
    const [progress, setProgress] = useState(AddConsoleState.FINDING_CONSOLE);
    const [xboxIds, setXboxIds] = useState<string[]>([]);
    const [selectedXboxId, setSelectedXboxId] = useState<string>("");
    const [error, setError] = useState<XMError>(emptyXMError());



    const onFoundConsoles = (xboxIds: string[]) => {
        setXboxIds(xboxIds);
        setProgress(AddConsoleState.FOUND_CONSOLES);
    }

    const onSelectedXboxId = (xboxId: string) => {
        setSelectedXboxId(xboxId);
        setProgress(AddConsoleState.LINKING_CONSOLE);
    }

    const onError = (error: XMError) => {
        setProgress(AddConsoleState.ERROR);
        setError(error);
    }

    const resetOnClose = () => {
        setProgress(props.emailAddress ? AddConsoleState.FINDING_CONSOLE : AddConsoleState.NO_EMAIL);
        setXboxIds([]);
        setSelectedXboxId("");
        setError(emptyXMError());
        props.onClose()
        onClose();
    }

    const states = {
        NO_EMAIL: <div>No User Selected.</div>,
        FINDING_CONSOLE: <FindConsolesScreen emailAddress={props.emailAddress} onError={onError} onFound={onFoundConsoles} />,
        FOUND_CONSOLES: <FoundConsolesScreen xboxIds={xboxIds} onSelected={onSelectedXboxId} />,
        LINKING_CONSOLE: <LinkingConsoleScreen emailAddress={props.emailAddress} onError={onError} xboxId={selectedXboxId} />,
        ERROR: <AddConsoleErrorScreen error={error} />,
    }

    return (
        <Modal isOpen={props.isOpen} onClose={resetOnClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Link Your Xbox To Coplay</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {states[progress]}
                </ModalBody>

                <ModalFooter>
                    <Button onClick={resetOnClose} variant='ghost'>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};