import { Text } from "@chakra-ui/react"

export default function AuthStatus(props: { getStatus: string }) {
    let authStatus: JSX.Element
    if (props.getStatus) {
        authStatus = (
            <Text style = {{
                marginBottom: "15px",
                textAlign: "center",
                fontSize: "14px"
            }} color = {props.getStatus !== "Email sent!" && "red.400"}>
                {props.getStatus}
            </Text>
        )
    } else {
        authStatus = null
    }

    return authStatus
}