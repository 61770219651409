import { FriendingLinkAction, FriendingScreen } from "lib/coplay/components/actions/ActionLink";
import { ViewState, BaseAction, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "lib/coplay/components/actions/BaseAction";
import { BasicResponse, OauthActions } from "lib/coplay/types/BackendTypes";

const syncFriendDialog: BaseActionProps['DialogElem'] = (props: {
    handleClose: () => void,
    setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
    setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
}) => {
    return (
        <>
            <FriendingScreen handleClose={props.handleClose} setViewState={props.setViewState} setActionResponse={props.setActionResponse} action={OauthActions.SyncFriends} />
        </>
    )
}

const actionLink: BaseActionProps['ActionElem'] = (props: { onClickHandler: () => void }) => {
    return (
        <>
            <FriendingLinkAction onClickHandler={props.onClickHandler} action={OauthActions.SyncFriends} />
        </>
    )
}

export function SyncFriendsActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={actionLink}
                DialogElem={syncFriendDialog}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}