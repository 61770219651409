import { useState } from "react"
import { ModalHeader, ModalCloseButton, ModalBody, Button, Text, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { createBatchRequest, createFriendingRequest, createSyncProfileRequest, scheduleAction } from 'lib/coplay/backend/ApiRequests';
import React from 'react';
import { useSelectedUsers } from '../../DataProvider';
import { BaseActionProps, ViewState } from '../BaseAction';
import { ScheduledActionSelector, useActionController } from './ActionSelector';
import { ScheduledFrequencySelector } from './FrequencySelector';
import RepeatSelector from "lib/coplay/components/actions/scheduling/RepeatSelector"
import DaySelectors from "lib/coplay/components/actions/scheduling/DaySelectors"
import FrequencyDescription
    from "lib/coplay/components/actions/scheduling/FrequencyDescription"
import EndSelector from "lib/coplay/components/actions/scheduling/EndSelector"
import { FriendingRequest, BatchRequest, XMProfileRequest, ScheduleFrequency, ScheduleEvent, BasicResponse, XboxUser, OauthActions } from "lib/coplay/types/BackendTypes";
import { createScheduleId } from "lib/coplay/types/FrontendTypes";

function createScheduleEvent(
    req: FriendingRequest | BatchRequest | XMProfileRequest,
    frequency: ScheduleFrequency,
    start: Date,
    end: Date,
    name?: string
) {
    const hours = start.getHours()
    const minutes = start.getMinutes()

    if (frequency === ScheduleFrequency.OneTime) {
        end = start
    } else {
        end.setHours(hours, minutes + 1, 0, 0); // To make the last schedule execute
    }

    const scheduleEvent: ScheduleEvent = {
        name: name === '' ? undefined : name,
        scheduleId: createScheduleId(req.oauthAction, frequency, start, end, req.requestId),
        scheduledRequest: req,
        frequency: frequency,
        startDate: start.getTime(),
        endDate: end.getTime(),
        time: `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
    }

    return scheduleEvent;
}

// TODO: handleClose is unused
export const ScheduleActionDialog: BaseActionProps['DialogElem'] = (props: { handleClose: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>> }) => {

    const startMin = new Date()
    const timezoneOffset = startMin.getTimezoneOffset()
    startMin.setMinutes(startMin.getMinutes() - timezoneOffset, 0, 0)
    const [getStart, setStart] = useState<Date>(startMin)

    const endGuess = new Date(startMin)
    endGuess.setUTCMonth(startMin.getUTCMonth() + 3)
    const [getEnd, setEnd] = useState<Date>(endGuess)

    const [getRepeat, setRepeat] = useState<boolean>()
    const [frequency, setFrequency] = useState<ScheduleFrequency>(ScheduleFrequency.Daily);

    const selectedUsers = useSelectedUsers();
    const actionsController = useActionController();

    const [scheduleName, setScheduleName] = useState<string>('');

    const repeatFrequency: ScheduleFrequency
        = getRepeat ? frequency : ScheduleFrequency.OneTime

    const incrementedStart = (frequency: ScheduleFrequency, startDate: Date) => {
        const endMin = new Date(startDate)
        if (frequency === ScheduleFrequency.Daily) {
            endMin.setDate(startDate.getDate() + 1)
        } else if (frequency === ScheduleFrequency.Weekly) {
            endMin.setDate(startDate.getDate() + 7)
        } else {
            endMin.setUTCMonth(startDate.getUTCMonth() + 1)
        }
        return endMin
    }

    const frequencyStart
        = (frequency: ScheduleFrequency, startDate: Date = getStart) => {
            setFrequency(frequency);
            setStart(startDate)

            const endMin = incrementedStart(frequency, startDate)
            if (endMin > getEnd) {
                setEnd(endMin)
            }
        }

    const changeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = new Date(`${event.target.value}Z`);
        if (!isNaN(newDate.getTime())) {
            frequencyStart(frequency, newDate);
        }
    }

    const submitSchedule = () => {
        props.setViewState(ViewState.Loading);
        // console.log('[ScheduleActionModal] submitSchedule');
        // console.log('[ScheduleActionModal] action: ', actionsController.action);
        // console.log('[ScheduleActionModal] selectedUsers: ', selectedUsers);
        // console.log('[ScheduleActionModal] dateRange: ', dateController.dateRange);
        // console.log('[ScheduleActionModal] clockTime: ', timeController.clockTime);
        // console.log('[ScheduleActionModal] frequency: ', frequency);
        // console.log('[ScheduleActionModal] numOccurences: ', numOccurences);

        let scheduleRequest: Promise<BasicResponse> = undefined;
        let selectedEmailAddresses = selectedUsers.map((user: XboxUser) => { return user.emailAddress });

        getStart.setMinutes(getStart.getMinutes() + timezoneOffset, 0)
        getEnd.setMinutes(getEnd.getMinutes() + timezoneOffset)

        if (actionsController.action === OauthActions.ClearConversations) {

            scheduleRequest = new Promise((resolve) => {
                createBatchRequest(
                    actionsController.action as OauthActions.ClearConversations,
                    selectedEmailAddresses
                ).then((req) => {
                    const event = createScheduleEvent(req, repeatFrequency, getStart, getEnd, scheduleName || undefined);
                    scheduleAction(event).then((res) => {
                        resolve(res);
                    }).catch(e => props.setViewState(ViewState.Error));
                });
            });

        } else if (actionsController.action === OauthActions.RemoveFriends) {

            scheduleRequest = new Promise((resolve) => {
                createFriendingRequest(
                    selectedEmailAddresses,
                    ['all'],
                    OauthActions.RemoveFriends
                ).then((req) => {
                    const event = createScheduleEvent(req, repeatFrequency, getStart, getEnd, scheduleName || undefined);
                    scheduleAction(event).then((res) => {
                        resolve(res);
                    }).catch(e => props.setViewState(ViewState.Error));
                });
            });

        } else if (actionsController.action === OauthActions.SyncFriends) {

            scheduleRequest = new Promise((resolve) => {
                createFriendingRequest(
                    selectedEmailAddresses,
                    ['all'],
                    OauthActions.SyncFriends
                ).then((req) => {
                    const event = createScheduleEvent(req, repeatFrequency, getStart, getEnd, scheduleName || undefined);
                    scheduleAction(event).then((res) => {
                        resolve(res);
                    }).catch(e => props.setViewState(ViewState.Error));
                });
            });
        } else if (actionsController.action === OauthActions.XMProfile) {

            scheduleRequest = new Promise((resolve) => {
                createSyncProfileRequest(
                    selectedEmailAddresses,
                ).then((req) => {
                    const event = createScheduleEvent(req, repeatFrequency, getStart, getEnd, scheduleName || undefined);
                    scheduleAction(event).then((res) => {
                        resolve(res);
                    }).catch(e => props.setViewState(ViewState.Error));
                });
            });
        }

        if (scheduleRequest === undefined) {
            console.log('[ScheduleActionModal] Something went wrong. Schedule Request is undefined.');
            return;
        }

        scheduleRequest.then((res) => {
            console.log('[ScheduleActionModal] scheduleRequest res: ', res);
            props.setActionResponse(res as BasicResponse);
            props.setViewState(ViewState.Success);
        }).catch((err) => {
            console.log('[ScheduleActionModal] error: ', err);
            props.setViewState(ViewState.Error);
        });
    }

    return (
        <>
            <ModalHeader>Schedule</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <FormControl mb={4}>
                    <FormLabel>Schedule Name (optional)</FormLabel>
                    <Input
                        value={scheduleName}
                        onChange={(e) => setScheduleName(e.target.value)}
                        placeholder="Enter a name for this schedule"
                    />
                </FormControl>
                <label style={{ display: "flex", alignItems: "center" }}>
                    <ScheduledActionSelector controller={actionsController} />
                </label>
                <label style
                    ={{ padding: "25px 0", display: "flex", alignItems: "center" }}>
                    <Text style={{ width: "68px" }} fontWeight='bold'>
                        {getRepeat ? "Start" : "Time"}:
                    </Text>
                    <Input
                        style={{ maxWidth: "285px" }}
                        type={"datetime-local"}
                        value={getStart.toISOString().slice(0, 16)}
                        min={startMin.toISOString().slice(0, 16)}
                        onChange={changeStart}
                    />
                </label>
                <div style={{ height: "40px", display: "flex", fontWeight: "bold" }}>
                    <label style={
                        { paddingRight: "2px", display: "flex", alignItems: "center" }
                    }>
                        <RepeatSelector setRepeat={setRepeat} />
                        <p>
                            Repeat
                        </p>
                    </label>
                    <ScheduledFrequencySelector
                        frequency={repeatFrequency} frequencyStart={frequencyStart}
                    />
                </div>
                <DaySelectors
                    frequency={repeatFrequency}
                    getStart={getStart}
                    frequencyStart={frequencyStart}
                />
                <label style={{
                    paddingTop: "16px",
                    height: "56px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <p style={{ padding: "0 16px 0 68px" }}>
                        <FrequencyDescription
                            frequency={repeatFrequency} getStart={getStart}
                        />
                    </p>
                    <EndSelector
                        getRepeat={getRepeat}
                        endValue={getEnd.toISOString().slice(0, 10)}
                        endMin={incrementedStart(frequency, getStart).toISOString()}
                        setEnd={setEnd}
                    />
                </label>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        my='25px'
                        width='200px'
                        variant="brand"
                        disabled={!(getRepeat || getStart > startMin)}
                        onClick={submitSchedule}
                    >Confirm</Button>
                </div>
            </ModalBody>
        </>
    )
}