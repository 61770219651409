import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Spacer, Text } from '@chakra-ui/react';
import { useHasPayments } from 'lib/coplay/context/ConfigurationContext';
import { MdSubscriptions, MdShoppingCart } from "react-icons/md";
import { SidebarLink } from "../app_layout/AppSidebar";

export function PaymentsSidebarSection(props: { isDashboard: boolean }) {
    const hasPayments = useHasPayments();

    if (!hasPayments) {
        return null;
    }

    return (
        <Accordion m={0} p={0} allowMultiple defaultIndex={props.isDashboard ? [] : [0]}>
            <AccordionItem border='none' m={0} p={0}>
                <AccordionButton w={'100%'} m={0} p={0} alignItems={'center'}>
                    <Text mt={4} px={4} fontSize={'md'} fontWeight={'bold'} textAlign='left'>Subscriptions & Purchasing Manager</Text>
                    <Spacer />
                    <AccordionIcon mt={8} mr={4} />
                </AccordionButton>
                <AccordionPanel _hover={{ cursor: 'pointer' }}>
                    <SidebarLink label="Subscriptions" icon={MdSubscriptions} routeName={'/app/payments'} />
                    {/* <SidebarLink label="Purchases" icon={MdShoppingCart} routeName={'/app/payments'} /> */}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>

    )
}