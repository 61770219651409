import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Spinner, Button } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Card from 'lib/horizon/components/card/Card';
import { LogEntry, XboxUser } from 'lib/coplay/types/BackendTypes';
import { subscribeToUserLogs } from 'lib/coplay/backend/FirebaseFunctions';
import SpotlightLogItem from './SpotlightLogItem';

interface SpotlightUserLogsProps {
    user: XboxUser;
}

const SpotlightUserLogs: React.FC<SpotlightUserLogsProps> = ({ user }) => {
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        const unsubscribe = subscribeToUserLogs(user.emailAddress, (fetchedLogs) => {
            setLogs(fetchedLogs);
            setLoading(false);
        });
        return unsubscribe;
    }, [user.emailAddress]);

    return (
        <Card>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold">User Logs</Text>
                <Button
                    onClick={() => setIsExpanded(!isExpanded)}
                    size="sm"
                    rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                    {isExpanded ? 'Hide' : 'Show'}
                </Button>
            </Box>
            {isExpanded && (
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Spinner size="xl" />
                    </Box>
                ) : logs.length === 0 ? (
                    <Text>No logs available.</Text>
                ) : (
                    <VStack align="stretch" spacing={4} maxHeight="400px" overflowY="auto">
                        {logs.map((log, index) => (
                            <SpotlightLogItem key={index} logEntry={log} />
                        ))}
                    </VStack>
                )
            )}
        </Card>
    );
};

export default SpotlightUserLogs;