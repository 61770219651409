
export function createActivityGraphOptions() {
    return {
        chart: {
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: undefined,
            },
            onDatasetHover: {
                style: {
                    fontSize: '12px',
                    fontFamily: undefined,
                },
            },
            theme: 'dark',
        },
        xaxis: {
            categories: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
            show: true,
            labels: {
                show: true,
                style: {
                    colors: '#A3AED0',
                    fontSize: '14px',
                    fontWeight: '500'
                },
                rotate: 0,
                rotateAlways: false,
                hideOverlappingLabels: true,
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: true,
            color: 'black',
            labels: {
                show: true,
                style: {
                    colors: '#A3AED0',
                    fontSize: '14px',
                    fontWeight: '500'
                }
            }
        },
        grid: {
            show: false,
            column: {
                color: ['#7551FF', '#39B8FF'],
                opacity: 0.5,
            },
        },
        colors: [
            '#5E37FF', '#6AD2FF', '#E1E9F8', '#FF6B6B', '#FFD93D',
            '#6BCB77', '#4D96FF', '#FF9A8B', '#9B59B6', '#3498DB'
        ],
        dataLabels: {
            enabled: false,
        },
        legend: {
            labels: {
                colors: '#A3AED0',
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '20px',
                stacked: true, // Added this line to stack bars
            }
        }
    };
}