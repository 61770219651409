import { Input } from "@chakra-ui/react"
import InformationValue from "lib/coplay/components/views/account/InformationValue"

export default function ContactDetailValue(props: {
    getEditing: boolean,
    inputRef: React.MutableRefObject<HTMLInputElement>,
    detailValue: string
}) {
    let contactDetailValue: JSX.Element
    if (props.getEditing) {
        contactDetailValue = (
            <Input
                ref = {props.inputRef}
                style = {{ padding: 0, height: "22px" }}
                defaultValue = {props.detailValue}
            />
        )
    } else {
        contactDetailValue = (
            <InformationValue value = {props.detailValue} />
        )
    }
    
    return contactDetailValue
}