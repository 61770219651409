import { getConsolesForUser } from "../../../../../backend/ApiRequests";
import { Box, Button, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Loading } from "../../../../Loading";
import { createXMError } from "lib/coplay/utils";
import { XMError } from "lib/coplay/types/BackendTypes";

export const FindConsolesScreen = (props: { emailAddress: string, onFound: (str: string[]) => void, onError: (err: XMError) => void }) => {
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const onClick = () => {
        console.log("Clicked");
        setIsSearching(true);
        getConsolesForUser(props.emailAddress).then((xboxIds: string[]) => {
            setIsSearching(false);
            props.onFound(xboxIds);
        }).catch((error) => {
            setIsSearching(false);
            props.onError(createXMError(error));
        })
    }

    const searchingText = (
        <>
            <Text textAlign={"center"} style={{ paddingTop: "20px" }}>Searching For Xbox...</Text>
            <Loading />
        </>
    )
    const startSearchButton = (<Button mt="20px" w={'100%'} onClick={onClick} variant='action'>Start</Button>)

    return (
        <>
            <Box>
                <strong>Linking your Xbox to coplay is easy! Just follow the steps below</strong>
                <Text style={{ paddingTop: "20px" }}> Make sure you are logged in as <strong>{props.emailAddress}</strong> on the console you want to link </Text>
                {isSearching ? searchingText : startSearchButton}
            </Box>
        </>
    )
}