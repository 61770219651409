import Card from 'lib/horizon/components/card/Card';
import BarChart from 'lib/horizon/components/charts/BarChart';
import { Box, Flex, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { createActivityGraphOptions } from 'lib/coplay/components/views/usage/ActivityGraphUtils';
import { MonthlyReport, UsageMetrics } from 'lib/coplay/types/BackendTypes';

function formatMetricName(metricKey: string): string {
	return metricKey
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
		.replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
}

export default function ActivityGraph(props: {
	monthlyReport: MonthlyReport,
	month: string, // Added month prop
}) {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	let chartData = Object.keys(UsageMetrics).map(metricKey => {
		const metric = UsageMetrics[metricKey as keyof typeof UsageMetrics];
		return {
			name: formatMetricName(metricKey), // Format the metric name
			data: props.monthlyReport.days.map(day => day.metrics[metric] || 0)
		};
	});

	return (
		<Card alignItems='center' flexDirection='column' w='100%'>
			<Flex align='center' w='100%' px='15px' py='10px'>
				<Text me='auto' color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
					CoPlay XM Usage
				</Text>
				<Spacer />
				<Text me='auto' color={textColor} fontSize='24px' fontWeight='700' lineHeight='100%'>
					{props.month} {/* Replaced static "Month" label with dynamic month prop */}
				</Text>
			</Flex>

			<Box minH="60vh" h='100%' mt='auto' w='100%'>
				<BarChart chartData={chartData} chartOptions={createActivityGraphOptions()} />
			</Box>
		</Card>
	);
}