import { Text, Button, Flex, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { MdLayersClear } from "react-icons/md";
import { clearConversations } from "../../backend/ApiRequests";
import { useSelectedUsers } from "../DataProvider";
import { addXboxUsersObserver } from "../../backend/FirebaseFunctions";
import { ActionLinkElem, ACTION_LINK_TIMEOUT } from "./ActionLink";
import { BaseAction, ViewState, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "./BaseAction";
import { BasicResponse } from "lib/coplay/types/BackendTypes";

function ClearConversationsActionElem(props: { onClickHandler: () => void }) {
    let label = 'Clear Conversations';
    let icon = <Icon as={MdLayersClear} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}
export const ClearConversationsScreen: BaseActionProps['DialogElem'] =
    (props: {
        handleClose: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
    }) => {

        const selectedUsers = useSelectedUsers();

        const handler: () => void = () => {

            props.setViewState(ViewState.Loading);
            const timeout = setTimeout(() => {
                props.setViewState(ViewState.Warning);
            }, ACTION_LINK_TIMEOUT);

            addXboxUsersObserver(() => {
                props.handleClose();
                clearTimeout(timeout);
            })

            const selected: string[] = [];
            selectedUsers.forEach(user => selected.push(user.emailAddress))
            clearConversations(selected).catch((error) => {
                console.log("[clearConversations] Error", error)
                clearTimeout(timeout);
                props.setViewState(ViewState.Error);
            });
        }

        return (
            <>
                <ModalHeader>Clear Conversations</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                        <Text py='25px'>Are you sure you want to clear all conversations for {selectedUsers.size()} user(s)?</Text>
                        <Button width='200px' variant="brand" onClick={handler}>Confirm</Button>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </>
        )
    }

export function ClearConversationsActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={ClearConversationsActionElem}
                DialogElem={ClearConversationsScreen}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}