import { useState } from "react";
import { Flex, VStack, Text, IconButton, Spacer, Button } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import EditProfileButton from "lib/coplay/components/views/profiles/EditProfileButton";
import ProfileDetailsInfo from "lib/coplay/components/views/profiles/ProfileDetailsInfo";
import { XMProfile } from "lib/coplay/types/BackendTypes";

export function ProfileDetails(props: { profile?: XMProfile, onBack: () => void, onDelete: (profile: XMProfile) => void }) {
    const [getEditing, setEditing] = useState<boolean>()

    if (!props.profile)
        return (
            <VStack spacing={4} align="stretch">
                <IconButton
                    aria-label="Go back"
                    icon={<MdArrowBack />}
                    onClick={() => props.onBack()}
                    mb={4} />
                <Text>No profile selected</Text>
            </VStack>

        )

    return (
        <VStack spacing={4} align="stretch">
            <Flex justifyContent='left' w='100%'>
                <IconButton
                    aria-label="Go back"
                    icon={<MdArrowBack />}
                    onClick={() => props.onBack()}
                    alignSelf='left' />
                <Spacer />
                <Button
                    style={{ width: "87px" }}
                    colorScheme='red'
                    variant="ghost"
                    onClick={() => { props.onDelete(props.profile) }}
                >Delete</Button>
                <EditProfileButton
                    getEditing={getEditing}
                    setEditing={setEditing}
                />
            </Flex>
            <ProfileDetailsInfo
                editing={getEditing}
                profile={props.profile}
                onCreated={() => setEditing(false)}
            />
        </VStack >
    );
}