import { Text, Button, Flex, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { MdLayersClear } from "react-icons/md";
import { batchInstallSoftware } from "../../backend/ApiRequests";
import { useSelectedUsers } from "../DataProvider";
import { addXboxUsersObserver } from "../../backend/FirebaseFunctions";
import { ActionLinkElem, ACTION_LINK_TIMEOUT } from "./ActionLink";
import { BaseAction, ViewState, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "./BaseAction";
import { AdvancedGameSearch } from "../views/profiles/AdvancedGameSearch";
import { useState } from "react";
import { SoftwareItem } from "../views/profiles/SoftwareSearch";
import { XboxUsersSet } from "lib/coplay/types/FrontendTypes";
import { BasicResponse, SearchedSoftware } from "lib/coplay/types/BackendTypes";

function BatchInstallActionElem(props: { onClickHandler: () => void }) {
    let label = 'Install Software';
    let icon = <Icon as={MdLayersClear} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}


const allHaveXboxConsoles = (selectedUsers: XboxUsersSet) => {
    let allHaveConsoles: boolean = true

    for (const user of selectedUsers.getAll()) {
        if (!user.consoleId) {
            allHaveConsoles = false
            break
        }
    }

    return allHaveConsoles
};

export const BatchInstallScreen: BaseActionProps['DialogElem'] =
    (props: {
        handleClose: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
    }) => {

        const selectedUsers = useSelectedUsers();
        const allHaveConsoles = allHaveXboxConsoles(selectedUsers)
        const [selectedSoftware, setSelectedSoftware] = useState<SearchedSoftware | null>(null)

        const handler: () => void = () => {

            props.setViewState(ViewState.Loading);
            const timeout = setTimeout(() => {
                props.setViewState(ViewState.Warning);
            }, ACTION_LINK_TIMEOUT);

            addXboxUsersObserver(() => {
                props.handleClose();
                clearTimeout(timeout);
            })

            const selected: string[] = [];
            selectedUsers.forEach(user => selected.push(user.emailAddress))
            batchInstallSoftware(selected, [selectedSoftware]).catch((error) => {
                console.log("[BatchInstall] Error", error)
                clearTimeout(timeout);
                props.setViewState(ViewState.Error);
            });
        }

        return (
            <>
                <ModalHeader>Batch Install Software</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    {allHaveConsoles ?
                        <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                            {selectedSoftware != null &&
                                <SoftwareItem
                                    key={`${selectedSoftware.name}`}
                                    app={selectedSoftware}
                                    editing={true}
                                    remove={() => { setSelectedSoftware(null) }}
                                />}
                            <Text py='25px'>Search for the game you would like to install</Text>
                            <AdvancedGameSearch
                                editing={true}
                                onSelected={setSelectedSoftware}
                            />
                            <Button width='200px' variant="brand" disabled={selectedSoftware == null} onClick={handler}>Confirm</Button>
                        </Flex> :
                        <Text> Uh oh, not every selected user has a linked xbox!</Text>
                    }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </>
        )
    }

export function BatchInstallActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={BatchInstallActionElem}
                DialogElem={BatchInstallScreen}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}