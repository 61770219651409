
import { Avatar, Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { XBLCollatedConversations, XBLConversation } from 'lib/coplay/types/BackendTypes';
import { isLastMessageText } from 'lib/coplay/types/FrontendTypes';
import Card from 'lib/horizon/components/card/Card';
import { useState } from 'react';




export default function SpotlightConversations(props: { onClear: () => Promise<void>, conversations?: XBLCollatedConversations, [x: string]: any }) {
    const { conversations, ...rest } = props;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';

    let elem = <ConversationsZeroState />
    if (props.conversations !== undefined && props.conversations !== null) {
        elem = <MessageList conversations={props.conversations} />
    }

    const primaryMessagesExist = conversations?.primary?.conversations?.length > 0;
    const secondaryMessagesExist = conversations?.secondary?.conversations?.length > 0;
    const groupedMessagesExist = conversations?.grouped?.conversations?.length > 0;
    const isClearAllDisabled = !(primaryMessagesExist || secondaryMessagesExist || groupedMessagesExist);

    const onSubmit = async () => {
        try {
            setIsSubmitting(true);
            await props.onClear();
            setIsSubmitting(false);
        } catch (error) {
            console.log(error);
            // setIsSubmitting(false); 
        }
    }


    return (
        <Card mb={{ base: '0px', '2xl': '20px' }} maxH="500px" overflowY='scroll' {...rest}>
            <Flex justifyContent="space-between" alignItems="center">
                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
                    Conversations and Messages
                </Text>
                <Button
                    disabled={isClearAllDisabled || isSubmitting}
                    isLoading={isSubmitting}
                    colorScheme='blue'
                    onClick={onSubmit}
                >
                    Clear All
                </Button>
            </Flex>
            <Text color={textColorSecondary} fontSize='md' me='26px' mb='15px'>
                See the last message sent in each conversation the user is having.
            </Text>

            {elem}

        </Card>
    );
}

// Helper Components

function ConversationsZeroState() {
    return (
        <Card h='100%' w='100%'>
            <Flex align='center' h='100%' w='100%' justifyContent='center' alignContent={'center'} mb='30px'>
                <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                    It looks like you don't have any messages!
                </Text>
            </Flex>
        </Card>
    )
}

function MessageList(props: { conversations: XBLCollatedConversations }) {
    const { primaryMessages, secondaryMessages, groupedMessages } = parseConversations(props.conversations)
    const textColorSecondary = 'gray.400'

    const noMessagesElem = (
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='15px'>
            No Messages
        </Text>
    )
    return (
        <>
            <Flex key='primary'>
                <Text fontWeight='bold'>Primary Inbox</Text>
            </Flex>
            {primaryMessages.length > 0 ? primaryMessages : noMessagesElem}

            <Flex key='secondary' py="10px">
                <Text fontWeight='bold'>Secondary Inbox</Text>
            </Flex>
            {secondaryMessages.length > 0 ? secondaryMessages : noMessagesElem}

            <Flex key='secondary' py="10px">
                <Text fontWeight='bold'>Group Chats</Text>
            </Flex>
            {groupedMessages.length > 0 ? groupedMessages : noMessagesElem}
        </>
    )
}

function Message(props: {
    message: string;
    [x: string]: any;
}) {
    const { message, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const bg = useColorModeValue('white', 'navy.700');
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

    return (
        <Card bg={bg} boxShadow={cardShadow} mb='10px' {...rest} p='12px'>
            <Flex align='center'>
                <Avatar h='30px' w='30px' borderRadius='8px' me='20px' />
                <Box>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
                        {message}
                    </Text>

                </Box>
            </Flex>
        </Card>
    );
}



/* Helper Functions */
function parseConversations(conversations: XBLCollatedConversations) {

    const createConversationElement = (conversations: XBLConversation[]) => {
        const elems: JSX.Element[] = [];
        for (var convo of conversations) {
            const listKey = convo.conversationId;
            const msg = isLastMessageText(convo)
                ? convo.lastMessage.contentPayload.content?.parts[0].text as string
                : `(Message is not text)`

            elems.push(<Message
                key={listKey}
                message={msg}
            />)
        }

        return elems;
    }

    const primaryMessages: JSX.Element[] = createConversationElement(conversations.primary.conversations)
    const secondaryMessages: JSX.Element[] = createConversationElement(conversations.secondary.conversations)
    const groupedMessages: JSX.Element[] = createConversationElement(conversations.grouped.conversations)

    return { primaryMessages, secondaryMessages, groupedMessages }
}