import { ContactDetails } from "lib/coplay/types/FrontendTypes"
import { useContactDetails } from "lib/coplay/components/DataProvider"
import Card from "lib/horizon/components/card/Card"
import Information from "lib/horizon/views/admin/profile/components/Information"
import ContactDetailsView from "lib/coplay/components/views/account/ContactDetailsView"
import PasswordReset from "./PasswordReset"

export default function AccountView() {
    const contactDetails: ContactDetails = useContactDetails()

    return (
        <Card
            marginTop={{ base: "80px", xl: "80px" }}
            style={{ paddingRight: "20px" }}
        >
            <h3 style={{ margin: "20px", fontSize: "24px", fontWeight: "bold" }}>
                Company
            </h3>
            <Information title={"Name"} value={contactDetails.companyName} />
            <Information title={"Location"} value={contactDetails.companyLoc} />
            <Information title={"Joined Date"} value={contactDetails.joinedDate} />
            <hr style={{ margin: "20px" }} />
            <ContactDetailsView />
            <PasswordReset />
        </Card>
    )
}