import { Button, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Select, Spinner, Text } from "@chakra-ui/react";
import { useSelectedUsers } from "lib/coplay/components/DataProvider";
import { getXMProfiles } from "lib/coplay/backend/FirebaseFunctions";
import { ActionLinkElem, ACTION_LINK_TIMEOUT } from "lib/coplay/components/actions/ActionLink";
import { BaseAction, ViewState, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "lib/coplay/components/actions/BaseAction";
import { useEffect, useState } from "react";
import { RiProfileFill } from "react-icons/ri";
import { changeXMProfile } from "lib/coplay/backend/ApiRequests";
import { BasicResponse, XMProfile, PartialXMProfile } from "lib/coplay/types/BackendTypes";

const ActionLink: BaseActionProps['ActionElem'] = (props: { onClickHandler: () => void }) => {
    let label = 'Assign Profile';
    let icon = <Icon as={RiProfileFill} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}


enum ProfileState {
    Zero,
    Loading,
    Loaded,
}

const ProfilesDialog: BaseActionProps['DialogElem'] = (props: {
    handleClose: () => void,
    setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
    setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
}) => {

    const selectedUsers = useSelectedUsers();

    const [xmProfiles, setXMProfiles] = useState<XMProfile[]>([]);
    const [selectedProfile, setSelectedProfile] = useState('');
    const [profileState, setProfileState] = useState<ProfileState>(ProfileState.Loading);

    useEffect(() => {
        getXMProfiles().then((profiles) => {
            if (profiles.length === 0) setProfileState(ProfileState.Zero);
            else setProfileState(ProfileState.Loaded);
            setXMProfiles(profiles);
        }).catch(() => {
            // Handle error state, optionally you can add another state for error.
        });
    }, []);

    const handleProfileSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.target.value);
    };

    const handleAssignProfile = () => {
        // TODO: Implement assign profile logic
        props.setViewState(ViewState.Loading);
        const timeout = setTimeout(() => {
            props.setViewState(ViewState.Warning);
        }, ACTION_LINK_TIMEOUT);

        const selected: string[] = [];

        // get profile from id of selected
        const profile = xmProfiles.find(profile => profile.id === selectedProfile);
        const partial: PartialXMProfile = {
            id: profile?.id,
            name: profile?.name
        }

        selectedUsers.forEach(user => selected.push(user.emailAddress))
        changeXMProfile(selected, 'set', partial).then((response) => {
            props.handleClose();
            clearTimeout(timeout);
            props.setViewState(ViewState.Success);
            props.setActionResponse(response);
        }).catch((error) => {
            console.log("[ProfilesScreen] error", error)
            clearTimeout(timeout);
            props.setViewState(ViewState.Error);
        });
    };

    return (
        <>
            <ModalHeader>Choose Your Profile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {profileState === ProfileState.Zero && <Text>No Profiles Available</Text>}
                {profileState === ProfileState.Loading && <Spinner />}
                {profileState === ProfileState.Loaded && (
                    <Select value={selectedProfile} onChange={handleProfileSelect}>
                        <option value="">Select a profile</option>
                        {xmProfiles.map((profile: XMProfile) => (
                            <option key={profile.id} value={profile.id}>
                                {profile.name}
                            </option>
                        ))}
                    </Select>
                )}
            </ModalBody>
            <ModalFooter>
                <Button variant='action' onClick={handleAssignProfile} isDisabled={!selectedProfile}>Assign Profile</Button>
            </ModalFooter>
        </>
    );
}

export function SetProfileActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={ActionLink}
                DialogElem={ProfilesDialog}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}