import {
    Avatar,
    Box,
    Button,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    List,
    ListItem,
    Heading,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react";
import { useState } from "react";
import Card from "lib/horizon/components/card/Card"
import { SearchedSoftware } from "lib/coplay/types/BackendTypes";


const useSoftwareSearch = (props: { recomendedApps: SearchedSoftware[] }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredApps, setFilteredApps] = useState<SearchedSoftware[]>(props.recomendedApps);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm === "") {
            setFilteredApps(props.recomendedApps);
        } else {
            setFilteredApps(
                props.recomendedApps.filter((item) =>
                    item.name.toLowerCase().includes(newSearchTerm.toLowerCase())
                )
            );
        }
    };

    return {
        searchTerm,
        filteredApps,
        handleChange
    };
}

export function SoftwareSearch(props: {
    editing: boolean,
    onSelected: (app: SearchedSoftware) => void,
    recomended: SearchedSoftware[]
    placeholder: string
}) {

    const { searchTerm, filteredApps, handleChange } = useSoftwareSearch({ recomendedApps: props.recomended });

    const [isFocused, setIsFocused] = useState(false);

    let softwareSearch: JSX.Element
    if (props.editing) {
        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            console.log("blur")
            setIsFocused(false);
        };


        const items =
            filteredApps.map((app, index) =>
                <ListItem
                    key={`${index}-${app.name}`}
                    p={2}
                    _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
                    onMouseDown={() => props.onSelected(app)}
                >
                    <Flex alignItems={'center'}
                    >
                        <Avatar
                            name={app.name}
                            marginRight="2"
                            src={app.imageUri}
                            w='50px'
                            h='50px'
                            borderRadius='20px'
                            me='16px'
                        />
                        {app.name}
                    </Flex>
                </ListItem>
            )

        const renderAppList = isFocused && filteredApps.length > 0
            ? <List
                mt={2}
                borderWidth={1}
                borderRadius="md"
                zIndex={1}
                position="absolute"
                width="100%"
                bg="white"
                maxHeight={'300px'}
                overflowY='scroll'
            >
                {items}
            </List>
            : <> </>

        softwareSearch = (
            <>
                <InputGroup>
                    <Input
                        placeholder={props.placeholder}
                        value={searchTerm}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <InputRightElement children="🔍" />
                </InputGroup>
                {renderAppList}
            </>
        );
    } else {
        softwareSearch = null
    }

    return softwareSearch
}

export function SoftwareContainer(props: {
    apps: SearchedSoftware[],
    editing: boolean,
    remove: (app: SearchedSoftware) => void,
    softwareCategory: string
}) {

    if (props.apps.length === 0) {
        return (
            <Card border={'1px'} w='100%' h={'185px'}>
                <Flex
                    h='100%'
                    style={{ flexDirection: "column" }}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Heading size='md'>
                        {`No ${props.softwareCategory}s selected`}
                    </Heading>
                    <p style={{ height: "24px" }}>
                        <SearchSuggestion
                            editing={props.editing}
                            softwareCategory={props.softwareCategory}
                        />
                    </p>
                </Flex>
            </Card>
        )
    }

    let appItems = props.apps.map((app, index) =>
        <SoftwareItem
            key={`${app.name}-${index}`}
            app={app}
            editing={props.editing}
            remove={props.remove}
        />
    )
    // Hide the scrollbar
    /*
        Hide scrollbar for Chrome, Safari and Opera
            .example::-webkit - scrollbar {
            display: none;
        }

        Hide scrollbar for IE, Edge and Firefox 
        .example {
            -ms-overflow - style: none;  // IE and Edge
            scrollbar-width: none;  // Firefox
        }

*/



    return (
        <Box border={'1px'} style={{ padding: "8px 0", }} w='100%' overflowX='scroll'>
            <HStack px='10px' spacing="20px" w='100%'>
                {appItems}
            </HStack>
        </Box>
    )
}

const SearchSuggestion = (props: { editing: boolean, softwareCategory: string }) => {
    let searchSuggestion: string
    if (props.editing) {
        searchSuggestion = `Search for ${props.softwareCategory}s below`
    }

    return (
        <>
            {searchSuggestion}
        </>
    )
}

export function SoftwareItem(props: {
    app: SearchedSoftware, editing: boolean, remove: (app: SearchedSoftware) => void
}) {

    return (
        <Box
            position="relative"
            borderRadius='md'
            w='100px'
            minW="75px"
            overflow='hidden'
            border={'1px'}
        >
            <VStack p='5px 5px 4px'>
                <Avatar
                    m='0px'
                    p='0px'
                    name={props.app.name}
                    src={props.app.imageUri}
                    w='70px'
                    h='70px'
                    borderRadius='md'
                />
                <Box overflowY='hidden'>
                    <Text
                        fontSize={['xs', 'sm']} // Making it responsive
                        fontWeight='bold'
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxW="90px" // Max Width
                    >
                        {props.app.name}
                    </Text>
                </Box>
                <Spacer />

                <Button
                    isDisabled={!props.editing}
                    py='10px'
                    variant='action'
                    size='xs'
                    onClick={() => props.remove(props.app)}
                >Remove</Button>
            </VStack>
        </Box>
    );
}