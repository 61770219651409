import {
    Select,
    useColorModeValue,
} from '@chakra-ui/react';

const MonthOptions = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
    'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
};

export function MonthMenu(props: { selectedMonth: string, setMonth: (month: string) => void }) {
    const bgList = useColorModeValue('white', 'whiteAlpha.100');
    const textColor = useColorModeValue('secondaryGray.900', 'white');


    return (
        <Select
            placeholder="Select month"
            value={props.selectedMonth}
            onChange={(e) => props.setMonth(e.target.value)}
            bg={bgList}
            color={textColor}
            borderRadius='10px'
            minHeight='40px'
            h='45px'
        >
            {Object.keys(MonthOptions).map(month => (
                <option key={month} value={month}>
                    {month}
                </option>
            ))}
        </Select>
    );
}

export default MonthMenu;
export { MonthOptions };