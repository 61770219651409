import { Checkbox, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text, useColorModeValue, Wrap, WrapItem } from "@chakra-ui/react";
import PrivacySwitch from "lib/coplay/components/views/profiles/PrivacySwitch";
import { XboxAccountSettingsController } from "lib/coplay/components/views/profiles/ProfileDetailsInfo";
import { FriendingAction, UserPrivacySettings } from "lib/coplay/types/BackendTypes";
import { enrichSettings, SettingsHumanReadableToValue, PrioritySettings } from "lib/coplay/types/SettingsUtils";

export function UserSettings(props: {
    editing: boolean, controller: XboxAccountSettingsController
}) {
    return (
        <>
            <FormControl>
                <Text py='10px' fontSize={"xl"} fontWeight="black"> Account Settings </Text>

                <FormLabel>Friending options</FormLabel>

                <RadioGroup
                    isDisabled={!props.editing}
                    defaultValue={props.controller.friending}
                    onChange={(value) => props.controller.setFriending(value as FriendingAction)}
                >
                    <Stack>
                        <Radio value={FriendingAction.RemoveAll}>Remove All Friends</Radio>
                        <Radio value={FriendingAction.SyncAll}>Sync Friends to CoPlay XM</Radio>
                        {/* <Radio value={FriendingAction.AddAll}>Sync Friends to other users with same profile</Radio> */}
                    </Stack>
                </RadioGroup>

            </FormControl>

            <FormControl>
                <FormLabel>Conversations And Messages</FormLabel>
                <Checkbox
                    isDisabled={!props.editing}
                    onChange={() => props.controller.setClearConversations(!props.controller.clearConversations)}
                    defaultChecked={props.controller.clearConversations}
                    checked={props.controller.clearConversations}
                >
                    Clear all conversations and messages
                </Checkbox>
            </FormControl>

            <FormControl>
                <FormLabel>Privacy Settings</FormLabel>
                <PrivacySwitch
                    editing={props.editing} controller={props.controller}
                />
                <PrivacySettings
                    editing={props.editing} controller={props.controller}
                />
            </FormControl>

            <Flex w='100%' direction={'row'} justifyContent='left' alignItems='center'>
                <Text pe='10px' py='10px' fontSize={"xl"} justifyContent='left' fontWeight="black"> Xbox Console Settings </Text>
            </Flex>
        </>
    )
}

function PrivacySettings(
    props: { editing: boolean, controller: XboxAccountSettingsController }
) {
    const enrichedSettings = enrichSettings(props.controller.privacySettings);

    const textColorPrimary = useColorModeValue('brand.500', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.900', 'white');
    // const textColorSecondary = useColorModeValue('gray.700', 'gray.300');

    let standardSettingsElem: JSX.Element[] = []
    let prioritySettingsElems: JSX.Element[] = []
    if (props.controller.getEnablePrivacy) {
        enrichedSettings.forEach((setting, index) => {
            const settingName = setting.setting;
            const currentValue = setting.value;
            const handleSettingChange = (newSettingValue: string) => {
                const newSettings: UserPrivacySettings = {
                    settings: [...props.controller.privacySettings.settings]
                };

                for (const s in newSettings.settings) {
                    if (newSettings.settings[s].setting === settingName) {
                        newSettings.settings[s]
                            = { setting: settingName, value: newSettingValue }
                    }
                }

                props.controller.setPrivacySettings(newSettings)
            }



            const settingElem = (
                <WrapItem w='420px' h='160px' p='10px' key={index} borderWidth="1px" borderColor="gray.200" borderRadius="md">
                    <div>
                        <Text w='300px' color={textColorPrimary} fontWeight={"bold"}>
                            {setting.description}
                        </Text>
                        <Text w='300px' color={textColorSecondary}>
                            {setting.details}
                        </Text>
                    </div>

                    <RadioGroup
                        isDisabled={!props.editing}
                        value={currentValue}
                        onChange={e => handleSettingChange(e as string)}
                    >
                        <Stack direction="column">
                            {setting.options.map((option, i) => (
                                <Radio key={i} value={SettingsHumanReadableToValue[option]}>
                                    {option}
                                </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>
                </WrapItem>
            );

            if (PrioritySettings.includes(settingName)) {
                prioritySettingsElems.push(settingElem)
            } else {
                standardSettingsElem.push(settingElem)
            }
        })
    }

    let renderStandardSettings = () => {
        if (standardSettingsElem.length > 0) {
            return (
                <details color={textColorPrimary} >
                    <summary style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }} >Advanced Privacy Settings</summary>
                    <Wrap w='100%' p='10px'>
                        {standardSettingsElem}

                    </Wrap>
                </details>
            )
        }
    }
    return (
        <>

            <Wrap w='100%' p='10px'>
                {prioritySettingsElems}
            </Wrap>
            {renderStandardSettings()}
        </>
    )
}
