import { Text } from "@chakra-ui/react"
import ColorModeParagraph from
	"lib/coplay/components/views/dashboard/dashboard_list/spotlight/ColorModeParagraph"

export default function SpotlightPrivileges(props: {
	getPrivileges: { [key: string]: string }[]
}) {
	let spotlightPrivileges: React.ReactNode
	if (props.getPrivileges) {
		spotlightPrivileges
			= props.getPrivileges.map((privilege: { [key: string]: string }) => {
			return (
				<div key = {privilege.description}>
					<ColorModeParagraph
						lightMode = {"gray.700"} darkMode = {"gray.300"}
					>
						{privilege.description}
					</ColorModeParagraph>
					<Text color = {"gray.400"}>
						{privilege.value}
					</Text>
				</div>
			)
		})
	} else {
		spotlightPrivileges = (
			<p style = {{
				marginTop: "20px",
				textAlign: "center",
				fontSize: "24px",
				fontWeight: "bold"
			}}>
				Loading...
			</p>
		)
	}

	return (
		<>
			{spotlightPrivileges}
		</>
	)
}