import { Flex, Spinner, Text } from "@chakra-ui/react";

export function PaymentsLoadingState() {
    return (
        <Flex id="paymentsLoadingState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"} h='100%'>
            <Text textAlign={"center"} fontWeight="black" fontSize='3xl'>
                Loading Payments And Subscriptions...
            </Text>
            <Spinner size="xl" />
        </Flex>
    )
}
