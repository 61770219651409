import React, { useState } from "react";
import { VStack, Heading, Avatar, Divider, Box, Text, Button } from "@chakra-ui/react";
import { VendorMember, SupportedVendors } from "lib/coplay/types/PaymentTypes";
import DisneyPlus from '../../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../../assets/xbox_icon.png';
import { RemoteBrowserCanvas } from "lib/coplay/components/remote_browser/RemoteBrowserCanvas";
import { ManageDisneyStrategy, ManageXboxStrategy } from "lib/coplay/components/remote_browser/strategies";
import { FundingRenderer } from "../MemberListRenderer";

interface MemberDetailsProps {
    member: VendorMember;
}

const MemberDetails: React.FC<MemberDetailsProps> = ({ member }) => {
    const [isManageOpen, setIsManageOpen] = useState(false);

    const handleManageClick = () => {
        setIsManageOpen(true);
    };

    const handleManageClose = () => {
        setIsManageOpen(false);
    };

    const strategy = member.vendor === SupportedVendors.Disney ? new ManageDisneyStrategy(member) : new ManageXboxStrategy(member);

    return (
        <>
            {isManageOpen ? (
                <Box>
                    <RemoteBrowserCanvas
                        strategy={strategy}
                        onFinish={handleManageClose}
                    />
                    <Button colorScheme="blue" my={4} width="100%" onClick={handleManageClose}>
                        <Text>Close</Text>
                    </Button>
                </Box>

            ) : (
                <VStack width={'380px'}>
                    <Heading as='h3' size='lg'>{member.vendor}</Heading>
                    <Avatar
                        src={member.vendor === SupportedVendors.Disney ? DisneyPlus : XboxIcon}
                        size="xl"
                        mr={2}
                    />
                    <Divider />

                    <Heading as='h4' size='md'>{member.email}</Heading>

                    <Text>Status: <strong>Ready</strong></Text>

                    <Box w='85%'>
                        <Text fontWeight="bold">Card Note</Text>
                        <Text>{member.notes || 'Add a note...'}</Text>
                    </Box>
                    <Box w='85%'>
                        <Text fontWeight="bold">Aggregated</Text>
                        <Text>{new Date(member.createdAt).toLocaleString()}</Text>
                        <Text fontWeight="bold">Updated on</Text>
                        <Text>{new Date(member.updatedAt).toLocaleString()}</Text>
                    </Box>
                    <Box w='85%' py={2}>
                        <Text pb={2} fontWeight="bold">Funding Source: </Text>
                        <FundingRenderer member={member} />
                    </Box>


                </VStack>
            )}
        </>
    );
};

export default MemberDetails;
