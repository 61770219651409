// Chakra imports
import { Flex, HStack, Spacer, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { XboxUser } from 'lib/coplay/types/BackendTypes';
import Card from 'lib/horizon/components/card/Card';
import { UserAvatar } from '../../UserAvatar';

export default function SpotlightUserCard(props: {
    user?: XboxUser;
    [x: string]: any;
}) {
    const { user, ...rest } = props;


    // Nullish Coalescing
    const profileImgUrl: string = user?.profilePicUrl || '';
    const emailAddress: string = user?.emailAddress || 'Empty';
    const gamertag: string = user?.gamerTag || 'Empty';
    const presenceState: string = user?.presenceState || 'Empty';
    const presenceText: string = user?.presenceText || 'Empty';
    const numConversations: number | string = user?.numConversations || 0;
    const friends: number | string = user?.numFriends || 0;
    const lastUpdated: string = user?.updatedAt.toLocaleString().split(',')[0] || 'Empty';


    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const borderColor = useColorModeValue('white !important', '#111C44 !important');

    return (
        <Card mb={{ base: '0px', lg: '20px' }} alignItems='center' {...rest}>

            <VStack style={{ width: "100%" }}>
                <UserAvatar user={user} mx='auto' src={profileImgUrl} h='150px' w='150px' border='4px solid' borderColor={borderColor} />
                <Text
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='xl'
                    mt='10px'
                    style={{ overflowWrap: "anywhere" }}
                >
                    {emailAddress}
                </Text>
                <Text color={textColorSecondary} fontSize='sm'>
                    {gamertag}
                </Text>
                <Text fontWeight='bold' fontSize='sm' color="green" >
                    {presenceState} : {presenceText}<br />
                </Text>

                {/* <RenderSchedules scheduleIds={user?.schedules} emailAddress={emailAddress} /> */}
                <Spacer />

                <HStack mx='auto' pt='10px' justifyContent={'center'} alignItems='center'>

                    <Flex px='15px' alignItems='center' flexDirection='column'>
                        <Text color={textColorPrimary} fontSize='xl' fontWeight='700'>
                            {friends}
                        </Text>
                        <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                            Friends
                        </Text>
                    </Flex>

                    <Flex px='15px' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Text color={textColorPrimary} fontSize='xl' fontWeight='700'>
                            {numConversations}
                        </Text>
                        <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                            Conversations
                        </Text>
                    </Flex>

                    <Flex px='15px' alignItems='center' flexDirection='column'>
                        <Text color={textColorPrimary} fontSize='xl' fontWeight='700'>
                            {lastUpdated}
                        </Text>
                        <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                            Last Updated
                        </Text>
                    </Flex>

                </HStack>
            </VStack>

        </Card>
    );
}