import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Friends } from 'lib/coplay/types/BackendTypes';
import Card from 'lib/horizon/components/card/Card';


function FriendsZeroState() {
    return (
        <Card h='100%' w='100%'>
            <Flex align='center' h='100%' w='100%' justifyContent='center' alignContent={'center'} mb='30px'>
                <Text fontWeight='bold' fontSize='2xl' mb='4px'>
                    Hmmm... It looks like you don't have any friends yet. Try adding some!
                </Text>
            </Flex>
        </Card>
    )
}

function FriendsList(props: { friends: Friends }) {
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

    const friendElems = props.friends.people.map((friend) => {
        const listKey = 'friendsList-' + friend.gamertag

        return <FriendListItem
            gamertag={friend.gamertag}
            boxShadow={cardShadow}
            key={listKey}
        />
    })

    return (
        <>
            {friendElems.length > 0 ? friendElems : <FriendsZeroState />}
        </>
    )
}

export default function SpotlightFriends(props: { friends?: Friends, [x: string]: any }) {
    const { ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';

    let elem = <FriendsZeroState />
    if (props.friends !== undefined && props.friends !== null) {
        elem = <FriendsList friends={props.friends} />
    }

    return (
        <Card mb={{ base: '0px', '2xl': '20px' }} maxH="500px" overflowY='scroll' {...rest}>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
                Friends
            </Text>
            <Text color={textColorSecondary} fontSize='md' me='26px' mb='15px'>
                These are all the friends you have added to your friends list.
            </Text>
            {elem}
        </Card>
    );
}

function FriendListItem(props: {
    gamertag: string;
    [x: string]: any;
}) {
    const { gamertag, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const bg = useColorModeValue('white', 'navy.700');


    return (
        <Card bg={bg} mb='10px' {...rest} p='12px'>
            <Flex align='center'>
                <Avatar h='30px' w='30px' name={gamertag} borderRadius='8px' me='20px' />
                <Box>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
                        {gamertag}
                    </Text>

                </Box>
            </Flex>
        </Card>
    );
}
