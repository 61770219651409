import { Flex, Divider, Spacer, Text, Button, useColorModeValue, Box } from "@chakra-ui/react";
import Card from "lib/horizon/components/card/Card";
import ProfileListItem from "lib/coplay/components/views/profiles/ProfileListItem";
import { XMProfile } from "lib/coplay/types/BackendTypes";


function createList(
    profiles: XMProfile[],
    onDetails: (profile: XMProfile) => void,
    onDelete: (profile: XMProfile) => void,
    onDuplicate: (profile: XMProfile) => void
) {
    return profiles.map((profile) => {
        const handleDelete = (event: React.MouseEvent) => {
            event.stopPropagation()
            onDelete(profile)
        }

        const handleDuplicate = (event: React.MouseEvent) => {
            event.stopPropagation()
            onDuplicate(profile)
        }

        return (
            <ProfileListItem
                key={profile.id}
                name={profile.name}
                date={new Date(profile.dateCreated).toLocaleDateString()}
                onClick={() => onDetails(profile)}
                onDelete={handleDelete}
                onDuplicate={handleDuplicate}
            />
        )
    })
}



export function ProfilesList(props: {
    profiles: XMProfile[],
    onCreate: () => void,
    onDetails: (profile: XMProfile) => void,
    onDelete: (profile: XMProfile) => void,
    onDuplicate: (profile: XMProfile) => void
}) {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const listItems = createList(props.profiles, props.onDetails, props.onDelete, props.onDuplicate)

    if (props.profiles.length === 0)
        return <ProfileZeroState onCreate={props.onCreate} />


    return (
        <Flex flexDirection='column' gridArea={{ xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }} h="100%">

            <Card h="100%" p='0px' >
                <Flex
                    align={{ sm: 'flex-start', lg: 'center' }}
                    justify='space-between'
                    w='100%'
                    px='22px'
                    py='18px'>
                    <Box>
                        <Text color={textColor} fontSize='xl' fontWeight='600'>
                            Your User Profiles
                        </Text>
                        <Text textAlign={"center"} fontSize='md'>
                            Profiles allow you to reset your user and console settings back to custom settings that you define.
                        </Text>
                    </Box>

                    <Spacer />
                    <Button colorScheme="blue" size="sm" onClick={() => { props.onCreate() }}> Create New Profile </Button>

                </Flex>

                <Divider colorScheme="telegram" m="10px" />

                {listItems}

                <Spacer />
                <Divider m="10px" />
            </Card>

        </Flex>

    )
}


function ProfileZeroState(props: { onCreate: () => void }) {

    return (
        <Flex id="profileZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"} h='100%'>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    Hmmm... Doesn't look like you have any profiles created yet. Let's fix that!
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    Profiles allow you to reset your user and console settings back to custom settings that you define.
                </Text>
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontSize='md'>
                    Click below to get started!
                </Text>
                <Button mt="20px" w={'100%'} onClick={() => { props.onCreate() }} variant='action'>Create A Profile</Button>
            </Box>
        </Flex>
    )

}