import ProfileButton
    from "lib/coplay/components/views/dashboard/dashboard_list/ProfileButton"
import { PartialXMProfile } from "lib/coplay/types/BackendTypes"

export default function ProfileLine(props: { profile: PartialXMProfile }) {
    let profileLine: JSX.Element
    if (props.profile) {
        profileLine = (
            <div style={{ height: "21px", display: "flex", alignItems: "center" }}>
                <ProfileButton profile={props.profile} />
            </div>
        )
    } else {
        profileLine = null
    }

    return profileLine
}