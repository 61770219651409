import { useHistory } from "react-router-dom";
import { Logout } from "../../backend/FirebaseFunctions";


function SignOut() {
    let history = useHistory();
    Logout().then(() => {
        console.log("Sucessfully Signed out.");
        history.push('/sign-in');
    }).catch((error) => {
        console.log("Error Signing uut: ", error);
        history.push('/sign-in');
    })

    return (
        <>
            Signing Out...
        </>
    );
}

export default SignOut;