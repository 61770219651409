import { useColorModeValue, Heading, Link } from "@chakra-ui/react"

export default function TermsModalBody() {
    const colorModeValue = useColorModeValue("brand.500", "white")

    return (
        <>
            <Heading size = {"lg"} color = {colorModeValue}>
                End User Service Agreement
            </Heading>
            <Heading size = {"md"} color = {colorModeValue}>
                Effective Date: December 1, 2022
            </Heading>
            <p>
                CoPlay provides a platform (“<b>Platform</b>”) that allows you to
                connect your gaming accounts and consoles (“<b>Accounts</b>”) to help
                you do things like manage your friend networks, console settings and
                clear messages and data.
            </p>
            <p>
                This Terms and Services Agreement (“<b>Agreement</b>”) is an agreement
                between you and CoPlay LLC. (“<b>CoPlay</b>”, “<b>we</b>” or
                “<b>us</b>”). By accepting this Agreement or accessing coplay.io or its
                associated web applications (xm.coplay.io etc), you agree to this
                Agreement and certify that you have all necessary rights to do so. If
                you are accepting on behalf of your employer or another entity, you
                represent and warrant that: (i) you have full legal authority to bind
                your employer or such entity to this Agreement; (ii) you have read and
                understand this Agreement; and (iii) you agree to this Agreement on
                behalf of the party that you represent. If you do not have the legal
                authority to bind your employer or the applicable entity, please do not
                accept this Agreement or access the features covered by this Agreement.
            </p>
            <Heading size = {"sm"} color = {colorModeValue}>
                coplay.io, xm.coplay.io etc
            </Heading>
            <p>
                You can use xm.coplay.io to manage your Gaming Accounts and consoles.
                xm.coplay.io is designed to empower you with greater control over your
                gaming account and console data. Your use of any gaming account or
                console on the platform also is governed by separate terms between you
                and the applicable gaming provider (Microsoft, Playstation, Nintendo,
                etc). CoPlay is not responsible for any Apps or Accounts provided by
                third parties or the acts or omissions of any third-party providers, and
                does not guarantee that any Apps or Accounts will remain available or
                compatible with the Platform.
            </p>
            <h2>
                <b>CoPlay Account Creation</b>.
            </h2>
            <p>
                You need to create an account with CoPlay in order to use the Platform.
                You also need to ensure that your information is accurate, complete and
                up-to-date. You must notify us if you learn of any unauthorized access
                to or use of your CoPlay account.
            </p>
            <h2>
                <b>Control and Responsibilities</b>.
            </h2>
            <p>
                You represent and warrant that you have all necessary rights to use your
                gaming accounts and consoles with the Platform, and you agree to comply
                with all laws and regulations applicable to your use, as well as any
                rules and guidelines that we post. You must not (1) use or access anyone
                else’s Accounts or related data, (2) submit information about anyone
                else’s identity or Accounts or that violates any third-party rights or
                (3) use the Platform for any fraudulent, illegal or misleading purpose.
                You also agree not to (a) modify, reverse engineer or seek to gain
                unauthorized access to the Platform or related systems, data or source
                code, (b) bypass or circumvent measures designed to prevent or limit
                access to any part of the Platform, (c) rent, lease, provide access to
                or sublicense any elements of the Platform to a third party or use the
                Platform on behalf of or to provide services to third parties, (d) copy,
                modify or create derivative works of the Platform or remove any of
                Plaid’s proprietary notices, (e) access the Platform for competitive
                purposes or publish any benchmark or performance information about the
                Platform, or (f) use the Platform in any manner that could damage,
                disable, overburden, or impair the functioning of the Platform or
                interfere with, disrupt or negatively affect other users.
            </p>
            <h2>
                <b>How CoPlay Uses Your Data</b>.
            </h2>
            <p>
                Please review our Privacy Policy.
            </p>
            <h2>
                <b>Rights to the Platform</b>.
            </h2>
            <p>
                Note that CoPlay owns all rights, titles and interest (including
                intellectual property rights) in and to the Platform and our related
                websites and technology. If you choose to give us feedback, suggestions
                or other inputs about the Platform, we may use them without restriction.
            </p>
            <h2>
                <b>Our Disclaimers</b>.
            </h2>
            <p>
                TO THE EXTENT PERMITTED BY LAW, THE PLATFORM (INCLUDING xm.COPLAY.IO AND
                COPLAY PROFILES) IS PROVIDED “AS IS” AND “AS AVAILABLE,” AND ANY USE IS
                AT YOUR DISCRETION AND RISK. COPLAY MAKES NO WARRANTIES, EXPRESS OR
                IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF
                MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE OR
                NONINFRINGEMENT. COPLAY DOES NOT WARRANT THAT USE WILL BE UNINTERRUPTED
                OR ERROR-FREE, THAT ANY OF YOUR DATA WILL BE ACCURATE OR COMPLETE OR
                THAT COPLAY WILL MAINTAIN ANY DATA WITHOUT LOSS.
            </p>
            <p>
                <i>Liabilities for our Platform.</i> TO THE EXTENT PERMITTED BY LAW,
                COPLAY WILL NOT BE RESPONSIBLE FOR: (A) ANY LOST PROFITS, LOSS OF ACCESS
                OR USE OF GAMING ACCOUNTS OR CONSOLES, LOST OR INACCURATE DATA, FAILURE
                OF SECURITY MECHANISMS, FINANCIAL LOSSES, OR ANY INDIRECT, SPECIAL,
                INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND OR (B) ANY
                DAMAGES OR AMOUNTS EXCEEDING, IN THE AGGREGATE, THE GREATER OF (1) THE
                AMOUNT YOU PAID US TO USE THE PLATFORM AND (2) ONE HUNDRED U.S. DOLLARS
                (US $100).
            </p>
            <h2 style = {{ fontWeight: "bold" }}>
                Dispute Resolution.
            </h2>
            <p style = {{ fontWeight: "bold" }}>
                We hope you will have a positive experience using our Platform, but
                should a dispute between us arise out of or relating to these Terms, we
                agree to resolve the dispute by following these steps:
            </p>
            <ol style = {{ marginLeft: "48px" }}>
                <li>
                    <b>Send us a notice, according to the Notices section below,
                    describing the dispute and including all relevant facts so we know
                    how to help you.</b>
                </li>
                <li>
                    <b>Within 5 business days after our receipt of your notice, we will
                    reach out to discuss your dispute with you.</b>
                </li>
                <li>
                    <b>If we’re not able to resolve your dispute during our discussion,
                    you will send us a written proposal for resolving your dispute.</b>
                </li>
                <li>
                    <b>Within 15 business days after our receipt of your written
                    proposal, we will let you know whether we agree to your proposal, or
                    we will provide you with a counter-proposal.</b>
                </li>
            </ol>
            <p style = {{ fontWeight: "bold" }}>
                After Step 4, it’s up to you to decide whether you’d like to continue to
                negotiate with us to resolve your dispute, or whether you’d like to
                pursue a resolution through some other means.
            </p>
            <p style = {{ fontWeight: "bold" }}>
                Throughout this process, both you and CoPlay agree to negotiate in good
                faith and according to the terms of this section to resolve the dispute
                before resorting to litigation or some other form of dispute resolution
                procedure. All negotiations (including your notice, our discussions, and
                your and our proposals) pursuant to this section are confidential and
                treated as compromise and settlement negotiations for the purposes of
                federal and state rules of evidence and procedure.
            </p>
            <h2>
                <b>Notices</b>.
            </h2>
            <p>
                CoPlay may provide notices or communications to you through the email
                associated with your CoPlay account, through xm.coplay.io or through
                other reasonable methods. All notices, requests and other communications
                to CoPlay under this Agreement must be in writing to CoPlay LLC.,
                Attention: Legal 338 E 4115 S #221 Salt Lake City, UT (with a courtesy
                copy to <Link
                    color = {colorModeValue}
                    href = {"mailto:legal@coplay.io"}
                >
                    legal@coplay.io
                </Link> ) and will be deemed given when delivered.
            </p>
            <h2>
                <b>Ending This Agreement</b>.
            </h2>
            <p>
                At any time in its discretion, CoPlay may terminate or suspend this
                Agreement (or your use of the Platform) with or without notice and for
                any or no reason, including if CoPlay suspects that you have violated
                this Agreement. CoPlay will have no liability to you for any termination
                or suspension, nor will such action limit any other rights or remedies
                CoPlay may have. Except for your right to use the Platform, this
                Agreement will survive any termination.
            </p>
            <h2>
                <b>About This Agreement</b>.
            </h2>
            <p>
                This Agreement may not be transferred or assigned by you without
                CoPlay’s prior written consent. CoPlay may assign or transfer this
                Agreement to its affiliates or in connection with a merger, sale,
                reorganization or other change of control. In addition, CoPlay’s
                affiliates, contractors and service providers may exercise CoPlay’s
                rights or fulfill its obligations under this Agreement. Waivers must be
                in writing and no waivers will be implied. If any provision of this
                Agreement is held by a court of competent jurisdiction to be
                unenforceable for any reason, the remaining provisions will remain
                unaffected and in full force and effect. This Agreement is the final,
                complete and exclusive agreement between you and us relating the subject
                matter of this Agreement and supersedes all prior or contemporaneous
                understandings and agreements relating to such subject matter, whether
                oral or written. In this Agreement, headings are for convenience only
                and the term “including” (and similar terms) will be construed without
                limitation.
            </p>
            <p>
                CoPlay may modify this Agreement from time to time. Unless we specify
                otherwise, modifications take effect (and govern future use of the
                Platform, including coplay.io and your CoPlay Account) when we post the
                modified version. CoPlay will use reasonable efforts to notify you of
                the modifications, and you may be required to agree to the modified
                version. If you do not agree to the modifications, your sole remedy is
                to cease using the Platform.
            </p>
        </>
    )
}