export default function AuthInstructions() {
    let authInstructions: string
    if (window.location.pathname === "/sign-in") {
        authInstructions = "Enter your email and password to sign in!"
    } else if (window.location.pathname === "/sign-in/forgot-password") {
        authInstructions = "Enter your email to reset your password!"
    } else {
        authInstructions = "Enter a new password to set it!"
    }

    return (
        <>
            {authInstructions}
        </>
    )
}