import { Text, Select } from "@chakra-ui/react";
import { ScheduleFrequency } from "lib/coplay/types/BackendTypes";

export function ScheduledFrequencySelector(
    props: { frequency: ScheduleFrequency, frequencyStart: Function }
) {
    let scheduledFrequencySelector: JSX.Element
    if (props.frequency === ScheduleFrequency.OneTime) {
        scheduledFrequencySelector = null
    } else {
        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            props.frequencyStart(event.target.value as ScheduleFrequency);
        }

        scheduledFrequencySelector = (
            <label
                style={{ paddingLeft: "2px", display: "flex", alignItems: "center" }}
            >
                <Text style={{ paddingRight: "16px" }}>every</Text>
                <Select maxWidth={'172px'} value={props.frequency} onChange={handleChange}>
                    <option value={ScheduleFrequency.Daily}>day</option>
                    <option value={ScheduleFrequency.Weekly}>week</option>
                    <option value={ScheduleFrequency.Monthly}>month</option>
                </Select>
            </label>
        )
    }

    return scheduledFrequencySelector
}