import { SupportedVendors, VendorMember } from 'lib/coplay/types/PaymentTypes';
import { Browser, Page } from 'puppeteer-core/lib/esm/puppeteer/api-docs-entry';

export enum BrowserStrategyAction {
    Manage = 'manage',
    Aggregate = 'aggregate',
}

export type RunStrategy = (browser: Browser, page: Page, callback: () => void) => Promise<void>;


export abstract class BrowserStrategy {
    abstract action: BrowserStrategyAction;
    abstract vendor: SupportedVendors;

    createConfig(token: string): {
        token: string;
        vendor: SupportedVendors;
        quality: number;
        wsUrl: string;
        id?: string;
    } {
        const wsUrl = `wss://portal-dev.sparklightlabs.com/?token=${token}`;

        return {
            token,
            vendor: this.vendor,
            quality: 80,
            wsUrl,
        };
    }

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        throw new Error('Not implemented');
    }
}

export abstract class ManageStrategy extends BrowserStrategy {
    action = BrowserStrategyAction.Manage;
    member: VendorMember;

    constructor(member: VendorMember) {
        super();
        this.member = member;
    }

    createConfig(token: string): {
        token: string;
        vendor: SupportedVendors;
        quality: number;
        wsUrl: string;
        id?: string;
    } {
        const wsUrl = `wss://portal-dev.sparklightlabs.com/manage?token=${token}&userId=${this.member.id}&vendor=${this.vendor}`;
        return {
            token,
            vendor: this.vendor,
            quality: 80,
            wsUrl,
        };
    }

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        throw new Error('Not implemented');
    }
}

export class AggXboxStrategy extends BrowserStrategy {
    action = BrowserStrategyAction.Aggregate;
    vendor = SupportedVendors.Microsoft;

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        console.log('Xbox strategy running');

        await page.setUserAgent(
            "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.125 Safari/537.36"
        );

        const successLoginPromise = new Promise((resolve) => {
            page.on('response', async (response) => {
                console.log("[Response] ", response.url());
                if (response.url().startsWith('https://login.live.com/ppsecure/post.srf?')) {
                    if ([302].includes(response.status())) {
                        resolve(true);
                    }
                }
            });
        });

        // await successLoginPromise;
        // callback();

        const authPromise = new Promise((resolve) => {
            browser.on('targetchanged', (target) => {
                if (target === page.target() && target.url().includes('https://coplay.io/')) {
                    console.log("[Target changed] ", target.url());
                    resolve(target.url());
                }
            });
        });

        await authPromise;
        console.log('Xbox strategy Finished');
    }
}

export class AggDisneyStrategy extends BrowserStrategy {
    action = BrowserStrategyAction.Aggregate;
    vendor = SupportedVendors.Disney;

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        throw new Error('Not implemented');
    }
}

export class ManageXboxStrategy extends ManageStrategy {
    action = BrowserStrategyAction.Manage;
    vendor = SupportedVendors.Microsoft;

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        console.log('Manage Xbox strategy running');
        await new Promise((resolve) => setTimeout(resolve, 100000));
        console.log('Manage Xbox strategy Finished');
    }
}

export class ManageDisneyStrategy extends ManageStrategy {
    action = BrowserStrategyAction.Manage;
    vendor = SupportedVendors.Disney;

    static async run(browser: Browser, page: Page, callback: () => void): Promise<void> {
        throw new Error('Not implemented');
    }
}

export function getRunStrategy(action: BrowserStrategyAction, vendor: SupportedVendors): RunStrategy {
    switch (action) {
        case BrowserStrategyAction.Manage:
            switch (vendor) {
                case SupportedVendors.Microsoft:
                    return ManageXboxStrategy.run;
                default:
                    throw new Error(`No Manage strategy found for vendor: ${vendor}`);
            }
        case BrowserStrategyAction.Aggregate:
            switch (vendor) {
                case SupportedVendors.Microsoft:
                    return AggXboxStrategy.run;
                case SupportedVendors.Disney:
                    return AggDisneyStrategy.run;
                default:
                    throw new Error(`No Aggregate strategy found for vendor: ${vendor}`);
            }
        default:
            throw new Error(`No strategy found for action: ${action}`);
    }
}