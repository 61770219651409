import { Button } from "@chakra-ui/react"
import { ScheduleFrequency } from "lib/coplay/types/BackendTypes"

export default function DaySelector(props: {
    day: number, frequency: ScheduleFrequency, getStart: Date, frequencyStart: Function
}) {
    let color: string
    let backgroundColor: string
    let onClick: () => void
    if (props.frequency === ScheduleFrequency.Weekly
        && props.getStart.getUTCDay() !== props.day) {
        onClick = () => {
            const newStart = new Date(props.getStart)
            let startDay = newStart.getUTCDay()
            if (!startDay) {
                startDay = 7
            }
            newStart
                .setDate(newStart.getDate() - startDay + (props.day ? props.day : 7))
            if (new Date() > newStart) {
                newStart.setDate(newStart.getDate() + 7)
            }
            props.frequencyStart(props.frequency, newStart)
        }
    } else {
        color = "white"
        backgroundColor = "brand.500"

        if (props.frequency === ScheduleFrequency.Daily) {
            onClick = () => {
                const newStart = new Date(props.getStart)
                let startDay = newStart.getUTCDay()
                if (!startDay) {
                    startDay = 7
                }
                let dayGap = (props.day ? props.day : 7) - startDay
                if (dayGap < 0) {
                    dayGap += 7
                }
                newStart.setDate(newStart.getDate() + dayGap)
                props.frequencyStart(ScheduleFrequency.Weekly, newStart)
            }
        } else {
            onClick = () => props.frequencyStart(ScheduleFrequency.Daily)
        }
    }

    return (
        <Button style={{
            marginRight: "16px",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color
        }} backgroundColor={backgroundColor} onClick={onClick}>
            {["S", "M", "T", "W", "T", "F", "S"][props.day]}
        </Button>
    )
}