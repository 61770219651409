import { Box, Wrap, WrapItem, Flex, Avatar, Spacer, Text, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, CircularProgress, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { deleteVendorMember, spoofCoplayFunding, spoofDeletedFunding } from "lib/coplay/backend/FirebaseFunctions";
import { VendorMember, VendorSubscription, SupportedVendors } from "lib/coplay/types/PaymentTypes";
import Card from "lib/horizon/components/card/Card";
import { FaCheckCircle, FaEllipsisV, FaWallet } from "react-icons/fa";
import DisneyPlus from '../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../assets/xbox_icon.png';
import { useState } from "react";
import ManageMemberModal from "./modals/ManageMemberModal";

const SubscriptionRenderer: React.FC<{ subscription: VendorSubscription }> = ({ subscription }) => {
    return (
        <>
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                {subscription.name}
            </Text>
            {/* <Text px="15px" fontWeight='bold' fontSize='sm'>
                Next charge: {subscription.nextCharge}
            </Text>
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                Price: ${subscription.price?.toFixed(2) || 'N/A'}
            </Text> */}
            <Text px="15px" fontWeight='bold' fontSize='sm'>
                Days Left: {subscription.daysRemaining}
            </Text>
        </>
    )
}

const MemberListRenderer: React.FC<{ members: VendorMember[] }> = ({ members }) => {
    const [selectedMember, setSelectedMember] = useState<VendorMember | null>(null);

    return (
        <Box h='100%' overflowY="auto" width="100%">
            {members.map((member) => (
                <MemberRenderer key={member.id} member={member} setSelectedMember={setSelectedMember} />
            ))}
            <ManageMemberModal
                isOpen={!!selectedMember}
                onClose={() => setSelectedMember(null)}
                member={selectedMember!}
            />
        </Box>
    );
};



export default MemberListRenderer;


const MemberRenderer: React.FC<{ member: VendorMember, setSelectedMember: (member: VendorMember) => void }> = ({ member, setSelectedMember }) => {
    const onHoverColor = useColorModeValue('brand.500', 'white');
    const secondaryColor = useColorModeValue('gray.400', 'white');
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');
    const bgHover = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');


    // const spoofedFunding: VendorSubscription['coplayFunding'] = {
    //     fundingId: "123",
    //     subscriptionId: "456",
    //     memberId: "789",
    //     name: "Coplay Fund",
    //     amountUsed: 100,
    //     amountRemaining: 200,
    //     status: "Active"
    // }

    const spoofedFunding: VendorSubscription['coplayFunding'] | undefined = undefined


    return (
        <Card
            key={member.id}
            background='transparent'
            _hover={{ color: onHoverColor, cursor: 'pointer', background: bgHover }}
            width="100%"
            boxShadow={cardShadow}
            mb='10px'
            p={{ base: '10px', md: '15px', lg: '15px', sm: '25px' }}
            onClick={() => setSelectedMember(member)}
        >
            <Wrap align="center" spacingY="20px">
                <WrapItem>
                    <Flex alignItems="center" style={{ flexWrap: "wrap" }}>
                        <Avatar src={member.vendor === SupportedVendors.Disney ? DisneyPlus : XboxIcon} />
                        <Flex style={{ width: "247px" }} alignItems="center">
                            <Box>
                                <Text px="15px" fontWeight='bold' fontSize='md' style={{ overflowWrap: "anywhere" }}>
                                    {member.email}
                                </Text>
                                <Text px="15px" fontWeight='bold' fontSize='xs'>
                                    {member.vendor}
                                </Text>
                                <Text color={secondaryColor} px="15px" fontWeight='bold' fontSize='xs'>
                                    Last Updated: {new Date(member.updatedAt).toLocaleString()}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </WrapItem>

                <WrapItem>
                    <FundingRenderer member={member} />
                </WrapItem>

                <Spacer />
                <WrapItem>
                    <Box width="200px">
                        {member.subscriptions.length > 0 ? (
                            <SubscriptionRenderer key={member.subscriptions[0].id} subscription={member.subscriptions[0]} />
                        ) : (
                            <Text px="15px" fontWeight='bold' fontSize='sm'>
                                No active subscriptions
                            </Text>
                        )}
                    </Box>
                </WrapItem>
                <WrapItem overflow={'hidden'} textOverflow={'ellipsis'} h="50px" w="180px">
                    <Text textAlign="center" color={secondaryColor} minW="150px" fontWeight='bold' fontSize='sm'>
                        {member.notes || "Notes"}
                    </Text>
                </WrapItem>
                <WrapItem>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<Icon as={FaEllipsisV} />}
                            variant="ghost"
                            onClick={(e) => e.stopPropagation()}
                        />
                        <MenuList>
                            <MenuItem onClick={(e) => { e.stopPropagation(); deleteVendorMember(member.email) }}>Delete User</MenuItem>
                        </MenuList>
                    </Menu>
                </WrapItem>
            </Wrap>
        </Card>
    )
}

export const FundingRenderer: React.FC<{ member: VendorMember }> = ({ member }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const secondaryColor = useColorModeValue('gray.400', 'white');
    const onFundingClick = () => {
        spoofCoplayFunding(member).then(() => {
            console.log("Funding spoofed")
            onClose()
        }).catch((e) => {
            console.error(`[onFundingClick] Error: ${e}`)
        })
    }
    // TODO: Fix this
    let funding = null;
    if (member.subscriptions.length > 0) {
        funding = member.subscriptions[0].coplayFunding
    }

    if (!funding) {
        return (
            <>
                <Flex _hover={{ color: 'blue.500' }} alignItems="center" color="gray.500" as="button" onClick={(e) => { e.stopPropagation(); onOpen() }}>
                    <Icon mx="10px" as={FaWallet} boxSize={6} />
                    <Text fontWeight='bold' fontSize='xs' _hover={{ fontSize: 'sm' }}>
                        Link Funding
                    </Text>
                </Flex>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Link Funding</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>
                                Linking funding will allow you to manage your subscriptions more effectively. Do you want to proceed?
                            </Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onFundingClick}>
                                Confirm
                            </Button>
                            <Button variant="ghost" onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    return (
        <Flex alignItems="center">
            <Box position="relative" width="40px" height="40px">
                <CircularProgress
                    value={(funding.amountUsed / (funding.amountUsed + funding.amountRemaining)) * 100}
                    color="blue.400"
                    trackColor="gray.200"
                    size="40px"
                />
                <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text fontSize="xs" fontWeight="bold">
                        {Math.round((funding.amountUsed / (funding.amountUsed + funding.amountRemaining)) * 100)}%
                    </Text>
                </Flex>
            </Box>
            <Box ml="10px">
                <Text fontWeight='bold' fontSize='xs'>
                    Balance: ${funding.amountUsed.toFixed(2)} / ${(funding.amountUsed + funding.amountRemaining).toFixed(2)}
                </Text>
                <Text fontWeight='bold' fontSize='xs' color="blue.500">
                    <Flex alignItems="center">
                        <Icon as={FaCheckCircle} mr={1} />
                        CoPlay Managed
                    </Flex>
                </Text>
                <Text color={secondaryColor} fontSize='xs'>
                    {funding.status}
                </Text>
            </Box>
            <Spacer />
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<Icon as={FaEllipsisV} />}
                    color="gray.200"
                    variant="ghost"
                    onClick={(e) => e.stopPropagation()}
                />
                <MenuList>
                    <MenuItem onClick={(e) => { e.stopPropagation(); spoofDeletedFunding(member) }}>Delete Funding</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}