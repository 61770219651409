import { Button, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, useColorModeValue, Text, Icon } from "@chakra-ui/react";
import { useXboxUsers } from "lib/coplay/components/DataProvider";
import { addNewUser } from "lib/coplay/backend/ApiRequests";
import { BaseAction, BaseActionProps, BaseErrorScreen, ViewState } from "lib/coplay/components/actions/BaseAction";
import { MdCheckCircleOutline, MdPersonAdd } from "react-icons/md";
import { CreateUserModal } from "lib/coplay/components/modals/UserAggregationModal";
import ContactLink from "lib/coplay/components/actions/user/ContactLink";
import { BasicResponse, ResponseStatus } from "lib/coplay/types/BackendTypes";
import { useUserLimit } from "lib/coplay/context/ConfigurationContext";

export const NewUserDialog: BaseActionProps['DialogElem'] = (props: {
    handleClose: () => void,
    setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
    setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
}) => {
    const userLimit = useUserLimit();
    const xboxUsers = useXboxUsers()

    function handleAdd(loginCode: string) {
        props.setViewState(ViewState.Loading);
        addNewUser(loginCode).then((response) => {
            if (response.status !== ResponseStatus.Success) {
                props.setActionResponse(response);
                console.log("Error adding user", response)
                props.setViewState(ViewState.Error);
            } else {
                props.setActionResponse(response);
                props.setViewState(ViewState.Success);
            }
        });
    }


    let newUserDialog: JSX.Element
    if (xboxUsers.size() >= userLimit) {
        newUserDialog = (
            <>
                <ModalHeader>
                    Upgrade Your CoPlay XM Account
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Your CoPlay XM account has reached its maximum number of Xbox Live®
                    users. If you'd like to raise your limit, please <ContactLink />!
                </ModalBody>
                <ModalFooter />
            </>
        )
    } else {
        newUserDialog = (
            <CreateUserModal close={props.handleClose} handler={handleAdd} />
        )
    }

    return newUserDialog
}

function SuccessfulAddScreen(props: { close: () => void, setViewState: React.Dispatch<React.SetStateAction<ViewState>>, response: BasicResponse }) {
    let color = useColorModeValue('brand.500', 'white');

    return (
        <>
            <ModalHeader>Successfully Added User!</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Icon as={MdCheckCircleOutline} width='100px' height='100px' color={color} />,
                    <Text py='25px'>{props.response.emailAddress} was succesfully added to CoPlay XM</Text>
                    <Button width='200px' variant="brand" onClick={props.close}>Close</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

function AddActionElem(props: { onClickHandler: () => void }) {
    return (
        <>
            <Button size='lg' variant="brand" onClick={() => props.onClickHandler()} mx='5px' leftIcon={<MdPersonAdd />}>
                Add New User
            </Button>
        </>
    )
}

export function AddNewActionButton() {
    return (
        <>
            <BaseAction
                ActionElem={AddActionElem}
                DialogElem={NewUserDialog}
                ErrorElem={BaseErrorScreen}
                SuccessElem={SuccessfulAddScreen}
            />
        </>
    )
}