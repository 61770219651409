import { Text, Select } from "@chakra-ui/react";
import { OauthActions } from "lib/coplay/types/BackendTypes";
import { useState } from "react";


export type ActionController = {
    action: OauthActions,
    onActionChange: (action: OauthActions) => void
}

export const useActionController = (): ActionController => {
    const [action, setAction] = useState<OauthActions>(OauthActions.XMProfile);

    const onActionChange = (action: OauthActions) => {
        setAction(action);
    }

    return {
        action,
        onActionChange
    }
}


export function ScheduledActionSelector(props: { controller: ActionController }) {
    const { action, onActionChange } = props.controller;

    return (
        <>
            <Text style={{ width: "68px" }} fontWeight='bold'>Action: </Text>
            <Select maxWidth={'285px'} value={action} onChange={(e) => onActionChange(e.target.value as OauthActions)}>
                <option value={OauthActions.XMProfile}>Apply Profile</option>
                <option value={OauthActions.ClearConversations}>Clear Conversations</option>
                <option value={OauthActions.RemoveFriends}>Remove Friends</option>
                <option value={OauthActions.SyncFriends}>Sync Friends</option>
            </Select>
        </>
    )
}