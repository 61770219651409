import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text } from "@chakra-ui/react"
import { MdHome, MdBarChart, MdGroups, MdCalendarMonth } from "react-icons/md"
import { BatchInstallActionLink } from "../actions/BatchInstallActionLink"
import { ClearConversationsActionLink } from "../actions/ClearConversationsActionLink"
import { RemoveFriendsActionLink } from "../actions/friending/RemoveFriendActionLink"
import { SyncFriendsActionLink } from "../actions/friending/SyncFriendsActionLink"
import { ScheduleActionLink } from "../actions/scheduling/ScheduleActionLink"
import { DeleteUsersActionLink } from "../actions/user/DeleteUsersActionLink"
import { RefreshUsersActionLink } from "../actions/user/RefreshUsersActionLink"
import { RemoveProfileActionLink } from "../actions/user/RemoveProfileActionLink"
import { SetProfileActionLink } from "../actions/user/SetProfileActionLink"
import { SyncProfileActionLink } from "../actions/user/SyncProfileActionLink"
import { SidebarLink } from "../app_layout/AppSidebar"

export function XboxSidebarSection(props: { isDashboard: boolean }) {
    return (
        <Accordion allowMultiple index={props.isDashboard ? [0] : []}>

            <AccordionItem border='none' >
                <Box position="relative" >
                    <SidebarLink label="Dashboard" icon={MdHome} routeName={'/app/dashboard'}>
                        <></>

                        <Flex border='' position="absolute" right={0} top={0} bottom={0} w='25%' justifyContent="center" alignItems="center">
                            <AccordionButton></AccordionButton>

                        </Flex>
                    </SidebarLink>
                </Box>

                <AccordionPanel _hover={{ cursor: 'pointer' }} pb={2} pl={16}>
                    <Text fontSize='sm' fontWeight={'bold'}> User Actions</Text>

                    <SyncFriendsActionLink key="SyncFriendsActionLink" />
                    <RemoveFriendsActionLink key="RemoveFriendsActionLink" />
                    <ClearConversationsActionLink key="ClearConversationsActionLink" />
                    <RefreshUsersActionLink key="RefreshUsersActionLink" />
                    <DeleteUsersActionLink key="DeleteUsersActionLink" />

                    <Text fontSize='sm' fontWeight={'bold'}> Console Actions</Text>
                    <BatchInstallActionLink key="BatchInstallActionLink" />


                    <Text fontSize='sm' fontWeight={'bold'}> Profile Actions </Text>
                    <SetProfileActionLink key="SetProfileActionLink" />
                    <RemoveProfileActionLink key="RemoveProfileActionLink" />
                    <SyncProfileActionLink key="SyncProfileActionLink" />

                    <Text fontSize='sm' fontWeight={'bold'}> Scheduling </Text>
                    <ScheduleActionLink key={"Schedule"} />

                </AccordionPanel>

            </AccordionItem>

            <SidebarLink label="Usage" icon={MdBarChart} routeName={'/app/usage'} />
            <SidebarLink label="Create/View Profiles" icon={MdGroups} routeName={'/app/profiles'} />
            <SidebarLink label="View Schedules" icon={MdCalendarMonth} routeName={'/app/schedules'} />
        </Accordion>
    )
}