import React from 'react';
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { MdOutlineErrorOutline } from "react-icons/md";
import MiniStatistics from "lib/horizon/components/card/MiniStatistics";
import IconBox from "lib/horizon/components/icons/IconBox";
import { XboxUser, UserStatusMessage } from 'lib/coplay/types/BackendTypes';
import { useXboxUsers } from '../../DataProvider';


export default function ErrorsAndWarningsStats() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const xboxUsers = useXboxUsers();


    let numErrorsAndWarnings = 0;
    xboxUsers.forEach((xboxUser: XboxUser) => {
        let status = xboxUser.userStatus.statusMessage;
        if (status === UserStatusMessage.Error || status === UserStatusMessage.Warning) {
            numErrorsAndWarnings++;
        }
    });

    return (
        <MiniStatistics
            startContent={
                <IconBox
                    w='50px'
                    h='50px'
                    bg={boxBg}
                    icon={<Icon w='32px' h='32px' as={MdOutlineErrorOutline} color={brandColor} />}
                />
            }
            name='Errors & Warnings'
            value={numErrorsAndWarnings}
        />
    );
}