import { Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { unscheduleAction } from 'lib/coplay/backend/ApiRequests';
import { ScheduleEvent, BasicResponse } from 'lib/coplay/types/BackendTypes';
import Card from 'lib/horizon/components/card/Card';
import { useState } from 'react';

function createSubText(label: string) {
    return (
        <Text
            color='secondaryGray.600'
            fontSize={{
                base: 'sm'
            }}
            fontWeight='400'
            me='14px'>
            {label}
        </Text>
    )
}

export function createSchedules(schedules: ScheduleEvent[], onSelect?: (event: ScheduleEvent) => void, emailAddress?: string) {

    const elems = schedules.map((schedule) => {
        const startDate = new Date(schedule.startDate)
        const localeTime = startDate.toLocaleTimeString()

        return <ScheduleListItem
            key={schedule.scheduleId}
            name={schedule.name || `${schedule.scheduledRequest.oauthAction}-${schedule.frequency}`}
            createdOn={new Date(schedule.scheduledRequest.requestId)}
            from={startDate.toLocaleDateString('en-US')}
            to={new Date(schedule.endDate).toLocaleDateString('en-US')}
            time={`Start Time: ${localeTime.slice(0, -6)} ${localeTime.slice(-2)}`}
            onClick={onSelect ? () => onSelect(schedule) : null}
            onRemove={() => unscheduleAction(schedule.scheduleId, emailAddress ? emailAddress : 'all')} />
    })

    return elems;
}

export default function ScheduleListItem(props: {
    name: string;
    createdOn: Date;
    from: string;
    to: string;
    time: string,
    onClick?: () => void;
    onRemove: () => Promise<BasicResponse>;
}) {
    const { name, createdOn, from, to, onClick, onRemove } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleUnschedule = (e: any) => {
        e.stopPropagation()
        setIsLoading(true)
        onRemove().then((response) => {

        }).catch((error: any) => {
            console.log(`[ScheduleListItem] Error: ${JSON.stringify(error)}`)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Chakra Color Mode
    const textColor = useColorModeValue('brands.900', 'white');
    const bgItem = useColorModeValue(
        { bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)', cursor: 'pointer' },
        { bg: 'navy.700', boxShadow: 'unset', cursor: 'pointer' }
    );

    return (
        <Card _hover={bgItem} bg='transparent' boxShadow='unset' px='24px' py='10px' transition='0.2s linear'>
            <Flex direction={{ base: 'column' }} justify='center'>
                <Flex position='relative' align='center' onClick={props.onClick} zIndex={'0'}>
                    <Flex
                        direction='column'
                        w={{ base: '70%', md: '100%' }}
                        me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
                        <Text
                            color={textColor}
                            fontSize={{
                                base: 'md'
                            }}
                            mb='5px'
                            fontWeight='bold'
                            me='14px'>
                            {name}
                        </Text>
                        {createSubText(`Created: ${createdOn.toLocaleString()}`)}
                        {createSubText(`From: ${from}`)}
                        {createSubText(`to: ${to}`)}
                        {createSubText(props.time)}
                    </Flex>
                    <Flex me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }} align='center'>
                        <Button zIndex={10} isLoading={isLoading} colorScheme="red" variant='ghost' size="sm" onClick={handleUnschedule}> Remove </Button>
                    </Flex>
                    {onClick && <Button zIndex={10} colorScheme="blue" variant='ghost' size="sm" onClick={() => onClick()}> Details </Button>}
                </Flex>
            </Flex>


        </Card>
    );
}
