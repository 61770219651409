import { Flex, VStack, Box, HStack, Button, Text } from "@chakra-ui/react";
import RemoteBrowserCanvas from "lib/coplay/components/remote_browser/RemoteBrowserCanvas";
import { ManageDisneyStrategy, ManageXboxStrategy } from "lib/coplay/components/remote_browser/strategies";
import { SupportedVendors, VendorMember } from "lib/coplay/types/PaymentTypes";
import React from "react";
import SubscriptionsTab from "./SubscriptionsTab";
import OrdersTab from "./OrdersTab";

interface MemberFinancialsProps {
    member: VendorMember;
}

const MemberFinancials: React.FC<MemberFinancialsProps> = ({ member }) => {
    const [selectedTab, setSelectedTab] = React.useState('Subscriptions');


    return (
        <Flex flex={1} bg='gray.500'>
            <VStack spacing={4} width="100%">
                <Box bg="gray.100" p={4} borderRadius="md" h='100%' width="100%">
                    <HStack spacing={4} justify="center" overflowX="auto" mb={2}>
                        {['Subscriptions', 'Orders', 'CoPlay'].map((tab) => (
                            <Button
                                key={tab}
                                variant={selectedTab === tab ? "solid" : "link"}
                                onClick={() => setSelectedTab(tab)}
                            >
                                {tab}
                            </Button>
                        ))}
                    </HStack>
                    {selectedTab === 'Orders' && <OrdersTab member={member} />}
                    {selectedTab === 'Subscriptions' && <SubscriptionsTab subscriptions={member.subscriptions} />}
                    {selectedTab === 'CoPlay' && <CoPlayTab member={member} />}
                </Box>
            </VStack>
        </Flex>
    );
};

function CoPlayTab({ member }: { member: VendorMember }) {
    const strategy =
        member.vendor === SupportedVendors.Disney ? new ManageDisneyStrategy(member) : new ManageXboxStrategy(member);

    return (
        <>
            <Flex justify="center" align="center">
                <Box>
                    <Text textAlign="center" fontSize="lg" fontWeight="bold">Manage Your account</Text>
                    <RemoteBrowserCanvas strategy={strategy} onFinish={() => { }} />
                </Box>
            </Flex>
        </>

    );
}

export default MemberFinancials;