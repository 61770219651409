import { Box, Flex, Text } from "@chakra-ui/react";
import { VendorSubscription } from "lib/coplay/types/PaymentTypes";

interface SubscriptionsTabProps {
    subscriptions: VendorSubscription[];
}

const SubscriptionsTab: React.FC<SubscriptionsTabProps> = ({ subscriptions }) => {

    const subscriptionRenderer = (subscription: VendorSubscription) => {
        return (
            <Box key={subscription.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} mb={2}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text fontWeight="semibold">{subscription.name}</Text>
                    <Box>
                        <Text fontSize="sm" fontWeight="bold">End Date: {new Date(subscription.endDate).toLocaleDateString()}</Text>
                        {subscription.daysRemaining !== undefined && (
                            <Text fontSize="sm" fontWeight="bold">Days Remaining: {subscription.daysRemaining}</Text>
                        )}
                    </Box>

                </Flex>
                <Text fontSize="sm" color="gray.500" mb={0} p={0}>{subscription.description}</Text>
                <Flex justifyContent="space-between" alignItems="center" mt={2}>
                    {subscription.nextCharge && (
                        <Text fontSize="sm" color="gray.500">Next Charge: {new Date(subscription.nextCharge).toLocaleDateString()}</Text>
                    )}
                    {subscription.price !== undefined && (
                        <Text fontSize="sm" color="gray.500">Price: ${subscription.price.toFixed(2)}</Text>
                    )}
                </Flex>
                <Text fontSize="sm" color="gray.500">Payment Method: {subscription.payment.name ? subscription.payment.name : "Unknown"}</Text>

                {subscription.payment.lastFourDigits && (
                    <>
                        <Text fontSize="sm" color="gray.500">Card: **** **** **** {subscription.payment.lastFourDigits}</Text>
                    </>
                )}
            </Box>
        );
    };

    return (
        <Box overflowY="auto" maxH="500px">
            <Text fontSize="xl" fontWeight="bold" mb={4}>Subscriptions</Text>
            {subscriptions.length === 0 ? (
                <Text>No subscriptions found.</Text>
            ) : (
                <Box>
                    {subscriptions.map((subscription) => subscriptionRenderer(subscription))}
                </Box>
            )}
        </Box>
    );
};

export default SubscriptionsTab;