import { Text, Button, Flex, Icon, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { MdSync } from "react-icons/md";
import { refreshUsers } from "lib/coplay/backend/ApiRequests";
import { useSelectedUsers } from "lib/coplay/components/DataProvider";
import { addXboxUsersObserver } from "lib/coplay/backend/FirebaseFunctions";
import { ActionLinkElem, ACTION_LINK_TIMEOUT } from "lib/coplay/components/actions/ActionLink";
import { BaseAction, ViewState, BaseActionProps, BaseErrorScreen, BaseSuccessScreen } from "lib/coplay/components/actions/BaseAction";
import { BasicResponse } from "lib/coplay/types/BackendTypes";


const RefreshLinkAction: BaseActionProps['ActionElem'] = (props: { onClickHandler: () => void }) => {
    let label = 'Refresh Users';
    let icon = <Icon as={MdSync} width='20px' height='20px' />;

    const selectedUsers = useSelectedUsers();

    return (
        <>
            <ActionLinkElem onClickHandler={props.onClickHandler} label={label} icon={icon} isActive={selectedUsers.size() > 0} />
        </>
    )
}

export const RefreshScreen: BaseActionProps['DialogElem'] = (
    props: {
        handleClose: () => void,
        setViewState: React.Dispatch<React.SetStateAction<ViewState>>,
        setActionResponse: React.Dispatch<React.SetStateAction<BasicResponse>>
    }) => {
    const selectedUsers = useSelectedUsers();

    const handler: () => void = () => {

        props.setViewState(ViewState.Loading);
        const timeout = setTimeout(() => {
            props.setViewState(ViewState.Warning);
        }, ACTION_LINK_TIMEOUT);

        addXboxUsersObserver(() => {
            props.handleClose();
            clearTimeout(timeout);
        })

        const selected: string[] = [];
        selectedUsers.forEach(user => selected.push(user.emailAddress))
        refreshUsers(selected).catch((error) => {
            console.log("[RefreshScreen] Refresh error", error)
            clearTimeout(timeout);
            props.setViewState(ViewState.Error);
        });
    }

    return (
        <>
            <ModalHeader>Refresh</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
                <Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
                    <Text py='25px'>Are you sure you want to refresh {selectedUsers.size()} user(s)?</Text>
                    <Button width='200px' variant="brand" onClick={handler}>Confirm</Button>
                </Flex>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </>
    )
}

export function RefreshUsersActionLink() {
    return (
        <>
            <BaseAction
                ActionElem={RefreshLinkAction}
                DialogElem={RefreshScreen}
                ErrorElem={BaseErrorScreen}
                SuccessElem={BaseSuccessScreen}
            />
        </>
    )
}