import { useColorModeValue, Text } from "@chakra-ui/react"

export default function ColorModeParagraph(props: {
    lightMode: string,
    darkMode: string,
    style?: { [key: string]: string },
    children: React.ReactNode
}) {
    const colorModeValue = useColorModeValue(props.lightMode, props.darkMode)

    return (
        <Text style = {props.style} color = {colorModeValue}>
            {props.children}
        </Text>
    )
}