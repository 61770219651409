// Chakra imports
import { Box, Button, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
// Custom components
import Card from 'lib/horizon/components/card/Card';
// Assets
import { XboxIcon } from 'lib/coplay/components/modals/console/XboxIcon';
import { AddConsoleModal } from 'lib/coplay/components/modals/console/add_console/add_console_modal/AddConsoleModal';
import React, { useCallback } from 'react';
import { ConsoleDetailsModal } from 'lib/coplay/components/modals/console/console_details/ConsoleDetailsModal';
import { XboxConsole, XboxUser } from 'lib/coplay/types/BackendTypes';

export default function SpotlightConsole(props: { emailAddress?: string, xboxConsole?: XboxConsole, [x: string]: any }) {
    const { emailAddress, xboxConsole, ...rest } = props;

    let elem = <LoadingState />
    const hasConsole = props.xboxConsole !== undefined && props.xboxConsole !== null;
    const hasEmailAddress = props.emailAddress !== undefined && props.emailAddress !== null;

    if (hasEmailAddress) {
        if (hasConsole) {
            elem = <ConsoleDetails emailAddress={emailAddress} xboxConsole={xboxConsole} />
        } else {
            elem = <ConsoleZeroState emailAddress={emailAddress} />
        }
    }

    return (
        <Card {...rest} mb='20px' alignItems='center' p='20px'>
            {elem}
        </Card>
    );
}


function LoadingState() {
    return (
        <Text> Loading...</Text>
    )
}

function ConsoleDetails(props: { emailAddress: string, xboxConsole: XboxConsole }) {
    const iconColor = useColorModeValue('#000000', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <ConsoleDetailsModal emailAddress={props.emailAddress} isOpen={isOpen} onClose={onClose} />
            <Box w='100%'>
                <Text fontWeight='bold' fontSize='2xl' >
                    Your Xbox
                </Text>
            </Box>
            <Flex h='100%' direction='column' justifyContent='center' alignContent='center'>
                <Flex w='100%' justifyContent='center' alignContent={'center'}>
                    <Box px={"35px"}>
                        < XboxIcon size='165px' color={iconColor} />
                    </Box>
                    <Flex direction='column' justifyContent='center' alignContent='center'>
                        <Text fontSize={'xl'}>ID: {props.xboxConsole.id}</Text>
                        <Text fontSize={'xl'}>Name: {props.xboxConsole.name}</Text>
                        <Text fontSize={'xl'}>Status: {props.xboxConsole.powerState}</Text>
                        <Button onClick={onOpen} size="md" m={'15px'} colorScheme='blue' variant='solid'>View Details</Button>
                    </Flex>
                </Flex>
            </Flex>
        </>

    )
}

function ConsoleZeroState(props: { emailAddress: string }) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const brandColor = useColorModeValue('brand.500', 'white');
    const textColorSecondary = 'gray.400';
    let xboxColor = useColorModeValue('#000000', 'white');

    const { isOpen, onOpen, onClose } = useDisclosure()


    const [userLinkConsole, setUserLinkConsole] = React.useState<XboxUser | null>(null);
    const openUserLinkConsole = useCallback((user: XboxUser) => setUserLinkConsole(user), []);
    const closeUserLinkConsole = useCallback(() => setUserLinkConsole(null), [])

    const onConsoleClose = useCallback(() => {
        closeUserLinkConsole();
        onClose();
    }, [])



    return (
        <Flex h='100%' direction={{ base: 'column', '2xl': 'row' }}>

            <AddConsoleModal emailAddress={props.emailAddress} isOpen={isOpen} onClose={onConsoleClose} />

            <Flex p='15px' justifyContent='center' alignContent='center' h='100%' w='100%'>
                <XboxIcon size="150px" color={xboxColor} />
            </Flex>

            <Flex direction='column' pe='22px'>
                <Text
                    color={textColorPrimary}
                    fontWeight='bold'
                    textAlign='start'
                    fontSize='2xl'
                    mt={{ base: '20px', '2xl': '50px' }}>
                    Link your Console
                </Text>
                <Text
                    color={textColorSecondary}
                    fontSize='md'
                    my={{ base: 'auto', '2xl': '10px' }}
                    mx='auto'
                    textAlign='start'>
                    Click below to link your xbox console to your account. This will allow CoPlaY XM to install and remove apps on your console
                </Text>
                <Flex w='100%'>
                    <Button
                        me='100%'
                        mb='50px'
                        w='140px'
                        minW='140px'
                        mt={{ base: '20px', '2xl': 'auto' }}
                        variant='brand'
                        fontWeight='500'
                        onClick={onOpen}>
                        Link Xbox
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}