import { CheckIcon, WarningIcon } from "@chakra-ui/icons"
import {
    useDisclosure, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react"
import ProfileModal from "lib/coplay/components/modals/ProfileModal"
import { PartialXMProfile } from "lib/coplay/types/BackendTypes"

export default function ProfileButton(props: { profile: PartialXMProfile }) {
    const disclosure = useDisclosure()

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        disclosure.onOpen()
    }

    const color = props.profile.error ? "orange.500" : "green.500"
    const icon = props.profile.error ? <WarningIcon fontSize="xs" /> : <CheckIcon fontSize="xs" />

    return (
        <Text
            px="15px"
            color={color}
            fontWeight='bold'
            fontSize='xs'
            _hover={{ fontSize: "sm" }}
            onClick={onClick}
        >
            {icon} {props.profile.name}

            <Modal
                isOpen={disclosure.isOpen}
                size={"6xl"}
                onClose={disclosure.onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton style={{ zIndex: 1 }} />
                    <ModalBody>
                        {props.profile.error && (
                            <Text as="span" color="red.500" ml={2} fontSize="md">
                                *{props.profile.error}
                            </Text>
                        )}
                        <ProfileModal profileId={props.profile.id} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Text>
    )
}