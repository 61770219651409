import { useHistory } from "react-router-dom";

// Chakra imports
import { Box, Flex, Grid, IconButton, Text, VStack } from '@chakra-ui/react';

// Assets
import SpotlightUserCard from './SpotlightUserCard';
import SpotlightNotes from './SpotlightNotes';
import SpotlightConsole from './SpotlightConsole';
import SpotlightFriends from './SpotlightFriends';
import SpotlightConversations from './SpotlightConversations';
import SpotlightPrivacy from './SpotlightPrivacy';
import ColorModeParagraph from "lib/coplay/components/views/dashboard/dashboard_list/spotlight/ColorModeParagraph";
import PrivilegesLink from "lib/coplay/components/views/dashboard/dashboard_list/spotlight/PrivilegesLink";
import SpotlightPrivileges from "lib/coplay/components/views/dashboard/dashboard_list/spotlight/SpotlightPrivileges";
import {
    subscribeToConsoleDetails,
    subscribeToConversations,
    subscribeToFriends,
    subscribeToSettings,
    subscribePrivileges,
    subscribeToXboxUser,
    updateNotes
} from 'lib/coplay/backend/FirebaseFunctions';
import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import Card from "lib/horizon/components/card/Card";
import { changeSettings, clearConversations } from 'lib/coplay/backend/ApiRequests';
import { XboxUser, Friends, XBLCollatedConversations, XboxConsole, UserPrivacySettings } from "lib/coplay/types/BackendTypes";
import SpotlightUserLogs from './SpotlightUserLogs';

const useSpotlightData = (user?: XboxUser) => {

    const [userState, setUserState] = useState<XboxUser | null>(null);
    const [friends, setFriends] = useState<Friends | null>(null);
    const [conversations, setConversations] = useState<XBLCollatedConversations | null>(null);
    const [xboxConsole, setXboxConsole] = useState<XboxConsole | null>(null);
    const [settings, setSettings] = useState<UserPrivacySettings | null>(null);
    const [getPrivileges, setPrivileges] = useState<{ [key: string]: string }[]>([])

    useEffect(() => {
        if (user === null || user === undefined) {
            setUserState(null);
            setFriends(null);
            setConversations(null);
            setSettings(null);
            setPrivileges(undefined)
            setXboxConsole(null);
        } else {

            const unsubUser = subscribeToXboxUser(user.emailAddress, setUserState);
            const unsubFriends = subscribeToFriends(user.emailAddress, setFriends);
            const unsubConversations = subscribeToConversations(user.emailAddress, setConversations)
            const unsubSettings = subscribeToSettings(user.emailAddress, setSettings);
            const unsubscribePrivileges = subscribePrivileges(user.emailAddress, setPrivileges)
            const unsubConsole = subscribeToConsoleDetails(user.emailAddress, setXboxConsole);

            return () => {
                unsubUser();
                unsubFriends();
                unsubConversations();
                unsubSettings();
                unsubscribePrivileges()
                unsubConsole();
            }
        }
    }, [user]);

    return {
        userState,
        friends,
        conversations,
        xboxConsole,
        settings,
        getPrivileges
    }

}


export default function SpotlightView(props: { user?: XboxUser }) {
    const history = useHistory<undefined>()

    const { userState, friends, conversations, xboxConsole, settings, getPrivileges }
        = useSpotlightData(props.user);

    useEffect(() => {
        if (userState) {
            window.scrollTo(0, 0);
        }
    }, [userState]);

    const notesUpdate = (notes: string) => { userState && updateNotes(userState, notes) }

    const clearConvos = async () => { userState && await clearConversations([userState.emailAddress]) }

    const updateSettings = async (settings: UserPrivacySettings) => { userState && await changeSettings([userState.emailAddress], settings) }


    if (userState == null || userState == undefined)
        return <SpotlightZeroState />

    return (
        <Box>
            <IconButton
                aria-label="Go back"
                icon={<MdArrowBack />}
                onClick={() => history.push("/app/dashboard")}
                mb={4} />
            <Grid
                templateColumns={{
                    base: '1fr',
                    lg: '1.34fr 1fr 1.62fr'
                }}
                templateRows={{
                    base: 'repeat(3, 1fr)',
                    lg: '1fr'
                }}
                gap={{ base: '20px', xl: '20px' }}>
                <SpotlightUserCard
                    user={userState}
                    gridArea='1 / 1 / 2 / 2'
                />

                <SpotlightNotes
                    gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
                    notes={userState?.notes}
                    updateNotes={notesUpdate} />
                <SpotlightConsole
                    gridArea={{
                        base: '3 / 1 / 4 / 2',
                        lg: '1 / 3 / 2 / 4'
                    }}
                    minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                    pe='20px'
                    pb={{ base: '100px', lg: '20px' }}
                    emailAddress={userState?.emailAddress}
                    xboxConsole={xboxConsole}
                />
            </Grid>
            <Grid
                mb='20px'
                templateColumns={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1.34fr 1.62fr 1fr'
                }}
                templateRows={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1fr'
                }}
                gap={{ base: '20px', xl: '20px' }}>


                <Card style=
                    {{ marginBottom: "20px", maxHeight: "500px", overflowY: "scroll" }}>
                    <ColorModeParagraph
                        lightMode={"secondaryGray.900"}
                        darkMode={"white"}
                        style={
                            { marginBottom: "4px", fontSize: "24px", fontWeight: "bold" }
                        }
                    >
                        Privileges
                    </ColorModeParagraph>
                    <Text
                        style={{ marginRight: "26px", marginBottom: "15px" }}
                        color={"gray.400"}
                    >
                        These permissions can be adjusted from <PrivilegesLink />.
                    </Text>
                    <VStack spacing={4} style={{ alignItems: "stretch" }}>
                        <SpotlightPrivileges getPrivileges={getPrivileges} />
                    </VStack>
                </Card>
                <SpotlightFriends
                    friends={friends}
                />
                <SpotlightConversations
                    conversations={conversations}
                    onClear={clearConvos}
                    gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
                    minH='365px'
                    pe='20px' />
            </Grid>
            <SpotlightPrivacy
                onSettings={updateSettings}
                settings={settings}
            />
            <SpotlightUserLogs
                user={userState}
            />
        </Box>
    );
}

function SpotlightZeroState() {

    return (
        <Flex h='90vh' w='100%' id="profileZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"}>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    Select a user to see their details
                </Text>
            </Box>
        </Flex>
    )

}

function spotlightLoadingState() {
    return (
        <Flex h='90vh' w='100%' id="profileZeroState" direction={"column"} justifyContent="center" alignContent={"center"} alignItems={"center"}>
            <Box maxW="50%">
                <Text textAlign={"center"} style={{ paddingTop: "20px" }} fontWeight="black" fontSize='3xl'>
                    Loading...
                </Text>
            </Box>
        </Flex>
    )

}