import AuthStatus from "lib/coplay/components/views/auth/AuthStatus"
import AuthButton from "lib/coplay/components/views/auth/AuthButton"

export default function SignInFormReset(props: { getStatus: string }) {
    return (
        <>
            <AuthStatus getStatus = {props.getStatus} />
            <AuthButton>
                Send Email
            </AuthButton>
        </>
    )
}