import React from 'react';
import { HStack, Button, Heading, Text, Flex, Spacer, Avatar, Divider } from '@chakra-ui/react';
import DisneyPlus from '../../../assets/disney_plus_icon.png';
import XboxIcon from '../../../assets/xbox_icon.png';
import { SupportedVendors } from 'lib/coplay/types/PaymentTypes';
import { useUserLimit } from 'lib/coplay/context/ConfigurationContext';

interface PaymentsHeaderProps {
    coplayBalance: number;
    coplaySpend: number;
    monthlyCards: number;
}

export function FilterButtons(props: {
    setVendor: (vendor: SupportedVendors | null) => void,
    allVendors: SupportedVendors[],
    selectedVendor: SupportedVendors | null,
    [key: string]: any
}) {
    const { setVendor, allVendors, selectedVendor, ...otherProps } = props;
    return (
        <HStack justifyContent="center" spacing={4} m={4} {...otherProps}>
            <Button
                variant="outline"
                borderRadius="full"
                onClick={() => setVendor(null)}
                isActive={selectedVendor === null}
            >
                <Flex alignItems="center" justifyContent="center">
                    <Text px={2}>Show All</Text>
                </Flex>
            </Button>
            {allVendors.map((v) => (
                <Button
                    key={v}
                    variant="outline"
                    borderRadius="full"
                    onClick={() => setVendor(v)}
                    isActive={selectedVendor === v}
                >
                    <Flex alignItems="center" justifyContent="center">
                        <Avatar src={v === SupportedVendors.Disney ? DisneyPlus : XboxIcon} size="sm" />
                        <Text px={2}>{v === SupportedVendors.Disney ? 'Disney+' : 'Xbox'}</Text>
                    </Flex>
                </Button>
            ))}
        </HStack>
    )
}

export function FundingDetails(props: {
    coplayBalance: number,
    coplaySpend: number,
    users: number
}) {
    const { coplayBalance, coplaySpend, users: monthlyCards } = props;
    const userLimit = useUserLimit();
    return (
        <>
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500">Users</Text>
                <Text fontSize="xl" fontWeight="bold">{monthlyCards} / {userLimit}</Text>
            </Flex>
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500"> Spent</Text>
                <Text fontSize="xl" fontWeight="bold">${coplaySpend.toFixed(2)}</Text>
            </Flex>
            <Flex direction="column" align="center">
                <Text fontSize="sm" color="gray.500">CoPlay Credit</Text>
                <Text fontSize="xl" fontWeight="bold">${coplayBalance.toFixed(2)}</Text>
            </Flex>
        </>
    )
}

export function PaymentsHeader(props: {
    children: React.ReactNode[]
}) {
    const { children } = props;
    const [firstChild, ...rest] = React.Children.toArray(children);

    return (
        <>
            <HStack spacing={8} justify="space-between" borderRadius="lg">
                <Heading size="lg" fontWeight="bold">Subscription Manager</Heading>
                {firstChild}
                <Spacer />
                {rest}
            </HStack>
            <Text mb={2} color="gray.500" fontSize='md'>
                Aggregate third-party services like Disney+ or Xbox Game Pass, and manage your payments and billing in one place.
            </Text>
            <Divider mb={2} />
        </>

    );
};

export default PaymentsHeader;