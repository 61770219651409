import { Box, Button, Flex, HStack, Spacer, Text, useColorModeValue, VStack, Progress } from "@chakra-ui/react";
import { refreshConsoles } from "lib/coplay/backend/ApiRequests";
import { XboxConsole, XboxState } from "lib/coplay/types/BackendTypes";
import { useState } from "react";
import { XboxIcon } from "../XboxIcon";
import { XboxConsoleName } from "./XboxConsoleName";
import { FaUnlink, FaSync } from 'react-icons/fa'; // Import icons

function bytesToGB(bytes: number) {
    const bytesPerGB = 1024 * 1024 * 1024; // = 2^30
    return (bytes / bytesPerGB).toFixed(2);
}

export const ConsoleDetailsHeader = (props: { emailAddress: string, console: XboxConsole, onUnlink: () => void }) => {

    const iconColor = useColorModeValue('#000000', 'white');
    const [isRefreshing, setIsRefreshing] = useState(false);

    const regex = /(?<=[a-z])(?=[A-Z])/;
    const xboxType = props.console.consoleType.split(regex).join(' ');

    const storageDevices = () => {
        if (props.console.storageDevices) {
            return props.console.storageDevices.map((dev) => {
                const totalSpaceGB = Number(bytesToGB(dev.totalSpaceBytes));
                const freeSpaceGB = Number(bytesToGB(dev.freeSpaceBytes));
                const usedSpaceGB = totalSpaceGB - freeSpaceGB;
                const usedPercentage = (usedSpaceGB / totalSpaceGB) * 100;

                return (
                    <Box key={dev.storageDeviceName}>
                        <Text fontWeight="bold">{dev.storageDeviceName}:</Text>
                        <Text>{freeSpaceGB} GB Free ({totalSpaceGB} GB Total)</Text>
                        <Progress w="100%" backgroundColor="gray.200" value={usedPercentage} size="sm" colorScheme="green" />
                    </Box>
                );
            });
        }
    };

    const refreshConsole = () => {
        setIsRefreshing(true);
        refreshConsoles([props.emailAddress]).then((result) => {
            setIsRefreshing(false);
        }).catch((error) => {
            console.log(error);
            setIsRefreshing(false);
        });
    };

    return (
        <VStack alignItems="center" spacing={4} w='300px'>
            <XboxConsoleName console={props.console} emailAddress={props.emailAddress} />
            <XboxIcon color={iconColor} consoleState={props.console.status?.errorCode as XboxState} />
            <Flex flexDirection="column" alignItems="flex-start" w="100%">
                <HStack my={1} mt={0} justifyContent="center" width="100%">
                    <Button onClick={props.onUnlink} size="xs" py={'15px'} colorScheme="red" variant="solid" leftIcon={<FaUnlink />}>
                        Unlink Console
                    </Button>
                    <Button onClick={refreshConsole} isLoading={isRefreshing} size="xs" py={'15px'} colorScheme="green" variant="solid" leftIcon={<FaSync />}>
                        Refresh Console
                    </Button>
                </HStack>
                <ConsoleStatus errorCode={props.console.status?.errorCode} errorMessage={props.console.status?.errorMessage} />
                <Box w="85%">
                    <Text fontWeight="bold">Power</Text>
                    <Text>{props.console.powerState}</Text>
                </Box>
                <Box w="85%">
                    <Text fontWeight="bold">Type</Text>
                    <Text>{xboxType}</Text>
                </Box>
                <Box w="85%">
                    <Text fontWeight="bold">ID</Text>
                    <Text>{props.console.id}</Text>
                </Box>

                <Box w="85%">
                    <Text fontWeight="bold">Updated at:</Text>
                    <Text>{new Date(props.console.aggregatedAt).toLocaleString()}</Text>
                </Box>
                <Box w="85%">
                    {storageDevices()}
                </Box>
            </Flex>

            <Spacer />
        </VStack>
    );
};

export const ConsoleStatus = ({ errorCode, errorMessage }: { errorCode?: string, errorMessage?: string }) => {
    const text = errorMessage ? `${errorCode} : ${errorMessage}` : errorCode;

    return (
        <Box w="85%">
            <Text fontWeight="bold">Status</Text>
            <Text>{text}</Text>
        </Box>
    );
};