import { Box, Divider, Text } from "@chakra-ui/react"
import { XMError } from "lib/coplay/types/BackendTypes"

export const AddConsoleErrorScreen = (props: { error: XMError }) => {

    return (
        <>
            <Box py='10px' textAlign='center'>
                <Text>{props.error.userMessage}</Text>
                <Text>{props.error.suggestion}</Text>
                <Box py='10px'>
                    <details>
                        <summary>More Info</summary>
                        <Box py='5px'>
                            <Divider />
                            <Box textAlign='left'>
                                <Text>{props.error.errorMessage}</Text>
                            </Box>
                            <Divider />
                        </Box>
                    </details>
                </Box>
            </Box>
        </>
    )
}