import { Box } from "@chakra-ui/react";
import { XboxUsersSet } from "lib/coplay/types/FrontendTypes";
import { useSelectedUsers, useSetSelectedUsers } from "lib/coplay/components/DataProvider";
import { XboxUser } from "lib/coplay/types/BackendTypes";


export function SelectAllCheckbox(props: { displayedUsers: XboxUser[] }) {

    const setSelectedUsers = useSetSelectedUsers();
    const selectedUsers = useSelectedUsers();

    const isChecked = selectedUsers.size() > 0

    console.log("Rendering SelectAllCheckbox")
    console.log("SelectAllCheckbox isChecked", isChecked)

    return (
        <>
            <Box px="15px" fontWeight='bold' fontSize='md'>
                <label>
                    <input id="SelectAllCheckbox" style={{ height: '15px', width: '15px' }} type="checkbox" checked={isChecked}
                        onClick={(e) => {
                            if (isChecked) {
                                console.log("Removing user from selected users")
                                setSelectedUsers(new XboxUsersSet())
                            } else {
                                const newSelectedUsers = XboxUsersSet.FromUserArray(props.displayedUsers);
                                setSelectedUsers(newSelectedUsers)
                            }
                        }}
                        onChange={() => { }} />
                </label>
            </Box>
        </>
    )
}