import { Button } from "@chakra-ui/react"

export default function AuthButton(props: { children: string }) {
    return (
        <Button
            type="submit"
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
        // onClick={handleLogin}
        >
            {props.children}
        </Button>
    )
}