import { UserPrivacySettings } from "./BackendTypes";

export type EnrichedPrivacySetting = {
	setting: string;
	value: string;
	description: string;
	details: string;
	options: string[];
}

export const SettingsValuesToHumanReadable = {
	"FriendCategoryShareIdentityTransitively": "Friends of Friends",
	"Everyone": "Everyone",
	"PeopleOnMyList": "Friends",
	"Blocked": "Block",
	"FriendCategoryShareIdentity": "Friends I Choose",
}

export const SettingsHumanReadableToValue = {
	"Everyone": "Everyone",
	"Friends": "PeopleOnMyList",
	"Block": "Blocked",
	"Allow": "Everyone",
	"Friends I Choose": "FriendCategoryShareIdentity",
	"Everyone on the internet": "Everyone",
	"In-game friends": "PeopleOnMyList"
}

export const PrioritySettings = [
	"CommunicateUsingTextAndVoice",
	"AllowUserCreatedContentViewing",
	"CommunicateDuringCrossNetworkPlay",
]


export function enrichSettings(settings: UserPrivacySettings): EnrichedPrivacySetting[] {
	const enrichedSettings: EnrichedPrivacySetting[] = [];

	const privacySettingDescriptions = {
		"ShareFriendList": "Others can see your friends list",
		"ShareGameHistory": "Others can see your game and app history",
		"CommunicateUsingTextAndVoice": "Others can communicate with voice, text, or invites",
		"SharePresence": "Others can see if you 're online",
		"ShareProfile": "Others can see your Xbox profile details",
		"CommunicateUsingVideo": "You can use video for communications",
		"ShareIdentity": "Real Name",
		"ShareRecordedGameSessions": "Others can see your captures on Xbox Live",
		"ShareIdentityTransitively": "You can share your real name with friends of friends",
		"AllowUserCreatedContentViewing": "You can see and upload community creations",
		"AllowProfileViewing": "See other people 's Xbox Live profiles",
		"ShareClubMembership": "Others can see your club memberships",
		"ShareActivityFeed": "Others can see your activity feed",
		"CommunicateDuringCrossNetworkPlay": "You can communicate outside of Xbox Live with voice & text",
		// "CollectVoiceData": "Collect Voice Data (Xbox 360 Only)",
	}

	const privacySettingDetails = {
		"ShareFriendList": "Decide who can see your friends list.",
		"ShareGameHistory": "Decide who can see your game and app history.",
		"CommunicateUsingTextAndVoice": "Decide who on Xbox Live to communicate with using voice and text, and who sends you invitations to parties, games, or clubs.",
		"SharePresence": "Decide who can see that you 're online and which game or app you 're using.",
		"ShareProfile": "Decide who can see your Xbox profile bio and location.",
		"CommunicateUsingVideo": "Decide who to communicate with on Xbox Live using video.",
		"ShareIdentity": "Decide who can see your real name.",
		"ShareRecordedGameSessions": "Decide who can see game clips and screenshots you make and upload.",
		"ShareIdentityTransitively": "Decide whether friends of friends can see your name.",
		"AllowUserCreatedContentViewing": "Decide whose community creations you want to see.",
		"AllowProfileViewing": "Decide whether you can view other people 's Xbox profiles, which might include their game history and achievements, bio, and other personal info.",
		"ShareClubMembership": "Decide who can see your public and private club memberships on your profile. Hidden memberships will only be visible to you.",
		"ShareActivityFeed": "Decide who can see what you post to your activity feed.",
		"CommunicateDuringCrossNetworkPlay": "Decide whether to communicate using voice & text with people on  gaming services outside of Xbox Live, such as PC and PlayStation."
		// "CollectVoiceData": "Send samples of my voice data to Microsoft to help improve Microsoft products and services. This info will be associated with my Xbox profile and won’t be used for anything else.",

	}

	const privacySettingValues = {
		"ShareFriendList": ["Everyone", "Friends", "Block"],
		"ShareGameHistory": ["Everyone", "Friends", "Block"],
		"CommunicateUsingTextAndVoice": ["Everyone", "Friends", "Block"],
		"SharePresence": ["Everyone", "Friends", "Block"],
		"ShareProfile": ["Everyone", "Friends", "Block"],
		"CommunicateUsingVideo": ["Everyone", "Friends", "Block"],
		"ShareIdentity": ["Everyone", "Friends", "Friends I Choose", "Block"],
		"ShareRecordedGameSessions": ["Everyone on the internet", "Friends", "Block"],
		"ShareIdentityTransitively": ["Allow", "Block"],
		"AllowUserCreatedContentViewing": ["Everyone", "Friends", "Block"],
		"AllowProfileViewing": ["Everyone", "Friends", "Block"],
		"ShareClubMembership": ["Everyone", "Friends", "Block"],
		"ShareActivityFeed": ["Everyone", "Friends", "Block"],
		"CommunicateDuringCrossNetworkPlay": ["Allow", "In-game friends", "Block"],
		// "CollectVoiceData": ["Allow","Block"],
	}



	settings.settings.forEach((setting) => {
		if (!privacySettingDescriptions[setting.setting] ||
			!privacySettingValues[setting.setting]) {
			console.log("Unknown setting: " + setting.setting);
			return;
		}
		const enrichedSetting: EnrichedPrivacySetting = {
			...setting,
			description: privacySettingDescriptions[setting.setting],
			details: privacySettingDetails[setting.setting],
			options: privacySettingValues[setting.setting]
		};
		enrichedSettings.push(enrichedSetting);
	});
	return enrichedSettings;
}

export const DefaultPrivacySettings: UserPrivacySettings = {
	settings: [
		{ setting: "ShareFriendList", value: "PeopleOnMyList" },
		{ setting: "ShareGameHistory", value: "PeopleOnMyList" },
		{ setting: "CommunicateUsingTextAndVoice", value: "PeopleOnMyList" },
		{ setting: "SharePresence", value: "PeopleOnMyList" },
		{ setting: "ShareProfile", value: "PeopleOnMyList" },
		{ setting: "ShareVideoAndMusicStatus", value: "PeopleOnMyList" },
		{ setting: "CommunicateUsingVideo", value: "Blocked" },
		{ setting: "ShareIdentity", value: "PeopleOnMyList" },
		{ setting: "ShareRecordedGameSessions", value: "PeopleOnMyList" },
		{ setting: "ShareIdentityTransitively", value: "Everyone" },
		{ setting: "AllowUserCreatedContentViewing", value: "Everyone" },
		{ setting: "AllowProfileViewing", value: "PeopleOnMyList" },
		{ setting: "ShareClubMembership", value: "Blocked" },
		{ setting: "ShareActivityFeed", value: "PeopleOnMyList" },
		{ setting: "CommunicateDuringCrossNetworkPlay", value: "PeopleOnMyList" },
		// { setting: "CollectVoiceData", value: "Blocked" },
		// { setting: "ShareXboxMusicActivity", value: "Blocked" },
		// { setting: "CollectXboxVideoData", value: "Blocked" },
		// { setting: "ShareVideoHistory", value: "Blocked" },
		// { setting: "ShareMusicHistory", value: "Blocked" },
		// { setting: "ShareContentToExternalNetworks", value: "Blocked" },
		// { setting: "CollectVoiceSearchData", value: "Blocked" },
		// { setting: "CollectVoiceGameChatData", value: "Blocked" },
	]
};