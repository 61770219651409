import { Select } from "@chakra-ui/react"
import ProfileOptions
    from "lib/coplay/components/views/dashboard/dashboard_list/ProfileOptions"

export default function ProfileFilter(
    props: { setProfileFilter: React.Dispatch<React.SetStateAction<string>> }
) {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.setProfileFilter(event.target.value)
    }

    return (
        <Select
            style = {{ marginLeft: "15px" }}
            width = {"182px"}
            variant = {"filled"}
            onChange = {onChange}
        >
            <option>
                Filter By Profile
            </option>
            <ProfileOptions />
            <option>
                None
            </option>
        </Select>
    )
}