import { Text } from "@chakra-ui/react"
import StartTime from "lib/coplay/components/modals/schedules/StartTime"
import { ScheduleEvent } from "lib/coplay/types/BackendTypes"

export default function ViewSchedulesModal(props: { selectedSchedule: ScheduleEvent }) {
    const startDate = new Date(props.selectedSchedule.startDate)

    return (
        <>
            <Text fontSize="md">From: {startDate.toLocaleDateString('en-US')}</Text>
            <Text fontSize="md">To: {new Date(props.selectedSchedule.endDate).toLocaleDateString('en-US')}</Text>
            <p>
                Start Time: <StartTime localeTime={startDate.toLocaleTimeString()} />
            </p>
        </>
    )
}