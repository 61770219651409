import { Flex, Box, Divider, Text } from "@chakra-ui/react";
import { SupportedVendors, Vendor, VendorMember } from "lib/coplay/types/PaymentTypes";
import { useState } from "react";
import { AddVendorMemberButton } from "./AddVendorMemberButton";
import MemberRenderer from "./MemberListRenderer";
import PaymentsHeader, { FilterButtons, FundingDetails } from "./PaymentsHeader";
import { PaymentsZeroState } from "./PaymentsZeroState";


export function PaymentsList({ selectedService, setSelectedService, vendorMembers }) {

    const [selectedVendor, setSelectedVendor] = useState<SupportedVendors | null>(null);

    // let members = fakeVendorMembers
    let members = vendorMembers

    let aggregatedVendors: Record<SupportedVendors, boolean> = {} as Record<SupportedVendors, boolean>;
    members.forEach((vm) => {
        aggregatedVendors[vm.vendor] = true;
    });

    const filteredVendorMembers = selectedVendor ? members.filter((vm) => vm.vendor === selectedVendor) : members;

    return (
        <Flex id='test' direction={"column"} justifyContent="center" alignItems="center" height="90%" width="100%">
            {members.length === 0 ? <PaymentsZeroState /> : (
                <>
                    <Box w='100%' >
                        <PaymentsHeader>
                            <FilterButtons setVendor={setSelectedVendor} allVendors={Object.keys(aggregatedVendors) as SupportedVendors[]} selectedVendor={selectedVendor} />
                            <FundingDetails coplayBalance={0} coplaySpend={0} users={members.length} />
                            <AddVendorMemberButton hideIcon={false} onClick={() => { }} />
                        </PaymentsHeader>
                    </Box>
                    <MemberRenderer members={filteredVendorMembers} />
                </>
            )}
        </Flex>
    )
}


const fakeVendorMembers: VendorMember[] = [
    {
        id: "disney-123",
        email: "disney@example.com",
        vendor: SupportedVendors.Disney,
        subscriptions: [{
            id: "sub-d1",
            name: "Disney+ Monthly",
            endDate: "2023-12-31",
            nextCharge: "2023-06-01",
            daysRemaining: 30,
            payment: {
                id: "pay-d1",
                name: "Visa",
                lastFourDigits: "1234",
                expiryMonth: "12",
                expiryYear: "2025",
            },
            isCoplayManaged: true,
            description: "Standard Disney+ subscription",
        }],
        hasPurchasing: true,
        createdAt: "2023-05-01T12:00:00Z",
        updatedAt: "2023-05-01T12:00:00Z",
        notes: "Disney test account",
    },
    {
        id: "microsoft-456",
        email: "microsoft@example.com",
        vendor: SupportedVendors.Microsoft,
        subscriptions: [{
            id: "sub-m1",
            name: "Microsoft 365",
            endDate: "2024-05-31",
            nextCharge: "2023-06-01",
            daysRemaining: 365,
            payment: {
                id: "pay-m1",
                name: "Mastercard",
                lastFourDigits: "5678",
                expiryMonth: "06",
                expiryYear: "2026",
            },
            isCoplayManaged: true,
            description: "Microsoft 365 Family subscription",
        }],
        hasPurchasing: true,
        createdAt: "2023-05-01T14:00:00Z",
        updatedAt: "2023-05-01T14:00:00Z",
        notes: "Microsoft test account",
    },
];