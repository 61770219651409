import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useSelectedUsers } from "../../DataProvider";
import { XboxUser } from 'lib/coplay/types/BackendTypes';

const convertUsersToCSV = (users: XboxUser[]) => {
    const header = ['Email', 'Gamertag', 'XUID', 'Profile', 'Number of Friends', 'Number Of Conversations', 'User Status Message', 'Presence State', 'Presence Text', 'Updated At', 'Console ID', 'Console Name', 'Console OS', 'Notes', 'Schedules'].join(',');

    const rows = users.map((user) => {
        const { emailAddress, gamerTag, xuid, xmProfile, numFriends, numConversations, userStatus, presenceState, presenceText, updatedAt, consoleId, consoleName, consoleOS, notes, schedules } = user;
        const mlNotes = notes ? `\"${notes.replace(/\n/g, '|')}\"` : '';
        const row = [
            emailAddress,
            gamerTag,
            xuid,
            xmProfile?.name || '',
            numFriends,
            numConversations,
            userStatus.statusMessage,
            presenceState,
            presenceText,
            updatedAt.toISOString(),
            consoleId || '',
            consoleName || '',
            consoleOS || '', // Use an empty string if consoleOS is undefined or null
            mlNotes,
            schedules?.join(',') || ''
        ];
        return row.join(',');
    })
    return [header, ...rows].join('\n');
};

const CsvDownloadButton: React.FC = () => {
    const selectedUsers = useSelectedUsers();
    const usersArray = Array.from(selectedUsers.getAll());

    const handleDownload = () => {
        const csvData = convertUsersToCSV(usersArray);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        const currentDate = new Date().toISOString().split('T')[0];
        const fileName = `coplay-xm-user-snapshot-${currentDate}.csv`;
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const isDisabled = selectedUsers.size() === 0;

    return (
        <IconButton
            mx="5px"
            icon={<DownloadIcon />}
            onClick={handleDownload}
            aria-label="Download"
            disabled={isDisabled}
        />
    );
};

export default CsvDownloadButton;