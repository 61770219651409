/*eslint-disable*/

import { useDisclosure, Flex, Link, List, ListItem, Text, useColorModeValue }
	from '@chakra-ui/react';
import TermsModal from "lib/coplay/components/modals/terms/TermsModal"

export default function Footer() {
	const disclosure = useDisclosure()

	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex
			zIndex='3'
			flexDirection={{
				base: 'column',
				xl: 'row'
			}}
			alignItems={{
				base: 'center',
				xl: 'start'
			}}
			justifyContent='space-between'
			px={{ base: '30px', md: '50px' }}
			pb='30px'>
			<Text
				color={textColor}
				textAlign={{
					base: 'center',
					xl: 'start'
				}}
				mb={{ base: '20px', xl: '0px' }}>
				{' '}
				&copy; {new Date().getFullYear()}
				<Text as='span' fontWeight='500' ms='4px'>
					CoPlay. Technology for good.
				</Text>
			</Text>
			<List display='flex'>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={textColor} href='mailto:braxton@coplay.io'>
						Support
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link
						fontWeight='500'
						color={textColor}
						onClick = {disclosure.onOpen}
					>
						Terms of Use
					</Link>
					<TermsModal disclosure = {disclosure} />
				</ListItem>
				<ListItem>
					<Link fontWeight='500' color={textColor} href='https://coplay.io'>
						About
					</Link>
				</ListItem>
			</List>
		</Flex>
	);
}
