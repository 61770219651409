import { AvatarBadge, Badge, Box, Flex, Icon } from "@chakra-ui/react";
import { XboxConsole, XboxState } from "lib/coplay/types/BackendTypes";
import { FaCheckCircle, FaExclamationTriangle, FaQuestionCircle } from "react-icons/fa";

export const XboxIcon = (props: { color: string, size?: string, consoleState?: XboxState }) => {
      let size = '65px';

      if (props.size != null) {
            size = props.size;
      }

      return (
            <Box position={'relative'} w={size} h={size}>
                  <svg fill={props.color} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={size} height={size} viewBox="0 0 561.008 561.008" xmlSpace="preserve">
                        <g>
                              <g>
                                    <rect y="215.733" width="277.438" height="51.53" />
                                    <rect y="290.703" width="277.438" height="54.572" />
                                    <path d="M561.002,215.733H283.559v129.542h277.438V215.733H561.002z M486.393,250.415c5.588,0,10.68,1.934,14.811,5.074
              c-8.285-1.689-13.922,1.928-15.012,2.711c-1.072-0.765-6.512-4.223-14.518-2.785C475.787,252.318,480.848,250.415,486.393,250.415
              z M461.803,275.005c0-7.154,3.104-13.531,7.975-18.03c1.518,0.973,9.621,3.929,10.232,7.283c0,0-16.438,16.01-10.838,28.275
              C464.637,288.071,461.803,281.872,461.803,275.005z M486.393,299.59c-6.549,0-12.467-2.602-16.873-6.781
              c0.129-2.197,1.604-11.354,16.672-22.981c16.041,12.387,16.676,21.958,16.684,23.311
              C498.504,297.111,492.758,299.59,486.393,299.59z M502.984,293.047c6.316-12.326-10.619-28.789-10.619-28.789
              c0.668-3.66,10.166-6.817,10.404-7.479c5.006,4.498,8.213,10.961,8.213,18.226C510.982,282.166,507.869,288.555,502.984,293.047z"
                                    />
                                    <path d="M559.852,206.553l-1.15-3.06l-1.678-4.468c-1.188-3.164-4.889-5.728-8.268-5.728H283.559v10.196v3.06v3.06h277.438
              L559.852,206.553z" />
                                    <path d="M277.438,206.553v-3.06v-10.202H12.24c-3.378,0-7.081,2.564-8.269,5.728l-1.677,4.474l-1.145,3.06L0,209.613h277.438
              V206.553z" />
                                    <path d="M557.029,361.982l1.678-4.467l1.15-3.061l1.15-3.061H283.559v3.061v3.061v10.201h265.197
              C552.141,367.711,555.844,365.146,557.029,361.982z" />
                                    <path d="M2.295,357.516l1.677,4.467c1.188,3.164,4.89,5.729,8.269,5.729h265.198V357.51v-3.061v-3.061H0l1.15,3.061L2.295,357.516
              z" />
                                    <path d="M22.65,273.873c-3.378,0-6.12,2.289-6.12,5.11s2.742,5.11,6.12,5.11h215.571c3.378,0,6.12-2.289,6.12-5.11
              s-2.742-5.11-6.12-5.11H22.65z" />
                                    <polygon points="260.645,271.083 254.176,281.48 267.114,281.48" />
                                    <rect x="254.17" y="283.721" width="12.944" height="3.157" />
                              </g>
                        </g>

                  </svg>
                  <StatusBadge state={props.consoleState} />
            </Box>
      )
}

const StatusBadge = (props: { state: XboxState }) => {
      if (props.state) {
            if (props.state == XboxState.OK) {
                  return <Icon position={'absolute'} top={'55%'} right={'-7px'} color={'green.500'} as={FaCheckCircle} />
            } else {
                  return <Icon position={'absolute'} top={'55%'} right={'-7px'} color={'orange'} as={FaExclamationTriangle} />
            }
      } else {
            return <></>
      }
}
