import { Avatar, AvatarBadge, Box, Icon } from '@chakra-ui/react';
import { useState } from 'react';
import { IoTime } from "react-icons/io5";
import { PiWarningCircleBold } from 'react-icons/pi';
import { ScheduleDetailsModal } from 'lib/coplay/components/views/dashboard/dashboard_list/schedule_details/ScheduleDetailsModal';
import { XboxUser } from 'lib/coplay/types/BackendTypes';

export function UserAvatar(
    props: {
        user: XboxUser,
        [x: string]: any
    }
) {
    const { user, ...rest } = props;
    const schedules = user.schedules;
    const emailAddress = user.emailAddress;
    const hasWarnings = user.hasScheduleWarnings;

    return (
        <Avatar {...rest}>
            {/* <ScheduleBadge scheduleIds={schedules} emailAddress={emailAddress} hasWarnings={hasWarnings} /> */}
            {schedules && schedules.length > 0 &&
                <ScheduleBadge scheduleIds={schedules} emailAddress={emailAddress} hasWarnings={hasWarnings} />}
        </Avatar>
    );
}

function ScheduleBadge(props: { scheduleIds?: string[], emailAddress: string, hasWarnings?: boolean }) {
    const { scheduleIds, emailAddress, hasWarnings, ...rest } = props;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onClick = () => {
        setIsModalOpen(true);
    }

    // const warning: [string, JSX.Element] = hasWarnings ?
    //     ['orange.500', <Icon as={PiWarningCircleBold} boxSize='100%' />] :
    //     ['white', <Icon as={IoTime} boxSize='100%' />]

    const warning: [string, JSX.Element] =
        ['white', <Icon as={IoTime} boxSize='100%' />]

    return (
        <>
            <Box onClick={onClick} _hover={{ cursor: 'pointer' }}>
                <AvatarBadge
                    _hover={{ boxSize: '25px' }}
                    border="none"
                    bg={warning[0]}
                    boxSize='40%'
                >
                    {warning[1]}
                </AvatarBadge>

                <ScheduleDetailsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    scheduleIds={scheduleIds}
                    emailAddress={emailAddress}
                />
            </Box>
        </>
    );
}
