import { ScheduleFrequency } from "lib/coplay/types/BackendTypes"


export default function
    FrequencyDescription(props: { frequency: ScheduleFrequency, getStart: Date }) {
    let frequencyDescription: string
    if (props.frequency === ScheduleFrequency.OneTime) {
        frequencyDescription = "once."
    } else if (props.frequency === ScheduleFrequency.Daily) {
        frequencyDescription = "every day until"
    } else if (props.frequency === ScheduleFrequency.Weekly) {
        frequencyDescription = `every ${[
            "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
        ][props.getStart.getUTCDay()]} until`
    } else {
        frequencyDescription = `on day ${props.getStart.getUTCDate()} until`
    }

    return (
        <>
            Occurs {frequencyDescription}
        </>
    )
}