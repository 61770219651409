/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Switch, Route } from "react-router-dom";
import { Flex, Heading, Text, useColorModeValue, Link } from "@chakra-ui/react";
import DefaultAuth from "lib/horizon/layouts/auth/Default";
import illustration from "lib/horizon/assets/img/auth/auth.png";
import { IoGameController } from 'react-icons/io5';
import AuthInstructions from "lib/coplay/components/views/auth/AuthInstructions"
import SignInForm from "lib/coplay/components/views/auth/SignInForm"
import AuthBack from "lib/coplay/components/views/auth/AuthBack"
import ChangePasswordForm from "lib/coplay/components/views/auth/ChangePasswordForm"

function SignIn() {
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    // const [remember, setRemember] = React.useState<boolean>(false); //[BRADY] Implement this in the DataProvider

    return (
        <DefaultAuth image={illustration}>
            <Flex
                id='signInFlex'
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignSelf='center'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <AuthBack />
                <Flex w='100%' me='auto' direction='column' justifyContent='center' alignItems='center'>
                    <IoGameController size='120px' color={brandStars} />

                    <Heading color={textColor} fontSize='36px' px='10px' textAlign='center'>
                        Welcome To<br />CoPlay XM
                    </Heading>
                    <Text
                        mb='26px'
                        ms='4px'
                        color={"gray.400"}
                        fontWeight='400'
                        fontSize='md'
                        textAlign='center'>
                        <AuthInstructions />
                    </Text>
                </Flex>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>
                    <Switch>
                        <Route exact path = {"/sign-in/change-password"}>
                            <ChangePasswordForm
                                textColor = {textColor}
                                brandStars = {brandStars}
                            />
                        </Route>
                        <Route path = {"*"}>
                            <SignInForm
                                textColor = {textColor}
                                textColorBrand = {textColorBrand}
                                brandStars = {brandStars}
                            />
                        </Route>
                    </Switch>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'>
                        <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                            Not signed up yet?
                            <Link fontWeight='500' color={textColorBrand} paddingLeft='5px' href='mailto:braxton@coplay.io'>
                                Let's Chat!
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
