import React, { useCallback } from 'react';
import { MemoUserCard } from 'lib/coplay/components/views/dashboard/dashboard_list/UserCard';
import { useSelectedUsers, useSetSelectedUsers } from 'lib/coplay/components/DataProvider';
import { XboxUsersSet } from 'lib/coplay/types/FrontendTypes';
import { AddConsoleModal } from 'lib/coplay/components/modals/console/add_console/add_console_modal/AddConsoleModal';
import { ConsoleDetailsModal } from 'lib/coplay/components/modals/console/console_details/ConsoleDetailsModal';
import { XboxUser } from 'lib/coplay/types/BackendTypes';


export default function UsersList(props: { openSpotlightUser: (user: XboxUser) => void, displayedUsers: XboxUser[] }) {
    const selectedUsers = useSelectedUsers();
    const setSelectedUsers = useSetSelectedUsers();

    // const [spotlightUser, setSpotlightUser] = React.useState<XboxUser | null>(null);
    // const openSpotlightUser = useCallback((user: XboxUser) => setSpotlightUser(user), []);
    // const closeSpotlightUser = useCallback(() => setSpotlightUser(null), [])

    const [userConsoleDetails, setUserConsoleDetails] = React.useState<XboxUser | null>(null);
    const openUserConsoleDetails = useCallback((user: XboxUser) => setUserConsoleDetails(user), []);
    const closeUserConsoleDetails = useCallback(() => setUserConsoleDetails(null), [])

    const [userLinkConsole, setUserLinkConsole] = React.useState<XboxUser | null>(null);
    const openUserLinkConsole = useCallback((user: XboxUser) => setUserLinkConsole(user), []);
    const closeUserLinkConsole = useCallback(() => setUserLinkConsole(null), [])


    // Use a ref to store the current updater function
    // Update the ref after each render
    // Create a stable function which uses the current ref value
    let setSelectedRef = React.useRef<(user: XboxUser) => void>();
    setSelectedRef.current = (user: XboxUser) => {
        const newSelectedUsers = new XboxUsersSet(selectedUsers);
        newSelectedUsers.add(user);
        setSelectedUsers(newSelectedUsers);
    };
    const stableSetSelected = React.useCallback((user: XboxUser) => {
        setSelectedRef.current?.(user);
    }, []);


    const onConsoleSelect = useCallback((user: XboxUser) => {
        if (user.consoleId) {
            openUserConsoleDetails(user);
        } else {
            openUserLinkConsole(user);
        }
    }, [])

    const onConsoleClose = useCallback(() => {
        closeUserConsoleDetails()
        closeUserLinkConsole();
    }, [])


    // Use a ref to store the current updater function
    // Update the ref after each render
    // Create a stable function which uses the current ref value
    const setUnSelectedRef = React.useRef<(user: XboxUser) => void>();
    setUnSelectedRef.current = (user: XboxUser) => {
        const newSelectedUsers = new XboxUsersSet(selectedUsers);
        newSelectedUsers.delete(user);
        setSelectedUsers(newSelectedUsers);
    };
    const stableSetUnSelected = React.useCallback((user: XboxUser) => {
        setUnSelectedRef.current?.(user);
    }, []);

    const cards = props.displayedUsers.map((user) => {
        return <MemoUserCard
            key={user.xuid}
            user={user}
            isSelected={selectedUsers.has(user)}
            onClick={props.openSpotlightUser}
            onSelect={stableSetSelected}
            onDeselect={stableSetUnSelected}
            onConsole={onConsoleSelect}
        />
    })

    const addModal = userLinkConsole ? <AddConsoleModal emailAddress={userLinkConsole.emailAddress} isOpen={true} onClose={onConsoleClose} /> : null
    const detailModal = userConsoleDetails ? <ConsoleDetailsModal emailAddress={userConsoleDetails.emailAddress} isOpen={true} onClose={onConsoleClose} /> : null

    return (
        <div style={{ padding: "0px 25px 0px 25px" }}>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ flexGrow: 1 }}></div>
            </div>

            {addModal}
            {detailModal}

            {/* <XblUserModal xboxUser={spotlightUser} reset={closeSpotlightUser} /> */}
            {cards}
        </div>
    );
};