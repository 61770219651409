import React from "react";
import { Box, Spinner, Flex, Button, useColorModeValue, Spacer, Divider, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"; // Added Button import
import Card from "lib/horizon/components/card/Card";
import { useNotifications } from "lib/coplay/context/NotificationContext";
import { ResultRenderer } from "../usage/RequestLogRenderer";
import { XMNotification } from "lib/coplay/types/BackendTypes";

export default function NotificationsView() {
    const { notifications, unreadCount, markRead, deleteNotification } = useNotifications();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const markAsRead = (id: string) => {
        markRead(id);
    };


    const clearAll = () => {
        notifications.forEach(notification => deleteNotification(notification.id));
    };

    const markAllAsRead = () => {
        notifications.forEach(notification => markRead(notification.id));
    };

    return (
        <Card pt={2} minH="95vh">
            <Flex pt={{ base: '60px', xl: '60px' }} wrap='wrap' w='100%' gap={4} alignItems='center'>

                <Box px={4}>Total Notifications: {notifications.length}</Box>
                <Box px={4}>Unread Notifications: {unreadCount}</Box>

                <Spacer />
                <Button w='150px' minW='150px' variant='action' onClick={markAllAsRead}>Mark All as Read</Button>
                <Button w='150px' minW='150px' variant='action' onClick={clearAll}>Clear All</Button>

            </Flex>
            <Divider m={2} p={2} />

            <Box mb="30px" flex="1" display="flex" flexDirection="column">
                {isLoading ? (
                    <Spinner />
                ) : (
                    notifications.length === 0 ? (
                        <Box textAlign="center" mt={10}>
                            <Box fontSize="xl" fontWeight="bold">No Notifications</Box>
                            <Box fontSize="md" color="gray.500">You're all caught up!</Box>
                        </Box>
                    ) : (
                        notifications.map((notification) => (
                            <NotificationCard key={notification.id} notification={notification} onRead={markAsRead} deleteNotification={deleteNotification} />
                        ))
                    )
                )}
            </Box>
        </Card >
    );
}

function NotificationCard({ notification, onRead, deleteNotification }: { notification: XMNotification, onRead: (id: string) => void, deleteNotification: (id: string) => void }) {
    const cardShadow = useColorModeValue('0px 0px 20px rgba(112, 144, 176, 0.12)', 'unset');
    const onHoverColor = useColorModeValue('brand.500', 'white');
    const bgHover = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');

    function onDelete(id: string): void {
        deleteNotification(id);
    }

    return (
        <Card p={4} m={2} onClick={() => onRead(notification.id)} cursor="pointer" boxShadow={cardShadow} _hover={{ color: onHoverColor, cursor: 'pointer', background: bgHover }} maxW={'99%'}>
            <Flex>
                {!notification.read && <Box bg="red.500" borderRadius="50%" w="10px" h="10px" m={2} />}

                <Box>
                    <Box fontWeight="bold">{notification.title}</Box>
                    <Box>{notification.message}</Box>
                    <Box fontSize="sm" color="gray.500">
                        {notification.timestamp.toLocaleString()}
                    </Box>
                </Box>
                <Spacer />
                {!notification.read && <Button size='sm' mx={2} variant='ghost' onClick={() => onRead(notification.id)}>Mark as read</Button>}
                <Button size='sm' px={2} colorScheme='red' variant='ghost' onClick={() => onDelete(notification.id)}>Delete</Button>

            </Flex>
            {notification.result && (
                <Box>
                    <Accordion allowToggle>
                        <AccordionItem border="none">
                            <AccordionButton p={0}>
                                <Button size="sm" rightIcon={<AccordionIcon />} w='100%'>
                                    View Details
                                </Button>
                            </AccordionButton>
                            <AccordionPanel>
                                <ResultRenderer result={notification.result} isOpen={true} />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            )}
        </Card>
    );
}