import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Navbar from 'lib/horizon/components/navbar/NavbarAdmin';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';


function AppNavBar() {
    const { onOpen } = useDisclosure();
    const [fixed] = useState(false);
    // Get last part of url
    const location = useLocation();
    const path = location.pathname;
    const lastPart = path.substring(path.lastIndexOf('/') + 1);
    const lastPartCapitalized = lastPart.charAt(0).toUpperCase() + lastPart.slice(1);

    return (
        <>
            <Portal>
                <Box>
                    <Navbar
                        onOpen={onOpen}
                        logoText={'CoPlay XM'}
                        brandText={lastPartCapitalized}
                        secondary={false}
                        message={false}
                        fixed={fixed}
                    />
                </Box>
            </Portal>
        </>
    );
}

export default AppNavBar;