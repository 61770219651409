import { useState, useEffect } from "react"
import ProfileDetailsInfo from "lib/coplay/components/views/profiles/ProfileDetailsInfo"
import { subscribeToProfiles } from "lib/coplay/backend/FirebaseFunctions"
import { XMProfile } from "lib/coplay/types/BackendTypes"

export default function ProfileModal(props: { profileId: string }) {
    const [getProfile, setProfile] = useState<XMProfile>()

    useEffect(() => {
        return subscribeToProfiles((profiles: XMProfile[]) => {
            setProfile(
                profiles.find((profile: XMProfile) => profile.id === props.profileId)
            )
        })
    }, [props])

    let profileModal: JSX.Element
    if (getProfile) {
        profileModal = (
            <ProfileDetailsInfo profile={getProfile} />
        )
    } else {
        profileModal = null
    }

    return profileModal
}